import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { xTenantId } from '../../consts';
import {
  UserWalletsQuery,
  useUserWalletsLazyQuery,
} from '../../libs/graphql/baseAppAPI/queries/__generated__/user-walet.query.generated';
import { useUserContext } from '../../providers/UserProvider';
import { BalanceTypes, IUseUserBalanceData, IUseUserBalanceReturnType, USER_BALANCE_TYPE } from './types';
import { useWalletUpdatedSubscription } from '../../libs/graphql/baseAppAPI/subscriptions/__generated__/wallet-updated.generated';

const userBalanceInitState: IUseUserBalanceData = {
  cashBalance: 0,
  bonusBalance: 0,
};

export const useUserBalance = (): IUseUserBalanceReturnType => {
  const auth = useAuth();
  const { userProfileId } = useUserContext();
  const [userBalance, setUserBalance] = useState<IUseUserBalanceData>(userBalanceInitState);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const checkWallet = (
    walletType: string | null | undefined
  ): { isCash: boolean; isBonus: boolean; isEligible: boolean; balanceType: BalanceTypes } => {
    const { cashBalance, bonusBalance } = BalanceTypes;

    const isCash = walletType === USER_BALANCE_TYPE.MAIN;
    const isBonus =
      walletType === USER_BALANCE_TYPE.BONUS ||
      walletType === USER_BALANCE_TYPE.FREEBET ||
      walletType === USER_BALANCE_TYPE.FREESPINS;

    return {
      isCash,
      isBonus,
      isEligible: isCash || isBonus,
      balanceType: isCash ? cashBalance : bonusBalance,
    };
  };

  const onFetchBalanceCompleted = (data: UserWalletsQuery): void => {
    const balanceData = (data?.userWallets || []).reduce<IUseUserBalanceData>(
      (acc, item) => {
        const walletBalance = item?.balanceAmount || 0;
        const { isEligible, balanceType } = checkWallet(item?.type);
        const isActive = item?.isActive;

        if (isEligible && isActive) acc[balanceType] += walletBalance;

        return acc;
      },
      {
        cashBalance: 0,
        bonusBalance: 0,
      }
    );

    setUserBalance(balanceData);
    setIsCompleted(true);

    console.log('Wallets received from query', data?.userWallets);
  };

  const [fetchUserBalance, { loading: isBalanceLoading, refetch: refetchUserBalance }] = useUserWalletsLazyQuery({
    variables: {
      xTenantId,
      userProfileId,
    },
    onCompleted: onFetchBalanceCompleted,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const { data: receivedWallet, error: walletUpdateError } = useWalletUpdatedSubscription({
    variables: { userProfileId },
  });

  useEffect(() => {
    const { current: wallet, type: walletType } = receivedWallet?.walletUpdated || {};
    const { isCash, isBonus } = checkWallet(walletType);

    if (isBonus) {
      refetchUserBalance();
    }

    if (isCash) {
      const walletBalance = Number(wallet?.balanceAmount || 0);

      const targetUserBalance = {
        ...userBalance,
        ...(isCash && { cashBalance: walletBalance }),
      };

      walletUpdateError && console.error(walletUpdateError);
      walletBalance && setUserBalance(targetUserBalance);
    }

    receivedWallet && console.log('Wallet received from subscription', receivedWallet?.walletUpdated);
  }, [receivedWallet, walletUpdateError]);

  useEffect(() => {
    if (auth.isAuthenticated && !isCompleted) {
      fetchUserBalance();
    }
  }, [auth.isAuthenticated, isCompleted]);

  return { userBalance, isBalanceLoading };
};
