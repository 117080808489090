import { Game } from '../games.model';
import { GameCardContainerType } from '../GameCard/GameCard.types';
import { SimpleGameCard } from '../GameCard/SimpleGameCard/SimpleGameCard';

export const mapGamesToSimpleSlides = (
  games: (Game | undefined)[],
  onClose: () => void,
  containerType = GameCardContainerType.Wide,
  withLabel = false
): (JSX.Element | null)[] => {
  return games.map((game) =>
    game ? (
      <SimpleGameCard
        key={game.gameId}
        game={game}
        containerType={containerType}
        onClose={onClose}
        withLabel={withLabel}
      />
    ) : null
  );
};
