import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserTransactionsQueryVariables = Types.Exact<{
  inTenantId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  inUserProfileId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  inDatefromEpoch?: Types.InputMaybe<Types.Scalars['BigInt']['input']>;
  inDatetoEpoch?: Types.InputMaybe<Types.Scalars['BigInt']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['Cursor']['input']>;
}>;


export type UserTransactionsQuery = { __typename?: 'Query', userTransactions?: { __typename?: 'UserTransactionsConnection', totalCount: number, edges: Array<{ __typename?: 'UserTransactionsEdge', node?: { __typename?: 'UserTransaction', previousBalanceAmount?: any | null, balanceAmount?: any | null, transactionAmount?: any | null, currencyCode?: string | null, transactionStatus?: string | null, lastUpdatedOn?: string | null, createdOn?: string | null, walletType?: string | null, walletId?: string | null, mediatorId?: string | null, mediatorType?: string | null, operationType?: string | null } | null }>, pageInfo: { __typename?: 'TransactionPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: any | null, endCursor?: any | null } } | null };


export const UserTransactionsDocument = gql`
    query UserTransactions($inTenantId: String, $inUserProfileId: String, $inDatefromEpoch: BigInt, $inDatetoEpoch: BigInt, $first: Int, $offset: Int, $after: Cursor) {
  userTransactions(
    inTenantId: $inTenantId
    inUserProfileId: $inUserProfileId
    inDatefromEpoch: $inDatefromEpoch
    inDatetoEpoch: $inDatetoEpoch
    first: $first
    offset: $offset
    after: $after
  ) {
    edges {
      node {
        previousBalanceAmount
        balanceAmount
        transactionAmount
        currencyCode
        transactionStatus
        lastUpdatedOn
        createdOn
        walletType
        walletId
        mediatorId
        mediatorType
        operationType
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useUserTransactionsQuery__
 *
 * To run a query within a React component, call `useUserTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTransactionsQuery({
 *   variables: {
 *      inTenantId: // value for 'inTenantId'
 *      inUserProfileId: // value for 'inUserProfileId'
 *      inDatefromEpoch: // value for 'inDatefromEpoch'
 *      inDatetoEpoch: // value for 'inDatetoEpoch'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<UserTransactionsQuery, UserTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTransactionsQuery, UserTransactionsQueryVariables>(UserTransactionsDocument, options);
      }
export function useUserTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTransactionsQuery, UserTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTransactionsQuery, UserTransactionsQueryVariables>(UserTransactionsDocument, options);
        }
export function useUserTransactionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserTransactionsQuery, UserTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserTransactionsQuery, UserTransactionsQueryVariables>(UserTransactionsDocument, options);
        }
export type UserTransactionsQueryHookResult = ReturnType<typeof useUserTransactionsQuery>;
export type UserTransactionsLazyQueryHookResult = ReturnType<typeof useUserTransactionsLazyQuery>;
export type UserTransactionsSuspenseQueryHookResult = ReturnType<typeof useUserTransactionsSuspenseQuery>;
export type UserTransactionsQueryResult = Apollo.QueryResult<UserTransactionsQuery, UserTransactionsQueryVariables>;