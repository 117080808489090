import React, { FC } from 'react';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { useContentPageGetBySlugQuery } from '../../../../libs/graphql/betzoneDirectusAPI/queries/__generated__/content-page-get-by-slug.query.generated';
import { locale } from '../../../../consts';
import { transformContentPageData } from '../../../../utils/content';
import { Spinner } from '../../../shared/Spinner';
import { Endpoint } from '../../../../models/api.model';
import { Policies } from '../../types';
import './ContentPrompt.scss';

type ContentPromptProps = {
  onClose: () => void;
  modalValue: Policies;
};

export const ContentPrompt: FC<ContentPromptProps> = ({ modalValue, onClose }) => {
  const { data, loading } = useContentPageGetBySlugQuery({
    variables: {
      slug: modalValue,
      language: locale,
    },
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  const pageData = transformContentPageData(data);

  return (
    <PopUpHandler
      isOpen
      onClose={onClose}
      header={pageData.title}
      backdropClassName="content-prompt"
      modalProps={{ 'aria-label': 'accept-terms' }}
      drawerProps={{
        showCloseBtn: false,
      }}
    >
      {loading ? <Spinner /> : <div dangerouslySetInnerHTML={{ __html: pageData.content }} />}
    </PopUpHandler>
  );
};
