import React, { FunctionComponent } from 'react';

export const CookieIcon: FunctionComponent = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.467 19.4032L35.5653 19.7835C34.9325 19.91 34.2965 19.5809 34.0344 18.9911L31.9974 14.4079C31.825 14.0198 31.4835 13.7329 31.0716 13.6299L30.0104 13.3647C29.3963 13.2111 28.9656 12.6595 28.9656 12.0265V4.67216C28.9656 4.32849 28.8373 3.99716 28.6058 3.74309L26.0438 0.931688C23.7339 0.200204 21.2438 -0.126124 18.6551 0.0443446C8.70549 0.699813 0.645412 8.79356 0.0383023 18.7461C-0.670057 30.36 8.53916 40 20 40C30.9315 40 39.8089 31.2286 39.9914 20.3406L38.3544 19.5221C38.08 19.3848 37.7678 19.343 37.467 19.4032Z"
      fill="#FFE6A1"
    />
    <path
      d="M26.2069 33.7931C26.0855 33.7931 25.9659 33.7861 25.8451 33.7839C25.0872 34.634 23.9871 35.1724 22.7587 35.1724C20.9493 35.1724 19.4152 34.0091 18.853 32.3913C16.378 31.4118 14.1457 29.9546 12.2696 28.1306C11.9858 28.2161 11.691 28.2758 11.3793 28.2758C9.6653 28.2758 8.27584 26.8863 8.27584 25.1724C8.27584 24.5738 8.45334 24.0198 8.74694 23.5457C8.72592 23.5081 8.7035 23.4716 8.68264 23.4339C6.5299 23.288 4.82764 21.5001 4.82764 19.3103C4.82764 18.0198 5.41873 16.8678 6.34459 16.1089C6.25686 15.3487 6.20694 14.577 6.20694 13.793C6.20694 9.85953 7.34709 6.19445 9.30826 3.10132C3.71561 6.64726 6.10352e-05 12.8878 6.10352e-05 20C6.10352e-05 31.0457 8.95436 40 20.0001 40C27.1122 40 33.3527 36.2845 36.8987 30.6918C33.8056 32.653 30.1405 33.7931 26.2069 33.7931Z"
      fill="#FFD796"
    />
    <path
      d="M15.5173 16.5516C16.4695 16.5516 17.2415 15.7796 17.2415 14.8274C17.2415 13.8752 16.4695 13.1033 15.5173 13.1033C14.5651 13.1033 13.7932 13.8752 13.7932 14.8274C13.7932 15.7796 14.5651 16.5516 15.5173 16.5516Z"
      fill="#FFD796"
    />
    <path
      d="M33.7932 27.9312C34.7454 27.9312 35.5174 27.1593 35.5174 26.2071C35.5174 25.2548 34.7454 24.4829 33.7932 24.4829C32.841 24.4829 32.0691 25.2548 32.0691 26.2071C32.0691 27.1593 32.841 27.9312 33.7932 27.9312Z"
      fill="#FFD796"
    />
    <path
      d="M18.1035 27.9308C18.7701 27.9308 19.3104 27.3905 19.3104 26.724C19.3104 26.0574 18.7701 25.5171 18.1035 25.5171C17.437 25.5171 16.8966 26.0574 16.8966 26.724C16.8966 27.3905 17.437 27.9308 18.1035 27.9308Z"
      fill="#FFD796"
    />
    <path
      d="M27.4139 16.8967C28.0804 16.8967 28.6208 16.3563 28.6208 15.6898C28.6208 15.0232 28.0804 14.4829 27.4139 14.4829C26.7473 14.4829 26.207 15.0232 26.207 15.6898C26.207 16.3563 26.7473 16.8967 27.4139 16.8967Z"
      fill="#FFD796"
    />
    <path
      d="M22.5863 6.20697C23.2528 6.20697 23.7932 5.66663 23.7932 5.00009C23.7932 4.33355 23.2528 3.79321 22.5863 3.79321C21.9198 3.79321 21.3794 4.33355 21.3794 5.00009C21.3794 5.66663 21.9198 6.20697 22.5863 6.20697Z"
      fill="#FFD796"
    />
    <path
      d="M18.9964 17.7645L18.1992 19.0933C17.7226 19.8875 18.1488 20.9164 19.0474 21.141L21.4205 21.7343C22.1595 21.9191 22.9084 21.4698 23.0931 20.7307L23.3646 19.6448C23.4195 19.4251 23.4195 19.1954 23.3646 18.9757L23.0776 17.8275C22.8991 17.1132 22.1909 16.6651 21.469 16.8095L19.9087 17.1215C19.5276 17.1978 19.1964 17.4313 18.9964 17.7645Z"
      fill="#B97850"
    />
    <path
      d="M22.9771 26.3024L22.1456 27.6882C21.7323 28.3772 21.9929 29.2722 22.7115 29.6315L25.3585 30.9551C26.1477 31.3497 27.0995 30.9119 27.3135 30.0559L27.5862 28.9653L27.9048 27.6904C28.1032 26.8969 27.5715 26.1061 26.7617 25.9904L24.3548 25.6465C23.8056 25.568 23.2626 25.8265 22.9771 26.3024Z"
      fill="#B97850"
    />
    <path
      d="M8.46549 18.0747L7.45073 19.766C7.1251 20.3088 7.21065 21.0035 7.65815 21.451L9.25518 23.0482C9.83651 23.6295 10.794 23.5764 11.3076 22.9345L12.6767 21.223C12.9457 20.8869 13.0422 20.4445 12.9378 20.0268L12.6255 18.7778C12.4962 18.2606 12.0806 17.8643 11.5579 17.7597L9.9187 17.4319C9.34714 17.3175 8.76542 17.5749 8.46549 18.0747Z"
      fill="#B97850"
    />
    <path
      d="M14.081 6.87647L13.2985 8.18069C12.8445 8.93734 13.2079 9.91984 14.045 10.1989L15.7431 10.7649C16.2387 10.9301 16.7851 10.8011 17.1546 10.4317L18.0686 9.51773C18.4111 9.17515 18.5489 8.67788 18.4314 8.20788L18.1923 7.25171C18.0388 6.63757 17.4871 6.20679 16.8541 6.20679H15.2636C14.7793 6.20687 14.3303 6.46108 14.081 6.87647Z"
      fill="#B97850"
    />
    <path
      d="M30.7392 17.5368L29.9408 18.3351C29.4022 18.8737 29.4022 19.7471 29.9408 20.2857L30.3315 20.6764C30.7514 21.0963 31.3926 21.2003 31.9237 20.9348L34.1828 19.8053C34.761 19.5161 35.061 18.8642 34.9042 18.2371L34.8018 17.8278C34.6233 17.1135 33.9152 16.6653 33.1932 16.8097L31.4441 17.1596C31.177 17.2128 30.9317 17.3442 30.7392 17.5368Z"
      fill="#B97850"
    />
    <path
      d="M25.6105 7.69195L24.6945 8.91336C24.358 9.36195 24.3267 9.96976 24.6152 10.4506L25.4628 11.8632C25.8679 12.5385 26.7554 12.738 27.4106 12.3013L29.1317 11.1538C29.7906 10.7146 29.9451 9.81203 29.47 9.17859L28.5344 7.93109C28.3661 7.70664 28.1333 7.53882 27.8671 7.45015L27.1501 7.21109C26.5868 7.0232 25.9667 7.21695 25.6105 7.69195Z"
      fill="#B97850"
    />
    <path
      d="M13.8074 29.4833L12.9067 30.6091C12.3285 31.3318 12.6122 32.4076 13.4716 32.7514L14.6814 33.2353C15.0103 33.3669 15.3771 33.3669 15.7059 33.2353L17.0635 32.6922C17.877 32.3669 18.1849 31.3754 17.699 30.6464L17.4658 30.2967C17.3326 30.0969 17.1497 29.9355 16.935 29.8281L15.5014 29.1113C14.9195 28.8204 14.2136 28.9755 13.8074 29.4833Z"
      fill="#B97850"
    />
    <path
      d="M10.9667 22.8844L9.36963 21.2874C8.92213 20.8399 8.83651 20.1451 9.16221 19.6024L10.177 17.9111C10.2635 17.7669 10.3779 17.6498 10.5044 17.5492L9.91877 17.4321C9.34721 17.3178 8.76541 17.5752 8.46549 18.0749L7.45073 19.7663C7.1251 20.309 7.21065 21.0037 7.65815 21.4513L9.25518 23.0483C9.794 23.5871 10.6517 23.5742 11.1837 23.0592C11.1074 23.0086 11.034 22.9518 10.9667 22.8844Z"
      fill="#A5694B"
    />
    <path
      d="M17.1245 10.2738L15.4264 9.70788C14.5894 9.42882 14.2258 8.44632 14.6799 7.68968L15.4624 6.38546C15.5021 6.31905 15.5547 6.26444 15.604 6.20679H15.2635C14.7791 6.20679 14.3301 6.46101 14.0808 6.87647L13.2983 8.18061C12.8443 8.93725 13.2077 9.91975 14.0449 10.1988L15.7429 10.7648C16.2385 10.93 16.785 10.801 17.1544 10.4316L17.2829 10.3031C17.2301 10.2922 17.1765 10.2912 17.1245 10.2738Z"
      fill="#A5694B"
    />
    <path
      d="M22.7292 21.3377L20.356 20.7444C19.4574 20.5197 19.0312 19.4909 19.5078 18.6967L20.3051 17.3678C20.4 17.2096 20.5261 17.0761 20.6715 16.969L19.9087 17.1216C19.5277 17.1977 19.1963 17.4313 18.9964 17.7645L18.1992 19.0934C17.7226 19.8876 18.1488 20.9165 19.0474 21.1411L21.4205 21.7344C21.9204 21.8594 22.4204 21.6897 22.7516 21.341C22.7441 21.3392 22.7367 21.3396 22.7292 21.3377Z"
      fill="#A5694B"
    />
    <path
      d="M26.7511 11.7437L25.9036 10.3311C25.615 9.85029 25.6464 9.24263 25.9829 8.79388L26.8989 7.57255C26.9992 7.43872 27.1237 7.33388 27.2587 7.24693L27.15 7.21075C26.5868 7.02302 25.9666 7.21677 25.6104 7.69169L24.6944 8.91302C24.3579 9.36169 24.3266 9.96943 24.6151 10.4503L25.4627 11.8629C25.8665 12.5359 26.7491 12.7355 27.4036 12.3045C27.1414 12.1954 26.9092 12.0071 26.7511 11.7437Z"
      fill="#A5694B"
    />
    <path
      d="M31.4385 20.5843C30.8998 20.0457 30.8998 19.1724 31.4385 18.6337L32.2368 17.8353C32.4293 17.6428 32.6746 17.5115 32.9417 17.4582L34.4139 17.1637C34.0989 16.8643 33.6492 16.7182 33.1932 16.8094L31.444 17.1592C31.177 17.2127 30.9317 17.3439 30.7392 17.5364L29.9408 18.3348C29.4022 18.8735 29.4022 19.7468 29.9408 20.2854L30.3315 20.6761C30.7253 21.07 31.3113 21.1732 31.821 20.967L31.4385 20.5843Z"
      fill="#A5694B"
    />
    <path
      d="M24.4202 29.5159C23.7016 29.1566 23.4409 28.2616 23.8543 27.5726L24.6858 26.1869C24.7949 26.0052 24.949 25.8675 25.119 25.7559L24.3549 25.6467C23.8056 25.5682 23.2627 25.8267 22.9772 26.3025L22.1457 27.6883C21.7324 28.3773 21.993 29.2723 22.7116 29.6316L25.3586 30.9552C25.8862 31.219 26.4816 31.1051 26.8854 30.7487L24.4202 29.5159Z"
      fill="#A5694B"
    />
    <path
      d="M16.0607 33.0047L14.8509 32.5208C13.9916 32.1771 13.7079 31.1012 14.286 30.3784L15.1867 29.2527C15.2439 29.1812 15.3081 29.1187 15.3759 29.0615C14.8207 28.8489 14.1851 29.0109 13.8074 29.4832L12.9067 30.609C12.3285 31.3317 12.6122 32.4075 13.4716 32.7513L14.6814 33.2352C15.0103 33.3668 15.3771 33.3668 15.7059 33.2352L16.1885 33.0422C16.1457 33.0298 16.1022 33.0213 16.0607 33.0047Z"
      fill="#A5694B"
    />
    <path
      d="M5.51648 28.275C5.41312 28.275 5.30765 28.2515 5.20874 28.2023C4.86796 28.0319 4.72984 27.6178 4.9003 27.277L5.58999 25.8977C5.76046 25.5569 6.17358 25.4202 6.5153 25.5893C6.85608 25.7597 6.99421 26.1738 6.82374 26.5146L6.13405 27.8939C6.01319 28.1356 5.76976 28.275 5.51648 28.275Z"
      fill="#C98850"
    />
    <path
      d="M17.931 37.2414H17.2413C16.8605 37.2414 16.5516 36.933 16.5516 36.5517C16.5516 36.1706 16.8605 35.8621 17.2413 35.8621H17.931C18.3119 35.8621 18.6207 36.1705 18.6207 36.5517C18.6207 36.933 18.3119 37.2414 17.931 37.2414Z"
      fill="#925F4A"
    />
    <path
      d="M15.1718 24.138C15.0684 24.138 14.9629 24.1144 14.864 24.0652C14.5232 23.8947 14.3851 23.4806 14.5556 23.1399L15.2453 21.7606C15.4153 21.4205 15.8285 21.2831 16.1706 21.4522C16.5114 21.6226 16.6495 22.0367 16.479 22.3775L15.7893 23.7568C15.6685 23.9986 15.425 24.138 15.1718 24.138Z"
      fill="#C98850"
    />
    <path
      d="M11.0339 13.1034C10.9305 13.1034 10.8251 13.0799 10.7262 13.0307L9.34686 12.341C9.00608 12.1706 8.86796 11.7565 9.03843 11.4157C9.20851 11.0756 9.62202 10.9375 9.96374 11.1073L11.343 11.7969C11.6838 11.9673 11.8219 12.3814 11.6515 12.7222C11.5306 12.964 11.2871 13.1034 11.0339 13.1034Z"
      fill="#925F4A"
    />
    <path
      d="M19.3104 3.44822H17.9311C17.5503 3.44822 17.2415 3.13979 17.2415 2.75854C17.2415 2.37729 17.5503 2.06885 17.9311 2.06885H19.3104C19.6913 2.06885 20.0001 2.37729 20.0001 2.75854C20.0001 3.13979 19.6913 3.44822 19.3104 3.44822Z"
      fill="#925F4A"
    />
    <path
      d="M22.0697 13.7931C21.8164 13.7931 21.573 13.6537 21.4521 13.4119L20.7624 12.0326C20.592 11.6918 20.7301 11.2776 21.0709 11.1073C21.4123 10.9375 21.8262 11.0756 21.9962 11.4157L22.6859 12.795C22.8563 13.1358 22.7182 13.55 22.3774 13.7203C22.2784 13.7695 22.1731 13.7931 22.0697 13.7931Z"
      fill="#C98850"
    />
    <path
      d="M3.14308 17.1701C3.05034 17.1244 2.9662 17.0566 2.89909 16.9687C2.66862 16.6653 2.72792 16.2327 3.03144 16.0023L4.25972 15.0698C4.56292 14.8393 4.99519 14.8991 5.22612 15.2022C5.45659 15.5056 5.3973 15.9382 5.09378 16.1686L3.86558 17.101C3.65019 17.2644 3.37019 17.2819 3.14308 17.1701Z"
      fill="#925F4A"
    />
    <path
      d="M28.9655 24.8274C28.7891 24.8274 28.6126 24.7601 28.478 24.6254L27.0987 23.2461C26.8293 22.9767 26.8293 22.5403 27.0987 22.2709C27.368 22.0015 27.8045 22.0015 28.0739 22.2709L29.4532 23.6502C29.7226 23.9196 29.7226 24.356 29.4532 24.6254C29.3184 24.7601 29.142 24.8274 28.9655 24.8274Z"
      fill="#925F4A"
    />
    <path
      d="M30.3449 31.7243C30.1684 31.7243 29.9919 31.6569 29.8573 31.5222C29.5879 31.2529 29.5879 30.8164 29.8573 30.547L30.547 29.8573C30.8164 29.5879 31.2529 29.5879 31.5222 29.8573C31.7916 30.1267 31.7916 30.5632 31.5222 30.8325L30.8325 31.5222C30.6978 31.6569 30.5214 31.7243 30.3449 31.7243Z"
      fill="#C98850"
    />
    <path
      d="M31.7241 2.75854V4.49854C31.7241 4.98651 32.2169 5.3201 32.6699 5.13885L34.7389 4.31127C35.0007 4.20651 35.1724 3.95291 35.1724 3.67096V2.75854C35.1724 2.37768 34.8636 2.06885 34.4827 2.06885H32.4137C32.0329 2.06885 31.7241 2.37768 31.7241 2.75854Z"
      fill="#FFE6A1"
    />
    <path
      d="M33.1986 8.19565L32.7585 11.2764C32.655 12.0011 33.1357 12.6805 33.8534 12.824L35.1165 13.0766C35.6646 13.1863 36.2246 12.9542 36.5347 12.4892L37.0097 11.7767C37.2557 11.4075 37.3093 10.9426 37.1535 10.5273L35.7147 6.69049C35.5357 6.2133 34.9236 6.08073 34.5632 6.44112L33.5888 7.41541C33.3777 7.62643 33.2409 7.90026 33.1986 8.19565Z"
      fill="#FFE6A1"
    />
    <path
      d="M36.6561 11.8515L35.3931 11.5989C34.6753 11.4553 34.1946 10.7759 34.2981 10.0513L34.7382 6.97062C34.7775 6.69586 34.9056 6.44492 35.0921 6.2407C34.9049 6.23125 34.7127 6.29148 34.5632 6.44086L33.5888 7.41523C33.3777 7.62625 33.2408 7.90008 33.1987 8.19547L32.7586 11.2762C32.6551 12.0009 33.1358 12.6802 33.8535 12.8237L35.1166 13.0763C35.6646 13.1859 36.2247 12.954 36.5348 12.4889L36.9503 11.8656C36.8525 11.8676 36.7546 11.8712 36.6561 11.8515Z"
      fill="#FFD796"
    />
    <path
      d="M34.0492 3.75947C33.5962 3.94065 33.1034 3.60705 33.1034 3.11916V2.06885H32.4137C32.0329 2.06885 31.7241 2.37768 31.7241 2.75854V4.49854C31.7241 4.98651 32.2168 5.3201 32.6698 5.13885L34.7388 4.31127C35.0006 4.20651 35.1723 3.95299 35.1723 3.67096V3.31026L34.0492 3.75947Z"
      fill="#FFD796"
    />
    <path
      d="M36.8955 15.0407L36.4879 15.8561C36.3908 16.0502 36.3908 16.2787 36.4879 16.4729L36.6739 16.845C36.8594 17.2161 37.3281 17.3406 37.6733 17.1105L38.7851 16.3693C38.9769 16.2414 39.0922 16.0261 39.0922 15.7955V15.0525C39.0922 14.5818 38.631 14.2493 38.1844 14.3982L37.2942 14.695C37.1208 14.7527 36.9773 14.8771 36.8955 15.0407Z"
      fill="#FFD796"
    />
  </svg>
);
