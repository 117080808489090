import axios from 'axios';

const webhookURL = process.env.REACT_APP_OSM_WEBHOOK_URL as string;

export const notifyGiG = async (UserId: string, RuleName: string): Promise<void> => {
  const payload = {
    UserId,
    RuleName,
  };

  await axios.post(webhookURL, payload);
};
