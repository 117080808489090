import React, {
  ComponentPropsWithRef,
  forwardRef,
  FC,
  useEffect,
  useState,
  ReactElement,
  JSXElementConstructor,
  ChangeEvent,
} from 'react';
import noop from 'lodash/noop';
import { InputFeedbackIcon } from '../InputFeedbackIcon';
import { InputHelperText, InputHelperTextProps } from '../InputHelperText';
import { InputValidationAlerts } from '../InputValidationAlert';
import { FloatingLabel, Label, LabelProps } from '../Label';
import { Theme, ValidationState } from '../../../types';
import { joinStrings } from '../../../utils/string';
import { ValidationLinkDetails } from '../InputValidationAlert/types';
import './styles';

export type InputValidationProps = {
  /**
   * Optional ValidationMessage array containing any validation messages to be displayed underneath the input box.
   * The messages will be coloured to match the validationState (e.g. red for error) if one is supplied
   */
  validationMessages?: {
    message: string;
    id: string;
  }[];
  /**
   * The input control and any validation feedback will be styled to match the validationState (e.g. red for error)
   * if one is supplied.
   */
  validationState?: ValidationState;
};

export type InputProps = {
  /**
   * Current input value.  Left optional to prevent TypeScript complaint when used with hook form Controller.
   */
  value?: string;
  /**
   * Optionally disable the input.
   *
   * This is provided by ComponentProps but it's added to note that it will
   * use the aria-disabled attribute rather than using HTMLInputElement.disable
   * for better accessibility.
   */
  disabled?: boolean;
  /**
   * Shows currency Icon inside input dependent on currencyCode.
   */
  showCurrencyIcon?: boolean;
  /**
   * Whether to display a feedback icon.  Defaults to true
   */
  showFeedbackIcon?: boolean;
  /**
   * Emit the next input change event
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * ReactNode/Text to label the input
   */
  label: React.ReactNode | string;
  /**
   * ReactNode/Text to for the input
   */
  for?: React.ReactNode | string;
  /**
   * A custom icon which will be positioned inside the input at the right side.
   * Should use the Icon component (e.g. `<Icon variant='Eye'/>`) or the InputIconButton component
   * if it's interactive (e.g. `<InputIconButton onClick={onSearch} icon={<Icon variant='Search' />} />`)
   */
  iconRight?: React.ReactNode;
  /**
   * Date of awaiting pending limit
   */
  awaitingPending?: React.ReactNode;
  /**
   * A custom icon which will be positioned inside the input at the left side.
   * Should use the Icon component (e.g. `<Icon variant='Eye'/>`) or the InputIconButton component
   * if it's interactive (e.g. `<InputIconButton onClick={onSearch} icon={<Icon variant='Search' />} />`)
   */
  iconLeft?: React.ReactNode;
  /**
   * Optional & only for rare use cases. Wrap the input element with provided element.  (e.g. date picker)
   */
  inputWrapper?: FC<{ input: ReactElement<unknown, string | JSXElementConstructor<HTMLInputElement>> | undefined }>;
  /**
   * Optional props for input label.
   */
  labelProps?: LabelProps;
  /**
   * Input fills 100% to the container.
   */
  fill?: boolean;
  /**
   * Optional onBlur callback.
   */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Optional onFocus callback.
   */
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Optional onKeyDown callback.
   */
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * Optional onRender callback.
   */
  onRender?: () => void;
  /**
   * Optional, defaults to 'light'. If set to 'dark' the input will be styled to be placed on a dark background.
   */
  theme?: Theme;
  /**
   * Optional, currentLimit includes fields various field for deposit activities.
   */
  currentLimit?: string;
  /**
   * Optional, set lower case to input value.
   */
  lowerCase?: boolean;
  /**
   * Optional, sensitive used for default validation insted of useForm.
   */
  sensitive?: boolean;
  /**
   * Optional, props for helper text
   */
  helperTextProps?: Omit<InputHelperTextProps, 'id' | 'theme' | 'inputIsFocused' | 'disabled' | 'errorState'>;
  /**
   * Called when an input value is being pasted into venues search to trigger dropdown menu
   */
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  /**
   * Optinal, props to display the link in the validation error message
   */
  linkDetails?: ValidationLinkDetails;
  /**
   * Optional, prop to add custom styles for input
   */
  inputBoxClassName?: string;
} & InputValidationProps &
  ComponentPropsWithRef<'input'>;

/**
 *
 * A controlled Input component.
 *
 * ### Example - As Controlled Input
 *
 * ```tsx
 * const UseStateExample = () => {
 *   const [value, setValue] = useState('Initial Value');
 *   return (
 *     <Fragment>
 *       <Input
 *         name="username"
 *         value={value}
 *         label="Username"
 *         placeholder="Enter your username..."
 *         onChange={(e) => setValue(e.target.value)}
 *       />
 *     </Fragment>
 *   );
 * };
 * ```
 *
 * ### Example - React Hook Form
 *
 * An example which uses `react-hook-form` as the controller of state.
 *
 * ```tsx
 * import { useForm, Controller } from 'react-hook-form';
 *
 * const HookFormExample = ({
 *   onSubmit
 * }: {
 *   onSubmit: (formValues) => void;
 * }) => {
 *   const { control, handleSubmit } = useForm({
 *     defaultValues: { username: 'Initial Value' }
 *   });
 *   return (
 *     <form onSubmit={handleSubmit(onSubmit)}>
 *       <Controller
 *         name='username'
 *         control={control}
 *         as={<Input label='Username'/>}
 *       />
 *       <Button type='submit'>Submit</Button>
 *    </form>
 *   );
 * };
 * ```
 * Inputs will stretch to fill the width of their containers.
 *
 * - TODO: May require tooltip support in label and within the input box once tooltip component is created
 * - TODO: Potential improvement - Find better way to manage z-index for interactive vs non interactive input icons
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      disabled,
      onChange,
      validationState,
      validationMessages,
      label,
      showCurrencyIcon,
      showFeedbackIcon = true,
      iconLeft,
      iconRight,
      awaitingPending,
      inputWrapper,
      labelProps,
      value,
      defaultValue,
      className,
      fill = false,
      onBlur,
      onFocus,
      onRender,
      theme = 'light',
      helperTextProps,
      currentLimit,
      lowerCase,
      onPaste = noop,
      sensitive = false,
      linkDetails,
      inputBoxClassName,
      ...props
    },
    ref
  ) => {
    const hasValue = !!value;
    const finalValue = lowerCase ? value?.toLocaleLowerCase() : value;
    const inputId = props.id || props.name;
    const helperId = helperTextProps ? `${inputId}-helper-text` : undefined;

    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
      defaultValue && onChange?.({ target: { value: String(defaultValue) } } as ChangeEvent<HTMLInputElement>);
    }, [defaultValue]);

    const currencyIsFocused = isFocused && showCurrencyIcon;
    const currencyHasValue = value && showCurrencyIcon;
    const displayCurrency = currencyHasValue || currencyIsFocused;

    if (onRender) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(onRender);
    }

    const inputBox = (): JSX.Element => (
      <div className="input__box-container">
        {sensitive ? (
          <input
            {...props}
            ref={props?.ref || ref}
            className={joinStrings(['input__box', inputBoxClassName])}
            aria-disabled={disabled}
            readOnly={disabled || props.readOnly}
            id={inputId}
            required={props?.required}
            minLength={props?.minLength}
            maxLength={props?.maxLength}
            onFocus={(e): void => {
              onFocus && onFocus(e);
              setIsFocused(true);
            }}
            onBlur={onBlur}
          />
        ) : (
          <input
            {...props}
            ref={props?.ref || ref}
            className={joinStrings(['input__box', inputBoxClassName])}
            aria-disabled={disabled}
            readOnly={disabled || props.readOnly}
            onChange={onChange}
            onKeyDown={props.onKeyDown}
            onPaste={onPaste}
            onBlur={(e): void => {
              onBlur && onBlur(e);
              setIsFocused(false);
            }}
            onFocus={(e): void => {
              onFocus && onFocus(e);
              setIsFocused(true);
            }}
            onMouseOver={(): void => {
              setIsHovered(true);
            }}
            onMouseOut={(): void => {
              setIsHovered(false);
            }}
            value={defaultValue ?? finalValue}
            id={inputId}
            disabled={disabled}
          />
        )}
        {displayCurrency && <span className="input__currency input__currency--left">currency symbol</span>}
        {iconLeft && <span className="input__icon input__icon--left">{iconLeft}</span>}
        {iconRight && <span className="input__icon input__icon--right">{iconRight}</span>}
        {showFeedbackIcon && !disabled ? (
          <InputFeedbackIcon
            rightPosition={
              iconRight
                ? `calc(var(--input-right-icon-size) + var(--input-padding-right) 
                  + var(--input-icon-gap) + var(--input-border-width))`
                : 'calc(var(--input-padding-right) + var(--input-border-width))'
            }
            state={validationState}
            theme={theme}
          />
        ) : null}
        {isFocused && currentLimit && !awaitingPending && (
          <span className="input__currency input__currency--right">{`Current: currency symbol${currentLimit}`}</span>
        )}
        {awaitingPending && <span className="input__icon input__icon--pending">{awaitingPending}</span>}
      </div>
    );
    const canShowFeedbackIcon = showFeedbackIcon && !disabled && validationState;
    const multipleRightIcons = canShowFeedbackIcon && iconRight;
    const labelVariant = labelProps?.labelVariant || 'floating';

    if (labelVariant === 'floating' && props.placeholder) {
      console.error(
        `Placeholders are not compatible with floating label inputs
        as the label and placeholder use the same position within the input.`
      );
    }

    return (
      <div
        data-testid="input-container"
        className={joinStrings([
          'input',
          'disable-outline',
          fill && `input--fill`,
          validationState && `input--${validationState}`,
          disabled && 'input--disabled',
          displayCurrency && 'input--has-currency',
          hasValue && 'input--has-value',
          !multipleRightIcons && (canShowFeedbackIcon || iconRight) ? 'input--has-right-icon' : '',
          multipleRightIcons ? 'input--has-right-icons' : '',
          iconLeft && 'input--has-left-icon',
          isHovered && 'input--hover',
          theme === 'dark' && 'input--dark',
          className,
        ])}
      >
        {labelVariant === 'floating' ? (
          <FloatingLabel
            {...labelProps}
            disabled={disabled}
            htmlFor={inputId}
            inputIsFocused={isFocused}
            floatedTop={hasValue || disabled || (isFocused && !disabled)}
            className="input__label"
            inputIcons={{
              left: !!iconLeft,
              right: !!iconRight,
              feedback: !!canShowFeedbackIcon,
            }}
            theme={theme}
          >
            {label}
          </FloatingLabel>
        ) : (
          <Label {...labelProps} disabled={disabled} htmlFor={inputId} className="input__label" theme={theme}>
            {label}
          </Label>
        )}
        {inputWrapper ? inputWrapper({ input: inputBox() }) : inputBox()}
        {helperTextProps && helperId && (
          <InputHelperText
            {...helperTextProps}
            id={helperId}
            theme={theme}
            inputIsFocused={isFocused}
            disabled={disabled}
            validationState={validationState}
          />
        )}
        <InputValidationAlerts
          messages={validationMessages}
          state={validationState}
          theme={theme}
          linkDetails={linkDetails}
        />
      </div>
    );
  }
);

Input.displayName = 'Input';
