import { BudgetLimitsTransactionResponse } from '../../types.__generated__';
import { UsedLimit } from './AccountLimits/types';

export type PlayerLimitsFields = {
  activeLimit: Nullable<number>;
  pendingLimit: Nullable<number>;
  pendingLimitCoolOffEnd: Nullable<string>;
  pendingConfirmation?: Nullable<boolean>;
  playerChosenLimit?: Nullable<number>;
  upperLimit?: Nullable<number>;
  sharedLimit?: Nullable<number>;
  remainingAmount?: Nullable<number>;
};

export type ChangedInputs = {
  daily: boolean;
  weekly: boolean;
  monthly: boolean;
};

export type SetPlayerLimitsFormData = {
  dailyDepositLimit?: Nullable<string | number>;
  weeklyDepositLimit?: Nullable<string | number>;
  monthlyDepositLimit?: Nullable<string | number>;
  sessionReminderInterval?: Nullable<string | number>;
  sessionTotalDuration?: Nullable<string | number>;
  sessionCoolingOffDuration?: Nullable<string | number>;
  sessionLossLimit?: Nullable<string | number>;
  dailyStakes?: Nullable<string | number>;
  dailyLosses?: Nullable<string | number>;
  weeklyStakes?: Nullable<string | number>;
  weeklyLosses?: Nullable<string | number>;
  monthlyStakes?: Nullable<string | number>;
  monthlyLosses?: Nullable<string | number>;
  dailyLotteryPurchaseLimit?: Nullable<string | number>;
  weeklyLotteryPurchaseLimit?: Nullable<string | number>;
  monthlyLotteryPurchaseLimit?: Nullable<string | number>;
  dailySportsStakes?: Nullable<string | number>;
  weeklySportsStakes?: Nullable<string | number>;
  monthlySportsStakes?: Nullable<string | number>;
  autoWithdrawalThreshold?: Nullable<string | number>;
};

export interface TakeBreakParams {
  reason: string;
  duration: number;
  accountStatus: string;
  schemeOptIn?: boolean;
}

export type TakeABreakPayload = {
  duration: number;
  reason: string;
  durationLabel?: string;
  accountStatus?: string;
};

export enum MediatorType {
  Monetary = 'monetary',
  Casino = 'casino',
  Sports = 'sports',
}

export type SetPlayerLimitsData = {
  amount: number;
  period: string;
  type: UsedLimit;
  mediatorType: MediatorType;
};

export type IsDateReached = (a: string) => boolean;

export interface IGroupedLimits {
  [MediatorType.Monetary]: {
    [period: string]: BudgetLimitsTransactionResponse[];
  };
  [MediatorType.Casino]: {
    [period: string]: BudgetLimitsTransactionResponse[];
  };
  [MediatorType.Sports]: {
    [period: string]: BudgetLimitsTransactionResponse[];
  };
}
