import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserProfileQueryVariables = Types.Exact<{
  userProfileId: Types.Scalars['ID']['input'];
  xTenantId: Types.Scalars['ID']['input'];
}>;


export type UserProfileQuery = { __typename?: 'Query', userProfile: { __typename?: 'UserProfile', user?: { __typename?: 'Person', familyName?: string | null, givenName?: string | null, birthDate?: string | null, email?: string | null, address?: { __typename?: 'Address', addressCountry?: string | null, addressLocality?: string | null, addressRegion?: string | null, postOfficeBoxNumber?: string | null, postalCode?: string | null, streetAddress?: string | null } | null, mobileNumber?: { __typename?: 'TelephoneNumber', number?: string | null, prefix?: string | null } | null } | null, consents?: { __typename?: 'Consents', smsAllowed?: string | null, callAllowed?: string | null, emailAllowed?: string | null, basicCookiesAllowed?: boolean | null, functionalCookiesAllowed?: boolean | null, performanceCookiesAllowed?: boolean | null } | null, compliance?: { __typename?: 'Compliance', isDepositAllowed?: boolean | null, isMarketingAllowed?: boolean | null, idVerificationStatus?: string | null, kycLevel?: number | null } | null } };


export const UserProfileDocument = gql`
    query UserProfile($userProfileId: ID!, $xTenantId: ID!) {
  userProfile(userProfileId: $userProfileId, xTenantID: $xTenantId) {
    user {
      familyName
      givenName
      birthDate
      email
      address {
        addressCountry
        addressLocality
        addressRegion
        postOfficeBoxNumber
        postalCode
        streetAddress
      }
      mobileNumber {
        number
        prefix
      }
    }
    consents {
      smsAllowed
      callAllowed
      emailAllowed
      basicCookiesAllowed
      functionalCookiesAllowed
      performanceCookiesAllowed
    }
    compliance {
      isDepositAllowed
      isMarketingAllowed
      idVerificationStatus
      kycLevel
    }
  }
}
    `;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *      userProfileId: // value for 'userProfileId'
 *      xTenantId: // value for 'xTenantId'
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables> & ({ variables: UserProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export function useUserProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileSuspenseQueryHookResult = ReturnType<typeof useUserProfileSuspenseQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;