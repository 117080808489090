import axios from 'axios';
import { UserProfileResponse } from '../../../hooks/registration';
import { xTenantId } from '../../../consts';

// Type definition for the global SEON object
declare global {
  interface Window {
    seon: {
      init: () => Promise<void>;
      getSession: (config?: { session_id?: string }) => Promise<string>;
    };
  }
}

interface SeonRequestBody {
  action_type: string;
  user_id: string;
  user_fullname?: string;
  user_firstname?: string;
  user_lastname?: string;
  user_dob?: string;
  email?: string;
  user_country?: string;
  user_city?: string;
  user_region?: string;
  user_zip?: string;
  user_street?: string;
  phone_number?: string;
  session?: string;
  brand_id: string;
}

const SEON_CHECK_COMPLETED_KEY = 'seon_check_completed';

// Helper to ensure SEON is loaded
const getSeonInstance = (): Promise<Window['seon']> => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => reject(new Error('SEON SDK load timeout')), 5000);
    const checkSeon = (): void => {
      if (window.seon) {
        clearTimeout(timeout);
        resolve(window.seon);
      } else {
        setTimeout(checkSeon, 100);
      }
    };

    checkSeon();
  });
};

export const seonService = async (user: UserProfileResponse, userId: string): Promise<void> => {
  if (localStorage.getItem(SEON_CHECK_COMPLETED_KEY)) {
    return;
  }

  try {
    // Initialize SEON and get session data
    const seon = await getSeonInstance();

    await seon.init();
    const sessionData = await seon.getSession();

    // Construct full name if both parts are available
    const fullName = user.givenName && user.familyName ? `${user.givenName} ${user.familyName}`.trim() : undefined;

    // Format phone number if available
    const phoneNumber =
      user.mobileNumber?.prefix && user.mobileNumber?.number
        ? `${user.mobileNumber.prefix}${user.mobileNumber.number}`
        : undefined;

    const requestBody: SeonRequestBody = {
      // Required fields
      action_type: 'account_creation',
      user_id: userId,
      session: sessionData,

      // User details
      user_fullname: fullName,
      user_firstname: user.givenName || undefined,
      user_lastname: user.familyName || undefined,
      user_dob: user.birthDate ? new Date(user.birthDate).toISOString().split('T')[0] : undefined,
      email: user.email || undefined,

      // Address details
      user_country: user.address?.addressCountry || undefined,
      user_city: user.address?.addressLocality || undefined,
      user_region: user.address?.addressRegion || undefined,
      user_zip: user.address?.postalCode || undefined,
      user_street: user.address?.streetAddress || undefined,

      // Contact details
      phone_number: phoneNumber,

      // other details
      brand_id: xTenantId,
    };

    // Remove any undefined fields to keep the request payload clean
    Object.keys(requestBody).forEach((key) => {
      if (requestBody[key as keyof SeonRequestBody] === undefined) {
        delete requestBody[key as keyof SeonRequestBody];
      }
    });

    const response = await axios.post('/seon', requestBody);

    // Only set the completed flag if the request was successful
    if (response.status === 200) {
      localStorage.setItem(SEON_CHECK_COMPLETED_KEY, 'true');
    }

    return response.data.status;
  } catch (error) {
    console.error('Error calling SEON service:', error);
  }
};
