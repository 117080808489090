import React, { FC } from 'react';

import { ButtonSize, ButtonVariant } from '../Button';
import './ButtonBack.scss';
import noop from 'lodash/noop';
import { Link } from '../Link';
import { APP_URLS } from '../../../consts';

type ButtonBackProps = {
  position?: 'right' | 'left';
  icon?: React.ReactNode;
  type: 'button' | 'submit' | 'reset';
  size?: ButtonSize;
  onClick?: () => void;
  variant?: ButtonVariant;
  text?: string;
  siteLink: string;
};

export const ButtonBack: FC<ButtonBackProps> = ({
  position,
  icon,
  type,
  size = 'large',
  onClick = noop,
  variant = 'primary',
  text,
}) => {
  return (
    <Link
      href={APP_URLS.promotions}
      icon={icon}
      iconPosition={position}
      className="back-button__text"
      buttonStyle={{ variant, size }}
      type={type}
      onClick={onClick}
    >
      {text}
    </Link>
  );
};
