import React, { FunctionComponent } from 'react';
import { formatDate } from 'date-fns/format';
import './PromotionProgressBar.scss';
import { ParsedPromo } from '../types';
import { BonusBar } from '../../../shared/SVG/BonusBar';
import { useSelector } from 'react-redux';
import { promotionTabName } from '../../../../state/promotions';
import { PromotionTabsType } from '../PromotionsList/types';
import { formatCurrency } from '../../../../utils/wallets';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

export type PromotionProgressBarProps = {
  promotion: ParsedPromo;
};

export const PromotionProgressBar: FunctionComponent<PromotionProgressBarProps> = ({ promotion }) => {
  const { t } = useTranslations();
  const currentPromoTab = useSelector(promotionTabName);
  const isDetailsPage = currentPromoTab === PromotionTabsType.BONUS_DETAILS;

  const renderPercentage = (promotion: ParsedPromo): number => {
    return (Number(promotion.wageredAmount) / Number(promotion.wageringRequirementAmount)) * 100;
  };
  const renderAmountLeft = (promotion: ParsedPromo): number => {
    return Number(promotion.wageringRequirementAmount) - Number(promotion.wageredAmount);
  };
  const renderExpiryDate = (endDate?: string): string => {
    return formatDate(endDate || new Date().toISOString(), 'dd/MM/yyyy');
  };
  const resultBalance = Number(
    Number(isDetailsPage ? promotion.balanceAmount : promotion.wageringRequirementAmount).toFixed(2)
  );
  const amountLeft = renderAmountLeft(promotion).toFixed(2);

  const showResultBalance = Number.isFinite(resultBalance);
  const showAmountLeft = Number.isFinite(amountLeft);

  return (
    <>
      <div className="promotion-progress-bar__summary">
        <div className="promotion-progress-bar__summary-heading">
          <div className="promotion-progress-bar__summary-info">
            <span className="promotion-progress-bar__summary-title">{promotion.title}</span>
            <span className="promotion-progress-bar__summary-expire">
              {t('promotions.progress-bar.expires')} {renderExpiryDate(promotion.expiresOn)}
            </span>
          </div>
          {showResultBalance && (
            <span className="promotion-progress-bar__summary-amount">
              {formatCurrency(resultBalance, 'auto', 'auto')}
            </span>
          )}
        </div>
        {showAmountLeft && (
          <>
            <BonusBar value={renderPercentage(promotion)} height={4} />
            <div className="promotion-progress-bar__summary-subheading">
              {t('promotions.progress-bar.wagering')}{' '}
              <span className="promotion-progress-bar__summary-amount-wager">
                {formatCurrency(Number(amountLeft), 'auto', 'auto')}
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};
