import { seonService } from './seon-service';
import { useUserContext } from '../../../providers/UserProvider';
import { FunctionComponent, useEffect } from 'react';

export const Seon: FunctionComponent = () => {
  const { user, userProfileId } = useUserContext();

  useEffect(() => {
    if (user) {
      seonService(user, userProfileId);
    }
  }, [user, userProfileId]);

  return null;
};
