/* eslint-disable @typescript-eslint/ban-ts-comment */
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckboxFormField, FormControl, FormField } from '../../../shared/Form';
import { FieldSet } from '../../../shared/FieldSet';
import { Paragraph } from '../../../shared/Paragraph';
import { Grid, GridItem, calculateGridItemConfigurations } from '../../../shared/Grid';
import { FormControlSpacing } from '../../../shared/FormControlSpacing';
import { FlattenedProfileData } from '../../../../hooks/registration';
import { AccountFieldSetConfig } from '../../types';

enum AccountFieldSetNames {
  toggleMarketingCommunications = 'toggleAllMarketing',
  toggleMarketingContactName = 'toggleAllContact',
  acceptMarketingCommunications = 'acceptMarketingCommunications',
}

/**
 * Component which will render registration or account form fields in a grid layout
 *
 * Depends upon react hook form context.
 */
export const AccountDetailFieldSets = <T extends FlattenedProfileData>({
  formFields,
  fieldSets,
  marketingFieldNames,
  marketingFieldNamesRegister,
  contactFieldNames,
  fieldSetChildren,
  setSubmitDisabled = noop,
}: {
  fieldSets: AccountFieldSetConfig<T>[];
  formFields: Record<string, FormField<T>>;
  marketingFieldNames: string[];
  marketingFieldNamesRegister?: string[];
  contactFieldNames?: string[];
  fieldSetChildren?: { render: React.ReactNode; order: number };
  setSubmitDisabled?: (value: boolean) => void;
}): JSX.Element => {
  const { setValue, getValues } = useFormContext();

  const marketingFieldNamesOptions = marketingFieldNames.length ? marketingFieldNames : marketingFieldNamesRegister;

  const setMarketingPreferences = useCallback(
    (initMode?: boolean) => {
      const marketingPreferencesData = {
        optInEmail: false,
        optInTelephone: false,
        optInSms: false,
      };

      const allMarketingChecked = getValues(AccountFieldSetNames.toggleMarketingCommunications);

      // Don't push marketing data to form state if there is no select all checkbox or it's unchecked on a first step
      if (initMode && !allMarketingChecked) return;

      // Invert select all marketing checkbox value after initial component render as a form state changes slowly
      const resultCondition = initMode ? allMarketingChecked : !allMarketingChecked;

      Object.keys(marketingPreferencesData)?.forEach((fieldName) =>
        // @ts-ignore
        setValue(fieldName, resultCondition ? marketingPreferencesData[fieldName] : false)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Fragment>
      {fieldSets.map((fieldSet, fieldSetIndex) => {
        if (fieldSet.hidden) {
          return null;
        }

        const { fields } = fieldSet;
        const showFieldset = !fields[0].hidden;

        const columns = fields.map((step) => step.columns || 12);
        const columnConfig = calculateGridItemConfigurations(columns);

        const marketingSelected = !getValues(AccountFieldSetNames.toggleMarketingCommunications);
        const contactSelected = getValues(AccountFieldSetNames.toggleMarketingContactName);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { contactValue } = useMemo(() => {
          return {
            marketingValue: marketingSelected,
            contactValue: contactSelected,
          };
        }, [marketingSelected, contactSelected]);

        return (
          showFieldset && (
            <FieldSet
              key={fieldSet.id}
              id={fieldSet.id}
              legendSrOnly={fieldSet.labelSrOnly}
              legendHeadingStyleLevel={6}
              legend={fieldSet.label}
            >
              {fieldSet.editDescription && <Paragraph size="sm">{fieldSet.editDescription}</Paragraph>}
              <Grid columns={12}>
                {fields.map(({ name, optional, hidden }, fieldIndex) => {
                  if (hidden) {
                    // eslint-disable-next-line array-callback-return
                    return;
                  }

                  // Insert children components only in first displayed fieldset
                  const showFieldSetChildren =
                    !fieldSetIndex && fieldSetChildren && fieldIndex === fieldSetChildren.order;

                  const fieldName = name as string;
                  const gridItemConfig = columnConfig[fieldIndex];
                  const field = formFields[fieldName] as CheckboxFormField<T>;

                  if (name === AccountFieldSetNames.acceptMarketingCommunications) {
                    field.props = {
                      ...field.props,
                      onClick: (): void => {
                        setMarketingPreferences();
                      },
                    };
                  }

                  if (name === AccountFieldSetNames.toggleMarketingCommunications) {
                    field.props = {
                      ...field.props,
                      onClick: (): void => {
                        marketingFieldNamesOptions?.forEach((fieldName) =>
                          setValue(fieldName, !getValues(AccountFieldSetNames.toggleMarketingCommunications))
                        );
                      },
                    };
                  } else if (field && marketingFieldNamesOptions?.includes(fieldName)) {
                    field.props = {
                      ...field.props,
                      onClick: (): void => {
                        const currentValues = getValues(marketingFieldNamesOptions);
                        const fieldNameIndex = marketingFieldNamesOptions.indexOf(field.name as string);
                        const otherValues = currentValues.splice(fieldNameIndex, 1);
                        // @ts-ignore
                        const nextFieldValue = !otherValues[0];
                        const toggleState = currentValues.concat(nextFieldValue).every((val) => val);

                        setValue(AccountFieldSetNames.toggleMarketingCommunications, toggleState);
                      },
                    };
                  }

                  if (name === AccountFieldSetNames.toggleMarketingContactName) {
                    field.props = {
                      ...field.props,
                      onClick: () =>
                        contactFieldNames?.forEach((fieldName) => {
                          setValue(fieldName, !contactValue);
                          setSubmitDisabled(contactValue);
                        }),
                    };
                  } else if (contactFieldNames?.includes(fieldName)) {
                    field.props = {
                      ...field.props,
                      onClick: (): void => {
                        const fieldName = field.name as string;
                        const currentValues = getValues(contactFieldNames);
                        const otherValues = Object.values(omit(currentValues, fieldName));
                        // @ts-ignore
                        const nextFieldValue = !currentValues[fieldName];
                        const toggleState = otherValues.concat(nextFieldValue).every((val) => val);
                        const preferenceEnabled = otherValues.concat(nextFieldValue).some((val) => val);

                        setSubmitDisabled(!preferenceEnabled);
                        setValue(AccountFieldSetNames.toggleMarketingContactName, toggleState);
                      },
                    };
                  }

                  return (
                    <GridItem key={fieldName} startCol={gridItemConfig.startCol} endCol={gridItemConfig.endCol}>
                      <FormControlSpacing>
                        <FormControl {...field} optional={optional} />
                      </FormControlSpacing>
                      {showFieldSetChildren && fieldSetChildren?.render}
                    </GridItem>
                  );
                })}
              </Grid>
            </FieldSet>
          )
        );
      })}
    </Fragment>
  );
};
