import { AccountPageWrapperProps } from '../MyAccount/AccountPageWrapper';

export type MyAccountActivityProps = Pick<AccountPageWrapperProps, 'elementToFocusOnOpen' | 'onClose'>;

export enum PromoCancelStatus {
  Active = 'Active',
  PartQualified = 'PartQualified',
}

export enum ValidSubType {
  Authorized = 'authorized',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Rejected = 'rejected',
  RejectedByCompliance = 'rejectedbycompliance',
  Reversed = 'reversed',
  Unknown = 'unknown',
}

export enum MediatorType {
  monetary = 'monetary',
  sports = 'sports',
  casino = 'casino',
}
