import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TagsObjectQueryVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  externalResource: Types.Scalars['String']['input'];
  externalResourceId: Types.Scalars['String']['input'];
}>;


export type TagsObjectQuery = { __typename?: 'Query', tagsObject?: Array<{ __typename?: 'TagsObject', tag?: { __typename?: 'Tags', id?: string | null, name?: string | null, tagCategory?: { __typename?: 'TagCategory', name?: string | null } | null } | null }> | null };


export const TagsObjectDocument = gql`
    query TagsObject($xTenantId: ID!, $externalResource: String!, $externalResourceId: String!) {
  tagsObject(
    xTenantID: $xTenantId
    externalResource: $externalResource
    externalResourceId: $externalResourceId
  ) {
    tag {
      id
      name
      tagCategory {
        name
      }
    }
  }
}
    `;

/**
 * __useTagsObjectQuery__
 *
 * To run a query within a React component, call `useTagsObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsObjectQuery({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      externalResource: // value for 'externalResource'
 *      externalResourceId: // value for 'externalResourceId'
 *   },
 * });
 */
export function useTagsObjectQuery(baseOptions: Apollo.QueryHookOptions<TagsObjectQuery, TagsObjectQueryVariables> & ({ variables: TagsObjectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsObjectQuery, TagsObjectQueryVariables>(TagsObjectDocument, options);
      }
export function useTagsObjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsObjectQuery, TagsObjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsObjectQuery, TagsObjectQueryVariables>(TagsObjectDocument, options);
        }
export function useTagsObjectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TagsObjectQuery, TagsObjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TagsObjectQuery, TagsObjectQueryVariables>(TagsObjectDocument, options);
        }
export type TagsObjectQueryHookResult = ReturnType<typeof useTagsObjectQuery>;
export type TagsObjectLazyQueryHookResult = ReturnType<typeof useTagsObjectLazyQuery>;
export type TagsObjectSuspenseQueryHookResult = ReturnType<typeof useTagsObjectSuspenseQuery>;
export type TagsObjectQueryResult = Apollo.QueryResult<TagsObjectQuery, TagsObjectQueryVariables>;