import { NavigateFunction } from 'react-router-dom';
import { openNewWindow } from '../../components/Navigation/NavigationLink/helper';
import { LobbyItem } from '../../components/Games/games.model';
import { IconVariantEnum } from '../../components/shared/Icon';
import { ProductCategoryLink } from '../../components/Navigation/types';
import { capitalizeFirstLetter } from '../form';

const extLinkRegExp = /^http[s]?:|^\/\//i;

export const checkIsUrlExternal = (link: string): boolean => extLinkRegExp.test(link);

export const goToLink = (link: string, navigate: NavigateFunction, target = '_self'): void | boolean => {
  return checkIsUrlExternal(link) ? openNewWindow(link, target) : navigate(link);
};

export const getRealPath = (launchUrl: URL | string): string => {
  return launchUrl.toString().includes('#') ? '/real' : '#/real';
};

export const getURLSearchParam = (name: string, location: Partial<Location>): string | null => {
  const { search } = location;

  return new URLSearchParams(search).get(name);
};

export const prepareCategories = (categories?: LobbyItem[], path = ''): ProductCategoryLink[] => {
  if (!categories?.length) {
    return [];
  }

  return categories.map((cat) => ({
    label: cat.title,
    authenticated: true,
    unauthenticated: true,
    url: `${path}/${cat.slug}`,
    icon: capitalizeFirstLetter(cat.icon.toLowerCase()) as IconVariantEnum,
    iconActive: capitalizeFirstLetter(cat.icon.toLowerCase()) as IconVariantEnum,
  }));
};

export const handleLinkSlashSymbol = (value = ''): string => {
  if (checkIsUrlExternal(value)) return value;

  if (value && value[0] === '/') {
    return value;
  }

  return '/' + value;
};

export const getExternalUrlParam = (paramName: string): string | null =>
  new URLSearchParams(window.location.search).get(paramName);
