import { FC, useEffect, useState } from 'react';
import { useSnackbar } from '../../hooks/snackbar';
import { useUserContext } from '../../providers/UserProvider';
import { useMessagesGetByProfileLazyQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/messages-get-by-profile.query.generated';
import { useMessageDeleteMutation } from '../../libs/graphql/betzoneDirectusAPI/mutations/__generated__/message-delete.mutation.generated';
import { Endpoint } from '../../models/api.model';
import { Button } from '../shared/Button';
import { useTranslations } from '../../hooks/useTranslationsHelper';
import DOMPurify from 'dompurify';
import { filterSpecialHTMLCharacters } from '../../utils/string';
import { Heading } from '../shared/Heading';
import { Paragraph } from '../shared/Paragraph';
import { storageAfterLogin } from '../../utils/storage';
import './LoggedInMessages.scss';
import { useOnMessageCreatedSubscription } from '../../libs/graphql/betzoneDirectusAPI/subscriptions/__generated__/message-created.generated';
import { notifyGiG } from './service';

export const LoggedInMessages: FC = () => {
  const { t } = useTranslations();
  const { addSnack } = useSnackbar();
  const { userProfileId } = useUserContext();

  const sanitizer = DOMPurify.sanitize;

  const afterLogin = storageAfterLogin.get();

  const [messageParams, setMessageParams] = useState<{ id: string; ruleName: string | null | undefined }>({
    id: '',
    ruleName: '',
  });

  const [fetchMessages, { data: userMessages, error: messageError }] = useMessagesGetByProfileLazyQuery({
    variables: {
      userProfileId,
    },
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  const { data: userSubscribedMessages, error: subscribedMessageError } = useOnMessageCreatedSubscription({
    variables: {},
  });

  const [messageDeleteMutation, { error: deleteMessageError }] = useMessageDeleteMutation({
    variables: {
      id: messageParams.id,
    },
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  const addMessageSnack = (
    message:
      | {
          id: string;
          message_header?: string | null;
          message_content?: string | null;
          rule_name?: string | null;
        }
      | null
      | undefined
  ): void => {
    const { id, message_header, message_content, rule_name } = message || {};

    return addSnack({
      theme: 'dark',
      type: 'info',
      message: (
        <div className="message-snack">
          <Heading level={5}>{message_header}</Heading>
          <Paragraph
            noMargin
            dangerouslySetInnerHTML={{
              __html: filterSpecialHTMLCharacters(sanitizer(String(message_content))),
            }}
            className="aem-text"
          />
        </div>
      ),
      // eslint-disable-next-line react/display-name
      action: () => (
        <Button
          type="button"
          variant="text"
          size="medium"
          className="reality-checks__undo-btn"
          onClick={(): void => setMessageParams({ id: String(id), ruleName: rule_name })}
        >
          {t('buttons.close')}
        </Button>
      ),
      isDismissible: true,
      displayTime: 0,
    });
  };

  useEffect(() => {
    const { id, ruleName } = messageParams;

    // Delete message in DirectUs and send it to PAM
    id && messageDeleteMutation();
    ruleName && notifyGiG(userProfileId, ruleName);
  }, [messageParams]);

  useEffect(() => {
    userMessages?.messages?.map((message) => addMessageSnack(message));
  }, [userMessages]);

  useEffect(() => {
    const subscribedMessage = userSubscribedMessages?.messages_mutated?.data;
    const { status, user_profile_id: subscriptionUserProfileId } = subscribedMessage || {};

    status === 'published' && userProfileId === subscriptionUserProfileId && addMessageSnack(subscribedMessage);
  }, [userSubscribedMessages]);

  useEffect(() => {
    const resMessage = messageError || deleteMessageError || subscribedMessageError;

    resMessage &&
      addSnack({
        theme: 'dark',
        type: 'error',
        message: String(resMessage),
        isDismissible: true,
      });
  }, [messageError, deleteMessageError, subscribedMessageError]);

  useEffect(() => {
    if (afterLogin) {
      fetchMessages();
      storageAfterLogin.set({ value: false });
    }
  }, [afterLogin]);

  return null;
};
