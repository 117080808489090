import React, { FC, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { currencyCode, xTenantId } from '../../../consts';
import { useSnackbar } from '../../../hooks/snackbar';
import { BudgetLimitsTransactionType } from '../../../libs/graphql/baseAppAPI/__generated_types__/types.generated';
import { useTransactionLimitsCreateMutation } from '../../../libs/graphql/baseAppAPI/mutations/__generated__/transaction-limits-create.generated';
import { useTransactionLimitsDeleteMutation } from '../../../libs/graphql/baseAppAPI/mutations/__generated__/transaction-limits-delete.mutation.generated';
import { useUserBudgetLimitsQuery } from '../../../libs/graphql/baseAppAPI/queries/__generated__/obtaining-limits.query.generated';
import { useUserContext } from '../../../providers/UserProvider';
import { ActivationStatus, BudgetLimitsTransactionResponse, CreatorName } from '../../../types.__generated__';
import { groupLimitsData } from '../helper';
import { IGroupedLimits, MediatorType, SetPlayerLimitsData } from '../types';
import AccountLimitsHowItWorks from './AccountLimitsHowItWorks';
import { AccountLimitsModal } from './AccountLimitsModal';
import { AccountLimitsRemoveModal } from './AccountLimitsModal/AccountLimitsRemoveModal';
import { AccountLimitsSection } from './AccountLimitsSection';
import { LimitsHistory } from './LimitsHistory';
import { LimitPeriodsEnum, ModalAction } from './types';
import { useTranslations } from '../../../hooks/useTranslationsHelper';
import './styles';

export const AccountLimits: FC = () => {
  const { t } = useTranslations();
  const auth = useAuth();
  const { addSnack } = useSnackbar();
  const { userProfileId } = useUserContext();
  const profileId = userProfileId || (auth.user?.profile?.user_profile_id as string);

  const reason = 'Requested by user';

  const [data, setData] = useState<IGroupedLimits>();
  const [allTransactions, setAlltransactions] = useState<BudgetLimitsTransactionResponse[]>();
  const [displayRemoveModal, setDisplayRemoveModal] = useState(false);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [displayLimitsHistory, setDisplayLimitsHistory] = useState(false);
  const [displayHowItWorksModal, setDisplayHowItWorksModal] = useState(false);
  const [activeMediatorType, setActiveMediatorType] = useState<MediatorType | undefined>(undefined);
  const [period, setPeriod] = useState<LimitPeriodsEnum | undefined>(undefined);

  const currentItem = allTransactions?.find(
    (item) =>
      item.mediatorType === activeMediatorType &&
      item.period === period &&
      item.activationStatus === ActivationStatus.Active &&
      !item.isPendingCancellation
  );

  const { refetch, loading } = useUserBudgetLimitsQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      xTenantId,
      userProfileId: profileId,
    },
    onCompleted: (res) => {
      const test = res?.userBudgetLimits?.length ? [...res.userBudgetLimits] : [];
      const sortedData = test.sort((a, b): number => {
        const periodA = parseInt(String(a?.period).slice(1), 10);
        const periodB = parseInt(String(b?.period).slice(1), 10);

        return periodA - periodB;
      }) as BudgetLimitsTransactionResponse[];

      setAlltransactions(sortedData);
      setData(groupLimitsData(sortedData));
    },
  });

  const [transactionLimitsCreateMutation] = useTransactionLimitsCreateMutation({
    onCompleted: async () => {
      await refetch();
      closeModal();
      addSnack({
        type: 'success',
        message: t('limits.limits-updated-success'),
      });
    },
    onError: (error) => {
      addSnack({
        type: 'error',
        message: `${t('errors.detailed')} ${error?.message}`,
      });
    },
  });

  const [transactionLimitsDeleteMutation, { loading: isDeleting }] = useTransactionLimitsDeleteMutation({
    onCompleted: async () => {
      await refetch();
      addSnack({
        type: 'success',
        message: t('limits.delete-message'),
      });
      closeModal();
    },
    onError: () => {
      addSnack({
        type: 'error',
        message: t('errors.sorry'),
      });
    },
  });

  const openModal = (period?: LimitPeriodsEnum, mediatorType?: MediatorType, action?: ModalAction): void => {
    setActiveMediatorType(mediatorType);
    setPeriod(period);

    if (action === ModalAction.Edit) {
      setDisplayEditModal(true);
    } else if (action === ModalAction.Remove) {
      setDisplayRemoveModal(true);
    }
  };

  const closeModal = (): void => {
    setDisplayEditModal(false);
    setDisplayRemoveModal(false);
  };

  const openLimitsHistoryModal = (mediatorType: MediatorType): void => {
    setActiveMediatorType(mediatorType);
    setDisplayLimitsHistory(true);
  };

  const openHowItWorksModal = (mediatorType: MediatorType): void => {
    setActiveMediatorType(mediatorType);
    setDisplayHowItWorksModal(true);
  };

  const closeHowItWorksModal = (): void => {
    setDisplayHowItWorksModal(false);
  };

  const closeLimitsHistoryModal = (): void => {
    setDisplayLimitsHistory(false);
    setActiveMediatorType(undefined);
  };

  const onEditLimitClick = (): void => {
    setDisplayEditModal(true);
    setDisplayRemoveModal(false);
  };

  const onSubmit = async (data: SetPlayerLimitsData): Promise<void> => {
    await transactionLimitsCreateMutation({
      variables: {
        userProfileId: (auth.user?.profile?.user_profile_id as string) || '',
        xTenantId,
        creatorName: CreatorName.User,
        budgetLimitsCreateTransaction: {
          amount: data?.amount || 0,
          createdBy: (auth.user?.profile?.user_profile_id as string) || '',
          currencyCode,
          mediatorType: data?.mediatorType,
          reason,
          period: data?.period || LimitPeriodsEnum.daily,
          type: data?.type as unknown as BudgetLimitsTransactionType,
        },
      },
    });
  };

  const onRemoveLimit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    await transactionLimitsDeleteMutation({
      variables: {
        limitId: currentItem?.id || '',
        xTenantId,
        userProfileId: profileId,
        creatorName: CreatorName.User,
      },
    });
  };

  return (
    <>
      <AccountLimitsSection
        onItemClick={openModal}
        data={data?.[MediatorType.Monetary]}
        openLimitsHistoryModal={openLimitsHistoryModal}
        openHowItWorksModal={openHowItWorksModal}
        mediatorType={MediatorType.Monetary}
        isDataInitialised={!loading}
        refetchLimits={refetch}
      />

      <AccountLimitsSection
        onItemClick={openModal}
        data={data?.[MediatorType.Casino]}
        openLimitsHistoryModal={openLimitsHistoryModal}
        openHowItWorksModal={openHowItWorksModal}
        mediatorType={MediatorType.Casino}
        isDataInitialised={!loading}
        refetchLimits={refetch}
      />

      <AccountLimitsSection
        onItemClick={openModal}
        data={data?.[MediatorType.Sports]}
        openLimitsHistoryModal={openLimitsHistoryModal}
        openHowItWorksModal={openHowItWorksModal}
        mediatorType={MediatorType.Sports}
        isDataInitialised={!loading}
        refetchLimits={refetch}
      />

      {activeMediatorType && displayLimitsHistory && (
        <LimitsHistory onClose={closeLimitsHistoryModal} show mediatorType={activeMediatorType} />
      )}

      {activeMediatorType && displayEditModal && (
        <AccountLimitsModal
          onClose={closeModal}
          onSubmit={onSubmit}
          data={data?.[activeMediatorType]}
          isOpen={displayEditModal}
          mediatorType={activeMediatorType}
        />
      )}

      <AccountLimitsHowItWorks
        isOpen={displayHowItWorksModal}
        onClose={closeHowItWorksModal}
        mediatorType={activeMediatorType}
      />
      <AccountLimitsRemoveModal
        isOpen={displayRemoveModal}
        onClose={closeModal}
        mediatorType={activeMediatorType}
        activePeriod={period}
        onEditLimitClick={onEditLimitClick}
        onRemoveLimit={onRemoveLimit}
        limit={currentItem?.amount || 0}
        loading={isDeleting}
      />
    </>
  );
};
