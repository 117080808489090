import { useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';

/**
 * Hook that returns true/false denoting whether the user is idle
 * or not on the site. It checks mouse movements, touch events
 * @param time
 * @returns
 */
export const useIdle = (time: number, callback?: (isIdle: boolean) => void): boolean => {
  const idleRef = useRef(false);
  const auth = useAuth();
  const userId = auth.user?.profile.user_profile_id as string | undefined;
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const handleEvent = (): void => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (idleRef.current !== false) {
        callbackRef.current?.(false);
      }

      idleRef.current = false;

      timeoutId = setTimeout(() => {
        if (idleRef.current !== true) {
          callbackRef.current?.(true);
        }

        idleRef.current = true;
      }, time);
    };

    document.addEventListener('mousemove', handleEvent, { passive: true });
    document.addEventListener('keydown', handleEvent, { passive: true });
    document.addEventListener('touchstart', handleEvent, { passive: true });
    document.addEventListener('touchend', handleEvent, { passive: true });
    document.addEventListener('scroll', handleEvent, { passive: true });

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      document.removeEventListener('mousemove', handleEvent);
      document.removeEventListener('keydown', handleEvent);
      document.removeEventListener('touchstart', handleEvent);
      document.removeEventListener('touchend', handleEvent);
      document.removeEventListener('scroll', handleEvent);
    };
  }, [time, userId]);

  return idleRef.current;
};
