import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserFreeSpinQueryVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  userProfileId: Types.Scalars['ID']['input'];
  pageLimit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type UserFreeSpinQuery = { __typename?: 'Query', userFreeSpin?: { __typename?: 'PagedUserProfileFreeSpin', data?: Array<{ __typename?: 'UserProfileFreeSpin', freeSpinsTemplateId?: string | null, bonusTemplateId?: string | null, status?: Types.UserProfileFreeSpinStatus | null, mediatorId?: string | null, expiresOn?: string | null } | null> | null } | null };


export const UserFreeSpinDocument = gql`
    query UserFreeSpin($xTenantId: ID!, $userProfileId: ID!, $pageLimit: Int) {
  userFreeSpin(
    xTenantID: $xTenantId
    userProfileId: $userProfileId
    pageLimit: $pageLimit
  ) {
    data {
      freeSpinsTemplateId
      bonusTemplateId
      status
      mediatorId
      expiresOn
    }
  }
}
    `;

/**
 * __useUserFreeSpinQuery__
 *
 * To run a query within a React component, call `useUserFreeSpinQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFreeSpinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFreeSpinQuery({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      userProfileId: // value for 'userProfileId'
 *      pageLimit: // value for 'pageLimit'
 *   },
 * });
 */
export function useUserFreeSpinQuery(baseOptions: Apollo.QueryHookOptions<UserFreeSpinQuery, UserFreeSpinQueryVariables> & ({ variables: UserFreeSpinQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFreeSpinQuery, UserFreeSpinQueryVariables>(UserFreeSpinDocument, options);
      }
export function useUserFreeSpinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFreeSpinQuery, UserFreeSpinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFreeSpinQuery, UserFreeSpinQueryVariables>(UserFreeSpinDocument, options);
        }
export function useUserFreeSpinSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserFreeSpinQuery, UserFreeSpinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserFreeSpinQuery, UserFreeSpinQueryVariables>(UserFreeSpinDocument, options);
        }
export type UserFreeSpinQueryHookResult = ReturnType<typeof useUserFreeSpinQuery>;
export type UserFreeSpinLazyQueryHookResult = ReturnType<typeof useUserFreeSpinLazyQuery>;
export type UserFreeSpinSuspenseQueryHookResult = ReturnType<typeof useUserFreeSpinSuspenseQuery>;
export type UserFreeSpinQueryResult = Apollo.QueryResult<UserFreeSpinQuery, UserFreeSpinQueryVariables>;