import { joinStrings } from '../../../../utils/string';
import { CouponDescriptionWithOdds } from '../CouponDescriptionWithOdds';
import { FC, ReactNode, useMemo } from 'react';
import { PromotionCancelButton } from '../../../Layout/Promotions/PromotionCancelButton';
import { BonusHistoryItem } from '../../../../models/bonuses.model';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { formatCurrency } from '../../../../utils/wallets';

type MyAccountActivityContentProps = {
  /**
   * The payout amount.
   */
  payout?: number;
  /**
   * The coupon reference.
   */
  couponRef?: string;
  /**
   * A ReactNode representing the activity content.
   */
  content?: ReactNode;
  /**
   * The activity description.
   */
  description?: string;
  /**
   * A boolean representing whether the cancel button should be displayed.
   */
  isCancelBtn?: boolean;
  /**
   * The promo object.
   */
  activatedPromo?: BonusHistoryItem;
  /**
   * The RewardType.
   */
  rewardType?: string;
  /**
   * The transaction amount
   */
  transactionAmount?: number;
  /**
   * The isSingleBet bets
   */
  isSingleBet?: boolean;
};

export const MyAccountActivityContent: FC<MyAccountActivityContentProps> = ({
  payout,
  content,
  couponRef,
  description,
  isCancelBtn,
  activatedPromo,
}) => {
  const { t } = useTranslations();
  const formattedStake = useMemo(() => formatCurrency(payout, 'auto', 'auto'), [payout]);

  return (
    <div className="my-account-activity__activity-body">
      <span className="my-account-activity__activity-body-item-heading">
        {t('my-account.home.my-activity.coupon.description')}
      </span>
      <span
        className={joinStrings([
          'my-account-activity__coupon-details',
          'my-account-activity__coupon-details-description',
        ])}
      >
        {typeof description === 'string' ? description : <CouponDescriptionWithOdds description={description} />}
      </span>
      <span className="my-account-activity__coupon-details">{content}</span>
      {Number.isFinite(payout) && (
        <div className="my-account-activity__coupon-info">
          <span className="tddi__coupon-details">{`${t('my-account.transactionValue')}: ${formattedStake}`}</span>
        </div>
      )}
      {couponRef && <span className="my-account-activity__coupon-ref">{`Ref: ${couponRef}`}</span>}
      {isCancelBtn && (
        <div className="my-account-activity__cancel-button">
          <PromotionCancelButton activatedPromo={activatedPromo as BonusHistoryItem} />
        </div>
      )}
    </div>
  );
};
