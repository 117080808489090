import React, { FC } from 'react';
import { Icon } from '../../shared/Icon';
import { operationTypes } from './consts';
import { MediatorType } from './types';

export const IncomingIcon: FC = () => (
  <Icon className="my-account-activity-icon my-account-activity-icon--incoming" variant="Share" />
);
export const OutgoingIcon: FC = () => (
  <Icon className="my-account-activity-icon my-account-activity-icon--outgoing" variant="Reply" />
);
export const SportsIcon: FC = () => <Icon className="my-account-activity-icon" variant="IoIosFootball" />;
export const CasinoIcon: FC = () => <Icon className="my-account-activity-icon" variant="IoDiceOutline" />;
export const MonetaryIcon: FC = () => <Icon className="my-account-activity-icon" variant="IoWalletOutline" />;

/**
 * Render icon representing an activity of a certain type
 */
export const MyAccountActivityIcon: FC<{
  operationType: string;
  mediatorType: string;
}> = ({ operationType, mediatorType }) => (
  <>
    {operationType === operationTypes.debit ? <OutgoingIcon /> : <IncomingIcon />}
    {mediatorType === MediatorType.sports && <SportsIcon />}
    {mediatorType === MediatorType.casino && <CasinoIcon />}
    {mediatorType === MediatorType.monetary && <MonetaryIcon />}
  </>
);
