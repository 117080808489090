import { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { ConsentSubmission, CookiesPreferenceContentPayload } from '../../components/Compliance/CookiesPreferences';
import { xTenantId } from '../../consts';
import { useUserProfileUpdateMutation } from '../../libs/graphql/baseAppAPI/mutations/__generated__/user-profile-update.mutation.generated';
import { useUserContext } from '../../providers/UserProvider';
import { storageConsent } from '../../utils/storage';
import { useSnackbar } from '../snackbar';

type UseCookiesClaimsReturnValue = {
  loading: boolean;
  /**
   * Fetch cookies and set constants to state to determine rather cookies should be created or updated
   */
  fetchCookies: () => Promise<void>;
  /**
   * Incoming data for claims from platform
   */
  preferences: null | CookiesPreferenceContentPayload;
  /**
   * Set cookie preferences
   */
  setPreferences: (payload: CookiesPreferenceContentPayload | null) => void;
  /**
   * Create or update cookie claims
   */
  writeCookies: (payload: CookiesPreferenceContentPayload) => Promise<void>;
  /**
   * Update consent storage
   */
  updateConsentStorage: (payload: CookiesPreferenceContentPayload) => void;
  /**
   * Accept all consent properties to storage
   */
  acceptAllConsent: () => void;
};

/**
 * A hook that returns flags that define user's ability to observe deposit content and deposit limit prompt
 */
export function useCookies(): UseCookiesClaimsReturnValue {
  const { isAuthenticated } = useAuth();
  const { addSnack } = useSnackbar();
  const { consents, fetchUserProfile, userProfileId } = useUserContext();

  const storedConsents = useMemo(() => storageConsent.get() as ConsentSubmission, [isAuthenticated]);
  const playerId = storedConsents?.playerId;

  useEffect(() => {
    if (isAuthenticated) {
      const { basicCookiesAllowed, functionalCookiesAllowed, performanceCookiesAllowed } =
        storedConsents?.consent || {};

      if (!playerId) {
        writeCookies({
          isBasic: basicCookiesAllowed,
          isFunctional: functionalCookiesAllowed,
          isPerformance: performanceCookiesAllowed,
        });
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (consents && playerId && playerId !== userProfileId) {
      updateConsentStorage({
        isBasic: Boolean(consents?.basicCookiesAllowed),
        isFunctional: Boolean(consents?.functionalCookiesAllowed),
        isPerformance: Boolean(consents?.performanceCookiesAllowed),
      });
    }
  }, [consents]);

  const [userProfileUpdateMutation, { loading }] = useUserProfileUpdateMutation({
    onCompleted: async (data) => {
      if (!data) return;

      await fetchUserProfile();
      addSuccessSnack({ success: true });
    },
    onError: () => {
      addSuccessSnack({ success: false });
    },
  });

  const [preferences, setPreferences] = useState<null | CookiesPreferenceContentPayload>(null);

  const fetchCookies = async (): Promise<void> => {
    const isFunctional = Boolean(consents?.functionalCookiesAllowed);
    const isPerformance = Boolean(consents?.performanceCookiesAllowed);

    setPreferences({
      isFunctional,
      isPerformance,
    });
  };

  const onCreateConsent = (consentModel: ConsentSubmission): void => {
    storageConsent.set({
      value: consentModel,
    });
  };

  const updateConsent = (consent: ConsentSubmission): void => {
    onCreateConsent({
      consent: consent?.consent,
      playerId: consent?.playerId,
    });
  };

  const updateConsentStorage = (consent: CookiesPreferenceContentPayload): void =>
    updateConsent({
      playerId: userProfileId,
      consent: {
        basicCookiesAllowed: Boolean(consent.isBasic),
        functionalCookiesAllowed: Boolean(consent.isFunctional),
        performanceCookiesAllowed: Boolean(consent.isPerformance),
      },
    });

  const acceptAllConsent = (): void => {
    updateConsent({
      playerId: userProfileId,
      consent: {
        basicCookiesAllowed: true,
        functionalCookiesAllowed: true,
        performanceCookiesAllowed: true,
      },
    });

    isAuthenticated &&
      writeCookies({
        isFunctional: true,
        isPerformance: true,
      });
  };

  const writeCookies = async (payload: CookiesPreferenceContentPayload): Promise<void> => {
    await userProfileUpdateMutation({
      variables: {
        userProfileId,
        xTenantId,
        userProfileUpdateInfo: {
          basicCookiesAllowed: true,
          functionalCookiesAllowed: payload.isFunctional,
          performanceCookiesAllowed: payload.isPerformance,
          cookiePolicyVersionAccepted: '1.0',
        },
      },
    });
    updateConsentStorage(payload);
  };

  const addSuccessSnack = ({ success }: { success: boolean }): void => {
    addSnack({
      type: success ? 'success' : 'error',
      theme: 'dark',
      message: success ? 'Your preferences have been updated.' : 'Unable to save your preferences.',
    });
  };

  return {
    loading,
    preferences,
    fetchCookies,
    setPreferences,
    writeCookies,
    updateConsentStorage,
    acceptAllConsent,
  };
}
