import React, { FC, ReactElement, useEffect, useState } from 'react';
import { CookiesPreferences } from '../CookiesPreferences';
import { Trans } from '../../shared/Trans';
import { Link } from '../../shared/Link';
import { useTranslations } from '../../../hooks/useTranslationsHelper';
import { CookieIcon } from '../../shared/SVG/Icons/CookieIcon';
import { NotificationBar } from '../../shared/NotificationBar';
import { MediaBreakpoint, useBreakpointInfo } from '../../../hooks/useDeviceInfo';
import { useCookies } from '../../../hooks/cookies';
import { APP_URLS } from '../../../consts';
import { isCookieFalsy } from '../../../utils/cookies';

const CookieBannerContent = ({ onUpdate }: { onUpdate: () => void }): ReactElement => (
  <p className="cookie-content">
    <Trans keyProp="cookie-banner-full-text">
      Cookies are used to personalise content, track website usage and make our games function. You can see our full{' '}
      <Link href={origin + APP_URLS.cookiePolicy}>Cookie Policy</Link> or change your{' '}
      <Link onClick={onUpdate}>Cookie Preferences</Link>
    </Trans>
  </p>
);

export const CookieBanner: FC<{
  showCookiePreferencesOuter?: boolean;
  setShowCookiePreferencesOuter?: (arg: boolean) => void;
}> = ({ showCookiePreferencesOuter = false, setShowCookiePreferencesOuter }) => {
  const { acceptAllConsent } = useCookies();

  const isBelowMobileWidth = useBreakpointInfo([MediaBreakpoint.BELOW_MOBILE]);

  const [showCookiesPreferences, setShowCookiesPreferences] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const { t } = useTranslations();

  const onManageCookiesClick = (): void => {
    setShowCookiesPreferences(true);
  };

  const onClickAcceptAll = (): void => {
    acceptAllConsent();
    setShowCookieBanner(false);
  };

  useEffect(() => {
    isCookieFalsy() && setShowCookieBanner(true);
  }, []);

  useEffect(() => setShowCookiesPreferences(showCookiePreferencesOuter), [showCookiePreferencesOuter]);

  useEffect(() => setShowCookiePreferencesOuter?.(showCookiesPreferences), [showCookiesPreferences]);

  return (
    <>
      {showCookieBanner && (
        <NotificationBar
          isShow
          disableSiteContent
          imgNode={<CookieIcon />}
          message={CookieBannerContent({ onUpdate: onManageCookiesClick })}
          buttonText={t('cookie-banner-button-text')}
          onClick={onClickAcceptAll}
          SecondaryButton={{
            type: 'button',
            buttonVariant: 'text',
            onClick: onManageCookiesClick,
            size: isBelowMobileWidth ? 'medium' : 'large',
            text: t('cookie-banner-secondary-btn-text'),
          }}
        />
      )}
      {showCookiesPreferences && (
        <CookiesPreferences
          onClose={(): void => {
            setShowCookiesPreferences(false);
          }}
          setShowCookieBanner={setShowCookieBanner}
        />
      )}
    </>
  );
};
