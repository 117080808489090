import { useCallback } from 'react';
import { useAuth } from 'react-oidc-context';
import { useIdle } from '../useIdle';
import useDestroyUserSession from './useDestroyUserSession';

export const useAuthController = (): void => {
  const auth = useAuth();
  const { logoutUser } = useDestroyUserSession();

  const handleUserReturn = useCallback(async () => {
    console.log('[AuthController]', 'User has returned - checking auth status');

    try {
      // First check if the token is expired
      if (auth.user?.expired) {
        console.log('[AuthController]', 'Token is expired, attempting silent refresh');
        await auth.signinSilent();
      }

      // Start the regular silent renew process
      console.log('[AuthController]', 'Starting silent renew as user has returned');
      auth.startSilentRenew();
    } catch (error) {
      console.error('[AuthController]', 'Failed to refresh auth token:', error);
      logoutUser();
    }
  }, [auth, logoutUser]);

  useIdle(
    30000,
    useCallback(
      (isIdle: boolean) => {
        if (!isIdle) {
          handleUserReturn();

          return;
        }

        console.log('[AuthController]', 'Stopping silent renew as user is idle');
        auth.stopSilentRenew();
      },
      [handleUserReturn, auth]
    )
  );
};
