import React, { FunctionComponent } from 'react';
import { PopUpHandler } from '../../shared/PopupHandler';
import { Paragraph } from '../../shared/Paragraph';
import { FormControlSpacing } from '../../shared/FormControlSpacing';
import { Button } from '../../shared/Button';
import { Icon } from '../../shared/Icon';
import { useTranslations } from '../../../hooks/useTranslationsHelper';
import { getLimitProperties } from '../helper';
import { capitalizeFirstLetter } from '../../../utils/form';
import { BudgetLimitsTransactionType } from '../../../types.__generated__';
import { MediatorType } from '../types';

type AccountLimitsHowItWorksProps = {
  onClose: () => void;
  isOpen: boolean;
  backButton?: true;
  mediatorType?: MediatorType;
  onClickBack?: () => void;
};

const AccountLimitsHowItWorks: FunctionComponent<AccountLimitsHowItWorksProps> = ({
  onClose,
  isOpen,
  mediatorType,
  backButton,
  onClickBack,
}) => {
  const { t } = useTranslations();
  const type = getLimitProperties(mediatorType || MediatorType.Monetary, t).type;
  const name = (
    type === BudgetLimitsTransactionType.Deposit
      ? BudgetLimitsTransactionType.Deposit
      : BudgetLimitsTransactionType.Loss
  ).toLowerCase();
  const title = capitalizeFirstLetter(name);

  return (
    <PopUpHandler
      isOpen={isOpen}
      onClose={onClose}
      drawerProps={{ backdropCanClose: true }}
      headingProps={{ headingLevel: 5 }}
      modalProps={{
        'aria-label': `${name} limits`,
      }}
      header={t('limits.how-it-works.title', { title })}
    >
      <Paragraph>{t(`limits.allow-message-${name}.pt1`, { title })}</Paragraph>
      <Paragraph>{t(`limits.allow-message-${name}.pt2`, { title })}</Paragraph>
      {backButton && (
        <>
          <FormControlSpacing />
          <Button
            iconPosition="left"
            icon={<Icon variant="ArrowLeft" />}
            type="button"
            size="large"
            variant="text"
            onClick={onClickBack}
          >
            {t('buttons.back')}
          </Button>
          <FormControlSpacing />
        </>
      )}
    </PopUpHandler>
  );
};

export default AccountLimitsHowItWorks;
