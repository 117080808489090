import axios from 'axios';
import { xTenantId } from '../../consts';
import { getToken } from '../../utils/auth';

const requestUrl = process.env.REACT_APP_BASE_AUTH_URL;

export const retrieveLoginToken = async (userProfileId: string): Promise<{ sessionContextId: string }> => {
  const token = getToken();
  const config = {
    headers: {
      authorization: token ? `Bearer ${token}` : null,
      'X-Tenant-Id': xTenantId,
    },
  };
  const payload = {
    userProfileId: userProfileId,
    currencyCode: 'GBP',
    languageCode: 'EN',
    IsDemoPlay: false,
    deviceCode: 'desktop',
  };

  const result = await axios.post(
    `${requestUrl}/endeavour-metric/v1/user-profiles/${userProfileId}/launch`,
    payload,
    config
  );

  return result?.data;
};
