import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SessionContextUpdatedSubscriptionVariables = Types.Exact<{
  userProfileId: Types.Scalars['String']['input'];
}>;


export type SessionContextUpdatedSubscription = { __typename?: 'Subscription', sessionContextUpdated?: { __typename?: 'SessionContextUpdate', mediatorId?: string | null, mediatorType?: string | null, type?: string | null, id?: string | null, current?: { __typename?: 'Endeavour_DOT_SessionContext_DOT_Messaging_DOT_Contracts_DOT_SessionContexts_DOT_Events_DOT_SessionContextUpdatedSessionContext', status?: string | null, additionalProperties?: string | null } | null } | null };


export const SessionContextUpdatedDocument = gql`
    subscription SessionContextUpdated($userProfileId: String!) {
  sessionContextUpdated(userProfileId: $userProfileId) {
    current {
      status
      additionalProperties
    }
    mediatorId
    mediatorType
    type
    id
  }
}
    `;

/**
 * __useSessionContextUpdatedSubscription__
 *
 * To run a query within a React component, call `useSessionContextUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionContextUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionContextUpdatedSubscription({
 *   variables: {
 *      userProfileId: // value for 'userProfileId'
 *   },
 * });
 */
export function useSessionContextUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SessionContextUpdatedSubscription, SessionContextUpdatedSubscriptionVariables> & ({ variables: SessionContextUpdatedSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SessionContextUpdatedSubscription, SessionContextUpdatedSubscriptionVariables>(SessionContextUpdatedDocument, options);
      }
export type SessionContextUpdatedSubscriptionHookResult = ReturnType<typeof useSessionContextUpdatedSubscription>;
export type SessionContextUpdatedSubscriptionResult = Apollo.SubscriptionResult<SessionContextUpdatedSubscription>;