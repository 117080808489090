import React, { FunctionComponent } from 'react';
import { formatDate, parseISO } from 'date-fns';
import { KambiCouponDescription, KambiFetchCouponRowStatus } from '../../../../components/Kambi/types';
import './styles';
import { CouponDescriptionWithOdds } from '../../../Account/MyAccountActivity/CouponDescriptionWithOdds';

export type TabularDataDetailsItemProps = {
  stake?: number;
  title?: KambiCouponDescription;
  marker?: number;
  payout?: number;
  payoutBoosted?: number;
  status?: KambiFetchCouponRowStatus;
  subtitle?: string;
  content?: string | string[];
  results?: string;
  dateTime?: string;
  isSingleBet?: boolean;
  eventStartDate?: string | undefined;
};

const STATUS_COLORS = {
  [KambiFetchCouponRowStatus.WON]: 'tddi__marker--won',
  [KambiFetchCouponRowStatus.PLACED]: 'tddi__marker--won',
  [KambiFetchCouponRowStatus.LOST]: 'tddi__marker--lost',
  [KambiFetchCouponRowStatus.OPEN]: 'tddi__marker--open',
  [KambiFetchCouponRowStatus.PARTIAL_VOID]: 'tddi__marker--part-void',
  [KambiFetchCouponRowStatus.VOID]: 'tddi__marker--void',
};

export const TabularDataDetailsItem: FunctionComponent<TabularDataDetailsItemProps> = ({
  stake,
  title,
  subtitle,
  content,
  results,
  payout,
  payoutBoosted,
  status,
  marker,
  isSingleBet,
  eventStartDate,
}) => {
  const markerClass = `tddi__marker ${status ? STATUS_COLORS[status] : 'tddi__marker--default'}`;

  return (
    <div className="tddi-container">
      <div className="tddi">
        <span className="tddi__number">{marker}</span>

        <span className={markerClass} />

        <div className="tddi__info">
          <span className="tddi__title">
            <CouponDescriptionWithOdds description={title} />
          </span>
          {Array.isArray(content) ? (
            content.map((text, index) => (
              <span className="tddi__content" key={index}>
                {text}
              </span>
            ))
          ) : (
            <span className="tddi__content">{content}</span>
          )}
          {!!results && <span className="tddi__results">{`Results: ${results}`}</span>}
          {!!eventStartDate && (
            <span className="tddi__date">{formatDate(parseISO(eventStartDate), 'dd/MM/yyyy | HH:mm')}</span>
          )}
          <span className="tddi__sublabel">{subtitle}</span>
        </div>
      </div>

      {isSingleBet && (
        <div className="tddi__coupon">
          {!!stake && <span className="tddi__coupon-details">Stake: £${stake}</span>}
          {!!payout && <span className="tddi__coupon-details">`Payout: £${payoutBoosted || payout}`</span>}
        </div>
      )}
    </div>
  );
};
