import noop from 'lodash/noop';
import React, { FunctionComponent, useCallback } from 'react';
import { animated, useSpring } from 'react-spring';
import { APP_URLS } from '../../../../consts';
import { Button } from '../../../shared/Button';
import { Checkbox } from '../../../shared/Checkbox';
import { Heading } from '../../../shared/Heading';
import { Icon } from '../../../shared/Icon';
import { useCookiePreferencesContext } from '../CookiePreferences.context';
import './styles';
import { ConsentType } from '../types';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

type CookieSectionProps = {
  title: string;
  content: string;
  checked?: boolean;
  category?: string;
  disabled?: boolean;
  onClick?: () => void;
  alwaysActive?: boolean;
  setActiveCategory: (value: string) => void;
  setIsDetailPageDisplayed: (value: boolean) => void;
};

const CookieSection: FunctionComponent<CookieSectionProps> = ({
  title,
  content,
  disabled,
  checked,
  alwaysActive,
  onClick = noop,
  category = '',
  setIsDetailPageDisplayed,
  setActiveCategory,
}) => {
  const onCheckDetailsClick = useCallback(() => {
    setActiveCategory(category);
    setIsDetailPageDisplayed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <div className="cookie-preference-control__section">
      <div className="cookie-preference-control__split">
        <span className="cookie-preference-control__title">{title}</span>
        {alwaysActive ? (
          <span className="cookie-preference-control__title">Always Active</span>
        ) : (
          <Checkbox
            disabled={disabled}
            checked={checked}
            onClick={(): void => {
              !disabled && onClick();
            }}
            variant="switch"
          />
        )}
      </div>
      <div className="cookie-preference-control__section-content">
        {content}{' '}
        {category && (
          <Button type="button" variant="text" onClick={onCheckDetailsClick}>
            Check details
          </Button>
        )}
      </div>
    </div>
  );
};

export const CookieControl: FunctionComponent = () => {
  const { t } = useTranslations();
  const {
    isFunctional,
    isConfigReady,
    isPerformance,
    isSubmitting,
    isAccepting,
    isRejecting,
    onSave,
    setIsAccepting,
    setIsFunctional,
    setIsPerformance,
    setActiveCategory,
    setIsDetailPageDisplayed,
  } = useCookiePreferencesContext();

  const animationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  const onAcceptAllBtnClick = async (): Promise<void> => {
    setIsAccepting(true);
    setIsFunctional(true);
    setIsPerformance(true);

    await onSave({
      isBasic: true,
      isFunctional: true,
      isPerformance: true,
    });

    setIsAccepting(false);
  };

  return (
    <animated.div style={animationProps}>
      <div className="cookie-preference-control__description">{t('cookie-preferences-description')}</div>

      <div className="cookie-preference-control__links">
        <a className="cookie-preference-control__link" href={origin + APP_URLS.cookiePolicy} target="blank">
          <Icon variant="Cookie" />
          <div className="cookie-preference-control__link-name">{t('cookie-preferences-cookie-policy')}</div>
        </a>
        <a className="cookie-preference-control__link" href={origin + APP_URLS.privacyPolicy} target="blank">
          <Icon variant="Shield" />
          <div className="cookie-preference-control__link-name">{t('cookie-preferences-privacy-policy')}</div>
        </a>
      </div>

      <div className="cookie-preference-control__explanation">
        <Heading level={5}>{t('cookie-preferences-title')}</Heading>
        <span>{t('cookie-preferences-control-description')}</span>
      </div>

      <Button
        size="large"
        className="cookie-preference-control__head-button cookie-preference-control__case"
        type="button"
        variant="primary"
        disabled={(isFunctional && isPerformance) || isSubmitting || isRejecting}
        onClick={onAcceptAllBtnClick}
        isSubmitting={isAccepting}
      >
        {t('cookie-preferences-accept-btn')}
      </Button>

      {isConfigReady && (
        <div className="cookie-preference-control__categories">
          <Heading className="cookie-preference-control__categories-header" level={5}>
            {t('cookie-preferences-manage-link')}
          </Heading>
          <CookieSection
            title={t('cookie-preferences-basic')}
            content={t('cookie-preferences-basic-content')}
            alwaysActive
            setIsDetailPageDisplayed={setIsDetailPageDisplayed}
            category={ConsentType.BASIC}
            setActiveCategory={setActiveCategory}
          />

          <CookieSection
            title={t('cookie-preferences-functional')}
            content={t('cookie-preferences-functional-content')}
            checked={isFunctional}
            category={ConsentType.FUNCTIONAL}
            setIsDetailPageDisplayed={setIsDetailPageDisplayed}
            onClick={(): void => {
              setIsFunctional(!isFunctional);
            }}
            setActiveCategory={setActiveCategory}
          />

          <CookieSection
            title={t('cookie-preferences-performance')}
            content={t('cookie-preferences-performance-content')}
            checked={isPerformance}
            category={ConsentType.PERFORMANCE}
            setIsDetailPageDisplayed={setIsDetailPageDisplayed}
            onClick={(): void => {
              setIsPerformance(!isPerformance);
            }}
            setActiveCategory={setActiveCategory}
          />
        </div>
      )}
    </animated.div>
  );
};
