import React, { Children, FunctionComponent } from 'react';
import { useSpring, animated } from 'react-spring';
import { CookiesDetailsItem } from './CookieDetailsItem';
import { ConsentVendorCookie, ConsentStore } from '../types';
import './CookieDetails.scss';

export type CookiesDetailsProps = {
  /**
   * List of cookies
   */
  data?: ConsentStore;
  /**
   * Active category name
   */
  activeCategory: string;
};

/**
 * Cookie Details component to render list of cookies
 */
export const CookiesDetails: FunctionComponent<CookiesDetailsProps> = ({ data, activeCategory }) => {
  const animationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  return (
    <animated.div style={animationProps}>
      <div className="cookie-details">
        {Children.toArray(
          (data as Record<string, ConsentVendorCookie[]>)[activeCategory]?.map((elem: ConsentVendorCookie) => (
            <CookiesDetailsItem
              details={{
                name: elem.name,
                domain: elem.domain,
                duration: elem.duration,
                description: elem.description,
              }}
            />
          ))
        )}
      </div>
    </animated.div>
  );
};
