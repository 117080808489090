import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../shared/Spinner';
import { CookiesPreferenceContent } from './CookiesPreferencesContent';
import { CookiesPreferencesPortal } from './CookiesPreferencesPortal';
import { CookiesPreferenceContentPayload, CookiesPreferenceProps } from './types';
import { useCookies } from '../../../hooks/cookies';
import { CookiesPreferencesFooter } from './CookiesPreferencesFooter';
import { CookiePreferencesContext } from './CookiePreferences.context';

export const CookiesPreferences: FunctionComponent<CookiesPreferenceProps> = ({
  onError,
  onClose,
  mode,
  setShowCookieBanner,
}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const isUserAuthenticated = auth?.isAuthenticated;
  const { fetchCookies, writeCookies, updateConsentStorage, preferences } = useCookies();

  const [fetching, setFetching] = useState(true);
  const [showCookies, setShowCookies] = useState<boolean>(true);
  const [cookieDetailedPageTitle, setCookieDetailedPageTitle] = useState<string | undefined>(undefined);
  const [isDetailPageDisplayed, setIsDetailPageDisplayed] = useState<boolean>(false);
  const [ready, setReady] = useState(false);
  const [isFunctional, setIsFunctional] = useState(false);
  const [isPerformance, setIsPerformance] = useState(false);
  const [activeCategory, setActiveCategory] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);

  useEffect(() => {
    setIsFunctional(preferences?.isFunctional || false);
    setIsPerformance(preferences?.isPerformance || false);
    setReady(true);
  }, [preferences]);

  const onSave = async (payload: CookiesPreferenceContentPayload, showBanner?: boolean): Promise<void> => {
    if (isUserAuthenticated) {
      try {
        await writeCookies(payload);
        onCookiesSave();
      } catch (_error) {
        onCookiesSave();
      }
    } else {
      updateConsentStorage(payload);
      onCookiesSave();
    }

    setShowCookieBanner?.(Boolean(showBanner));
  };

  const onReject = useCallback(async () => {
    setIsRejecting(true);
    setIsFunctional(false);
    setIsPerformance(false);

    await onSave(
      {
        isBasic: false,
        isFunctional: false,
        isPerformance: false,
      },
      true
    );
    setIsRejecting(false);
  }, []);

  const onConfirm = useCallback(async () => {
    setIsSubmitting(true);

    await onSave({
      isBasic: true,
      isFunctional,
      isPerformance,
    });

    setIsSubmitting(false);
  }, [onSave, isFunctional, isPerformance]);

  const getCookies = useCallback(async (): Promise<void> => {
    setFetching(true);

    try {
      await fetchCookies();
    } catch (_error) {
      onError && onError();
    } finally {
      setFetching(false);
    }
  }, [fetchCookies, onError]);

  useEffect(() => {
    isUserAuthenticated ? getCookies() : setFetching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated]);

  const onCookiesSave = (): void => {
    onClose ? onClose() : setShowCookies(false);
  };

  const onCloseCookiesPreference = (): void => {
    onClose && onClose();
  };

  const onBackBtnClick = (): void => {
    isDetailPageDisplayed ? setIsDetailPageDisplayed(false) : navigate(-1);
  };

  const contextValue = {
    isConfigReady: ready,
    isAccepting,
    isRejecting,
    isFunctional,
    isSubmitting,
    isPerformance,
    activeCategory,
    isDetailPageDisplayed,
    onSave,
    onReject,
    onConfirm,
    setIsRejecting,
    setIsAccepting,
    setIsFunctional,
    setIsSubmitting,
    setIsPerformance,
    setActiveCategory,
    setIsDetailPageDisplayed,
  };

  return fetching ? (
    <Spinner />
  ) : (
    <CookiePreferencesContext.Provider value={contextValue}>
      <CookiesPreferencesPortal
        mode={mode}
        onBackBtnClick={onBackBtnClick}
        onClose={onCloseCookiesPreference}
        isDetailPageDisplayed={isDetailPageDisplayed}
        cookieDetailedPageTitle={cookieDetailedPageTitle}
        footer={<CookiesPreferencesFooter isOpened={showCookies} mode={mode} />}
      >
        <CookiesPreferenceContent
          setCookieDetailedPageTitle={setCookieDetailedPageTitle}
          isOpened={showCookies}
          mode={mode}
        />
      </CookiesPreferencesPortal>
    </CookiePreferencesContext.Provider>
  );
};
