import React, { FC } from 'react';
import { AccountLimitsItemControls } from './AccountLimitsItemControls';
import { AccountLimitsItemStatus } from './AccountLimitsItemStatus/AccountLimitsItemStatus';
import { BudgetLimitsTransactionResponse } from '../../../types.__generated__';

interface IAccountLimitsItemContent {
  label: string;
  isInitialLimit: boolean;
  data?: BudgetLimitsTransactionResponse;
  onEdit: () => void;
  onRemove: () => void;
}

export const AccountLimitsItemContent: FC<IAccountLimitsItemContent> = ({
  data,
  label,
  isInitialLimit,
  onEdit,
  onRemove,
}): JSX.Element => {
  return (
    <div className="account-limits-item-content">
      <AccountLimitsItemStatus activeItem={data} label={label} />
      <AccountLimitsItemControls
        isRemoveAllowed={false}
        isInitialLimit={isInitialLimit}
        onEdit={onEdit}
        onRemove={onRemove}
      />
    </div>
  );
};
