import React, { FC } from 'react';
import { AccountTile } from '../../Account/MyAccount/AccountTile';
import { AccountTileListItem } from '../../Account/MyAccount/AccountTileListItem';
import { Button } from '../../shared/Button';
import { Heading } from '../../shared/Heading';
import { Icon } from '../../shared/Icon';
import { Paragraph } from '../../shared/Paragraph';
import { Spinner } from '../../shared/Spinner';
import { getLimitProperties, getLimitNameByPeriod } from '../helper';
import { AccountLimitsItem } from './AccountLimitsItem/AccountLimitsItem';
import { IGroupedLimitsTypes, LimitPeriodsEnum, ModalAction } from './types';
import { useTranslations } from '../../../hooks/useTranslationsHelper';
import { MediatorType } from '../types';

interface AccountLimitsSectionProps {
  data?: IGroupedLimitsTypes;
  mediatorType: MediatorType;
  isDataInitialised?: boolean;
  refetchLimits: () => void;
  onItemClick: (period?: LimitPeriodsEnum, mediatorType?: MediatorType, action?: ModalAction) => void;
  openLimitsHistoryModal?: (mediatorType: MediatorType) => void;
  openHowItWorksModal?: (mediatorType: MediatorType) => void;
}

export const AccountLimitsSection: FC<AccountLimitsSectionProps> = ({
  data = {},
  mediatorType,
  isDataInitialised,
  onItemClick,
  refetchLimits,
  openHowItWorksModal,
  openLimitsHistoryModal,
}) => {
  const { t } = useTranslations();
  const limitTitle = getLimitProperties(mediatorType, t).title;
  const limitsDataKeys = [LimitPeriodsEnum.daily, LimitPeriodsEnum.weekly, LimitPeriodsEnum.monthly];

  return (
    <div className="account-limits">
      <div className="account-limits__heading-container">
        <Heading level={6}>
          {t('limits.limits-title', {
            limitTitle,
          })}
        </Heading>
        {openLimitsHistoryModal && (
          <Button
            type="button"
            variant="text"
            icon={<Icon variant="File" />}
            iconPosition="left"
            onClick={(): void => openLimitsHistoryModal(mediatorType)}
          >
            {t('limits.history')}
          </Button>
        )}
      </div>
      <Paragraph size="xs" noMargin highlight>
        {limitTitle === 'Deposit'
          ? t('limits.set-deposit-limits-message', { limitTitle: limitTitle.toLowerCase() })
          : t('limits.set-loss-limits-message')}
        <Button type="button" variant="text" onClick={(): void => openHowItWorksModal?.(mediatorType)}>
          {t('my-account.limits.see-how-works', { limitTitle })}
        </Button>
      </Paragraph>
      {isDataInitialised ? (
        Object.values(data)?.length ? (
          limitsDataKeys?.map((key) => (
            <AccountLimitsItem
              key={key}
              label={t('limits.period-message', {
                period: getLimitNameByPeriod(key, true),
                limitTitle,
              })}
              data={data[key]}
              onClick={onItemClick}
              refetchLimits={refetchLimits}
              mediatorType={mediatorType}
            />
          ))
        ) : (
          <AccountTile noPadding>
            <AccountTileListItem
              text={t('limit.set-limit-message', { limitTitle })}
              icon="Plus"
              iconAlign="right"
              onClick={(): void => onItemClick(LimitPeriodsEnum.daily, mediatorType, ModalAction.Edit)}
            />
          </AccountTile>
        )
      ) : (
        <div className="account-limits__spinner-wrapper">
          <Spinner theme="light" />
        </div>
      )}
    </div>
  );
};
