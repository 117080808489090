import React, { useState } from 'react';
import { APP_URLS, locale } from '../../../consts';
import { Endpoint } from '../../../models/api.model';
import { useSnackbar } from '../../../hooks/snackbar';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading } from '../../../components/shared/Heading';
import { NavigationTemplate } from '../../NavigationTemplate';
import { Spinner } from '../../../components/shared/Spinner';
import { ITransformedContentPageData, transformContentPageData } from '../../../utils/content';
import { useContentPageGetBySlugQuery } from '../../../libs/graphql/betzoneDirectusAPI/queries/__generated__/content-page-get-by-slug.query.generated';
import { joinStrings } from '../../../utils/string';
import { isCookieFalsy } from '../../../utils/cookies';
import './ContentPage.scss';

export const ContentPage: React.FC = () => {
  const navigate = useNavigate();
  const { slug = '' } = useParams();
  const { addSnack } = useSnackbar();

  const [transformedContent, setTransformedContent] = useState<ITransformedContentPageData>({ title: '', content: '' });

  const isPolicyPage = transformedContent.title.toLowerCase().includes('policy');

  const handleError = (): void => {
    addSnack({
      type: 'error',
      theme: 'dark',
      message: `Oops, something's gone wrong`,
    });
    navigate(APP_URLS.home);

    return;
  };
  const { loading } = useContentPageGetBySlugQuery({
    variables: {
      slug,
      language: locale,
    },
    onCompleted: (data) => {
      if (!data.documents.length) {
        return handleError();
      }

      const transformedContentData = transformContentPageData(data);

      setTransformedContent(transformedContentData);
    },
    onError: (error) => {
      console.error(error);

      return handleError();
    },
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  return (
    <>
      <NavigationTemplate>
        {loading ? (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        ) : (
          <div className={joinStrings(['content-page', isPolicyPage && isCookieFalsy() && 'content-page--overlay'])}>
            {/* TODO: add alternative text from translations instead of empty string if we get a null value (title or content) */}
            <Heading level={1}>{transformedContent.title}</Heading>
            <div dangerouslySetInnerHTML={{ __html: transformedContent.content }} />
          </div>
        )}
      </NavigationTemplate>
    </>
  );
};
