import axios from 'axios';
import { getToken } from '../../../../utils/auth';
import { xTenantId } from '../../../../consts';

const requestUrl = process.env.REACT_APP_BASE_AUTH_URL;

export const fetchUploaderIframeURL = async (userProfileId: string): Promise<string> => {
  const token = getToken();
  const response = await axios.post(
    `${requestUrl}/endeavour-age-checked/v1/user-profiles/${userProfileId}/launch`,
    { actionId: 'AC0130' },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Tenant-ID': xTenantId,
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data.url;
};
