import DOMPurify from 'dompurify';
import React, { FC } from 'react';
import { images } from './mocks';
import './styles';
import { Paragraph } from '../../shared/Paragraph';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

export type RegistrationFooterProps = {
  textContent?: string;
};

/**
 * Component will display text section at the left (if provided) and the image section at the right (if provided).
 */
export const RegistrationFooter: FC<RegistrationFooterProps> = () => {
  const { t } = useTranslations();
  const onTextClick = (): void => {
    console.log('onTextClick');
  };

  const textContent = `
  <p>${t('responsible.commited-text')} 
    <a href="pages/safer-gambling" target="_blank" rel="noopener noreferrer">Responsible Gambling</a>
  </p>\r\n<p>Copyright © Richmond Atlantic Ltd</p>\r\n
`;

  return (
    <div className="registration-footer">
      <div className="registration-footer__content">
        {textContent && (
          <div
            className="registration-footer__text-section"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(textContent, { ADD_ATTR: ['target'] }),
            }}
            onClick={onTextClick}
            onKeyPress={onTextClick}
            role="presentation"
          />
        )}

        {images?.length > 0 ? (
          <div className="registration-footer__image-section">
            {images &&
              images.map(({ imageLink, imageLabel, imagePath }) => {
                const image = (
                  <img
                    className="registration-footer__image"
                    key={imageLink ? undefined : imagePath}
                    src={imagePath}
                    alt={imageLabel}
                  />
                );

                return imageLink ? (
                  <a href={imageLink} key={imageLink} target="_blank" rel="noreferrer noopener">
                    {image}
                  </a>
                ) : (
                  image
                );
              })}
          </div>
        ) : null}
      </div>
      <Paragraph size="xs" className="registration-footer__sub-text" noMargin>
        {t('register.footer.text')}
      </Paragraph>
    </div>
  );
};
