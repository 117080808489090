import React, { FC, useEffect } from 'react';
import noop from 'lodash/noop';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { Paragraph } from '../../../shared/Paragraph';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

export type HowItWorksModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onShow?: () => void;
};

export const HowRealityChecksWorkModal: FC<HowItWorksModalProps> = ({ isOpen, onClose, onShow = noop }) => {
  const { t } = useTranslations();

  useEffect(() => {
    if (isOpen) {
      onShow();
    }
  }, [isOpen]);

  return (
    <PopUpHandler
      isOpen={isOpen}
      onClose={onClose}
      header={t('reality-check.title')}
      drawerProps={{ backdropCanClose: true }}
      headingProps={{ headingLevel: 5 }}
      modalProps={{
        'aria-label': 'How it works',
      }}
    >
      <Paragraph size="sm">{t('reality-check.how-it-works.1')}</Paragraph>
      <Paragraph size="sm">{t('reality-check.how-it-works.2')}</Paragraph>
    </PopUpHandler>
  );
};
