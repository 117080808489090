export type CookiesPreferencesMode = { mode?: 'prompt' | 'account' };

export type CookiesPreferenceProps = {
  /**
   * Callback that's executed when an error has occured.
   */
  onError?: () => void;
  /**
   * Callback that's executed onClose.
   */
  onClose?: () => void;
  /**
   * Handle cookie banner if preferences prompt is opened from the banner
   */
  setShowCookieBanner?: (arg: boolean) => void;
} & CookiesPreferencesMode;

export type ConsentSubmission = {
  consent: ConsentStorage;
  playerId?: string;
};

export enum ConsentEvent {
  CONSENT_UPDATED = 'consent_updated',
}

export type ConsentVendorId = number;

export type ConsentSettings = {
  settings: ConsentStore;
  playerId?: string;
};

export type ConsentStorage = {
  basicCookiesAllowed: boolean;
  functionalCookiesAllowed: boolean;
  performanceCookiesAllowed: boolean;
};

export type ConsentVendor = {
  id: ConsentVendorId;
  name: string;
  description: string;
  cookies: ConsentVendorCookie[];
};

export type ConsentStore = {
  basic: ConsentVendorCookie[];
  functional: ConsentVendorCookie[];
  performance: ConsentVendorCookie[];
};

export type ConsentVendorCookie = {
  name: string;
  domain: string;
  duration: string;
  description: string;
};

export enum ConsentType {
  BASIC = 'basic',
  FUNCTIONAL = 'functional',
  PERFORMANCE = 'performance',
}

export type CookiesPreferenceContentPayload = {
  isBasic?: boolean;
  isFunctional?: boolean;
  isPerformance?: boolean;
};
