import { FunctionComponent, useEffect } from 'react';
import { CookieControl } from '../CookieControl';
import { CookiesDetails } from '../CookieDetails';
import { CookiesPreferencesMode } from '../types';
import { useCookiePreferencesContext } from '../CookiePreferences.context';
import { cookiesStore } from '../../../../hooks/cookies/mocks';
import './styles';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

export type CookiesPreferenceContentProps = {
  /**
   * Whether is component opened
   */
  isOpened?: boolean;
  /**
   * Account page wrapper title setter
   */
  setCookieDetailedPageTitle: (arg?: string) => void;
} & CookiesPreferencesMode;

export const CookiesPreferenceContent: FunctionComponent<CookiesPreferenceContentProps> = ({
  isOpened = true,
  setCookieDetailedPageTitle,
}) => {
  const { t } = useTranslations();
  const { isDetailPageDisplayed, activeCategory } = useCookiePreferencesContext();

  useEffect(() => {
    setCookieDetailedPageTitle(
      t(activeCategory ? `cookie-preferences-list-${activeCategory}` : 'cookie-preferences-main-title')
    );
  }, [activeCategory]);

  return isOpened ? (
    <>
      {/* TODO: Replace mocked date with dynamic */}
      {isDetailPageDisplayed ? (
        <CookiesDetails data={cookiesStore} activeCategory={activeCategory} />
      ) : (
        <CookieControl />
      )}
    </>
  ) : null;
};
