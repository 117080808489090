import React, { FunctionComponent, useState } from 'react';
import domPurify from 'dompurify';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import { joinStrings } from '../../utils/string';
import { Heading } from '../shared/Heading';
import { Button } from '../shared/Button';
import { PopUpHandler } from '../shared/PopupHandler';
import { Paragraph } from '../shared/Paragraph';
import './styles';

type WelcomeBonusBannerProps = {
  termsContent: string;
  title?: string;
  subTitle?: string;
};

export const WelcomeBonusBanner: FunctionComponent<WelcomeBonusBannerProps> = ({ termsContent, title, subTitle }) => {
  const { isMobileDevice } = useDeviceInfo();
  const sanitizer = domPurify.sanitize;

  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

  return (
    <>
      <div className={joinStrings(['welcome-bonus-banner', isMobileDevice && 'welcome-bonus-banner--mobile'])}>
        <div
          className={joinStrings([
            'welcome-bonus-banner__title-wrapper',
            isMobileDevice && 'welcome-bonus-banner__title-wrapper--mobile',
          ])}
        >
          <Heading level={3} styleAs={3} className="welcome-bonus-banner__title">
            {title || ''}
          </Heading>
          <Heading level={2} styleAs={2} className="welcome-bonus-banner__subtitle">
            {subTitle || ''}
          </Heading>
        </div>
        {termsContent && (
          <Button
            type="button"
            variant="text"
            size="small"
            onClick={(): void => {
              setIsPopupVisible(true);
            }}
          >
            {isMobileDevice ? 'terms-mobile' : 'terms-desktop'}
          </Button>
        )}
      </div>
      {isPopupVisible && (
        <PopUpHandler
          isOpen
          onClose={(): void => {
            setIsPopupVisible(false);
          }}
          header="welcome-bonus-banner-terms-desktop"
          modalProps={{
            'aria-label': 'privacy policy heading',
          }}
        >
          <Paragraph dangerouslySetInnerHTML={{ __html: sanitizer(termsContent) }} />
          <Button
            type="button"
            size="x-large"
            className="terms-back-btn"
            onClick={(): void => {
              setIsPopupVisible(false);
            }}
          >
            Claim
          </Button>
        </PopUpHandler>
      )}
    </>
  );
};
