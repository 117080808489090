export const defaultPromotions = 'Default Promotions';
export const activePromotions = 'Active Promotions';
export const promotions = 'Promotions';
export const welcomeBonusType = 'FirstDeposit';
export const bonusItem = 'bonusItem';
export const bonusActiveItem = 'bonusActiveItem';
export const typeFixed = 'fixed';
export const typeDefault = 'default';
export const typeCancel = 'cancellation';
export const typeFirstDeposit = 'firstDeposit';
export const cashbackOnTotalStakeType = 'CashbackOnTotalStake';

export const today = new Date().toISOString();
