import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserWalletsQueryVariables = Types.Exact<{
  userProfileId: Types.Scalars['ID']['input'];
  xTenantId: Types.Scalars['ID']['input'];
}>;


export type UserWalletsQuery = { __typename?: 'Query', userWallets: Array<{ __typename?: 'UserWallets', balanceAmount?: number | null, currencyCode?: string | null, type?: string | null, walletId?: string | null, isActive?: boolean | null } | null> };


export const UserWalletsDocument = gql`
    query UserWallets($userProfileId: ID!, $xTenantId: ID!) {
  userWallets(userProfileId: $userProfileId, xTenantID: $xTenantId) {
    balanceAmount
    currencyCode
    type
    walletId
    isActive
  }
}
    `;

/**
 * __useUserWalletsQuery__
 *
 * To run a query within a React component, call `useUserWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWalletsQuery({
 *   variables: {
 *      userProfileId: // value for 'userProfileId'
 *      xTenantId: // value for 'xTenantId'
 *   },
 * });
 */
export function useUserWalletsQuery(baseOptions: Apollo.QueryHookOptions<UserWalletsQuery, UserWalletsQueryVariables> & ({ variables: UserWalletsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWalletsQuery, UserWalletsQueryVariables>(UserWalletsDocument, options);
      }
export function useUserWalletsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWalletsQuery, UserWalletsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWalletsQuery, UserWalletsQueryVariables>(UserWalletsDocument, options);
        }
export function useUserWalletsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserWalletsQuery, UserWalletsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserWalletsQuery, UserWalletsQueryVariables>(UserWalletsDocument, options);
        }
export type UserWalletsQueryHookResult = ReturnType<typeof useUserWalletsQuery>;
export type UserWalletsLazyQueryHookResult = ReturnType<typeof useUserWalletsLazyQuery>;
export type UserWalletsSuspenseQueryHookResult = ReturnType<typeof useUserWalletsSuspenseQuery>;
export type UserWalletsQueryResult = Apollo.QueryResult<UserWalletsQuery, UserWalletsQueryVariables>;