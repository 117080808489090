export const cookiesStore = {
  basic: [
    {
      name: 'receive-cookie-deprecation',
      domain: 'adnxs.com',
      duration: '1 year 1 month',
      description:
        'This cookie is used to signal to the website owner about the deprecation of cookies being received by the system, ensuring compliance and adaptability with evolving web standards and privacy legislation.',
    },
    {
      name: 'checkForPermission',
      domain: 'bidr.io',
      duration: '10 minutes',
      description: 'This cookie is set by Beeswax to determine whether the user has accepted the cookie consent box.',
    },
  ],
  functional: [
    {
      name: 'CxtId',
      domain: 'connextra.com',
      duration: '1 year',
      description:
        'This cookie is used to track user behavior and navigation across the website for improvement of user experience and optimization of advertising campaigns.',
    },
    {
      name: 'Betzone',
      domain: 'connextra.com',
      duration: '1 year',
      description: '',
    },
  ],
  performance: [
    {
      name: '_ga',
      domain: 'betzone.pages.dev',
      duration: '1 year 1 month',
      description:
        "This cookie name is associated with Google Universal Analytics - which is a significant update to Google's more commonly used analytics service. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.",
    },
    {
      name: '_ga_YRXK4JC1PV',
      domain: 'betzone.pages.dev',
      duration: '1 year 1 month',
      description: 'This cookie is used by Google Analytics to persist session state.',
    },
    {
      name: '_dd_s',
      domain: 'betzone.pages.dev',
      duration: '15 minutes',
      description:
        "This cookie is used to store session data, ensuring that visits to a website are consistent during a session. It may include data such as how the visitor interacts with the site's pages, any preferences selected, and can help in error management..",
    },
  ],
};
