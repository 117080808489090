import React, { FunctionComponent } from 'react';
import { ActivationStatus, BudgetLimitsTransactionResponse } from '../../../../types.__generated__';
import { Button } from '../../../shared/Button';
import { Form, FormControl, FormFieldType } from '../../../shared/Form';
import { FormControlSpacing } from '../../../shared/FormControlSpacing';
import { Paragraph } from '../../../shared/Paragraph';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { useGamblingControlsValidationRules } from '../../config';
import { MediatorType, SetPlayerLimitsData } from '../../types';
import { IGroupedLimitsTypes, LimitPeriodsEnum } from '../types';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { getLimitProperties } from '../../helper';
import './AccountLimitsModal.scss';

type AccountLimitsModalProps = {
  /**
   * Function to handle close events
   */
  onClose: () => void;
  /**
   * function which is triggered on form submit
   */
  onSubmit: (data: SetPlayerLimitsData) => void;
  /**
   * Player limit data returned from spine
   */
  data?: IGroupedLimitsTypes;
  /**
   * boolean value to display Modal
   */
  isOpen: boolean;
  /**
   * Different mediator type
   */
  mediatorType: MediatorType;
};

/**
 * Component to contain edit and set modal
 */
export const AccountLimitsModal: FunctionComponent<AccountLimitsModalProps> = ({
  onClose,
  onSubmit,
  data,
  isOpen,
  mediatorType,
}) => {
  const { t } = useTranslations();
  const title = getLimitProperties(mediatorType, t).title;
  const validation = useGamblingControlsValidationRules();

  const getPendingItemByPeriod = (period: LimitPeriodsEnum): BudgetLimitsTransactionResponse | undefined => {
    return data?.[period]?.find((item) => item.activationStatus === ActivationStatus.PendingUserAction);
  };

  const getLimitAmount = (period: LimitPeriodsEnum): number | null => {
    const amount = data?.[period]?.find(
      (item) => item.activationStatus === ActivationStatus.Active && !item.isPendingCancellation
    )?.amount;
    const pendingAmount = getPendingItemByPeriod(period)?.amount;

    return amount || pendingAmount || null;
  };

  const defaultValues = {
    dailyLimit: getLimitAmount(LimitPeriodsEnum.daily),
    weeklyLimit: getLimitAmount(LimitPeriodsEnum.weekly),
    monthlyLimit: getLimitAmount(LimitPeriodsEnum.monthly),
  };

  const handleSubmit = (limits: {
    dailyLimit: string | number | null;
    weeklyLimit: string | number | null;
    monthlyLimit: string | number | null;
  }): void => {
    const daily = limits.dailyLimit ? parseInt(String(limits.dailyLimit)) : 0;
    const weekly = limits.weeklyLimit ? parseInt(String(limits.weeklyLimit)) : 0;
    const monthly = limits.monthlyLimit ? parseInt(String(limits.monthlyLimit)) : 0;

    const { type } = getLimitProperties(mediatorType, t);

    if (daily && defaultValues.dailyLimit !== daily) {
      onSubmit({
        amount: daily,
        period: LimitPeriodsEnum.daily,
        type,
        mediatorType,
      });
    }

    if (weekly && defaultValues.weeklyLimit !== weekly) {
      onSubmit({
        amount: weekly,
        period: LimitPeriodsEnum.weekly,
        type,
        mediatorType,
      });
    }

    if (monthly && defaultValues.monthlyLimit !== monthly) {
      onSubmit({
        amount: monthly,
        period: LimitPeriodsEnum.monthly,
        type,
        mediatorType,
      });
    }
  };

  const dailyLimitAmountOptions = [10, 25, 50, 100, 200, 400, 500, 1000, 2000, 5000, 10000].map((option) => {
    return {
      value: String(option),
      label: `£${option}`,
    };
  });

  const weeklyLimitAmountOptions = [10, 25, 50, 100, 200, 400, 500, 1000, 2000, 5000, 10000, 15000].map((option) => {
    return {
      value: String(option),
      label: `£${option}`,
    };
  });

  const monthlyLimitAmountOptions = [10, 25, 50, 100, 200, 400, 500, 1000, 2000, 5000, 10000, 15000, 20000, 25000].map(
    (option) => {
      return {
        value: String(option),
        label: `£${option}`,
      };
    }
  );

  return (
    <PopUpHandler
      header={t('limits.header.title', { title })}
      drawerProps={{
        backdropCanClose: true,
        className: 'account-limits-modal',
      }}
      modalProps={{ 'aria-label': 'Account Limits' }}
      backdropClassName="account-limits-modal"
      headingProps={{ headingLevel: 5 }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Paragraph className="account-limits-modal__description">{t('limits.decrease-message')}</Paragraph>
      <Form
        id="Deposit Limits"
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        FormActions={({ submitDisabled }): JSX.Element => (
          <>
            <div className="deposit-limits-modal__btn-container">
              <Button type="button" variant="secondary-alt" onClick={onClose}>
                {t('buttons.cancel.uppercase')}
              </Button>
              <Button type="submit" disabled={submitDisabled}>
                {t('buttons.submit.uppercase')}
              </Button>
            </div>
          </>
        )}
      >
        <FormControl
          type={FormFieldType.Select}
          options={dailyLimitAmountOptions}
          name="dailyLimit"
          label={t('limits.dayly-label', { title })}
          rules={validation.dailyDeposit}
          disabled={Boolean(getPendingItemByPeriod(LimitPeriodsEnum.daily))}
          props={{ theme: 'dark' }}
        />
        <FormControlSpacing />
        <FormControl
          type={FormFieldType.Select}
          options={weeklyLimitAmountOptions}
          name="weeklyLimit"
          label={t('limits.weekly-label', { title })}
          rules={validation.weeklyDeposit}
          disabled={Boolean(getPendingItemByPeriod(LimitPeriodsEnum.weekly))}
          props={{ theme: 'dark' }}
        />
        <FormControlSpacing />
        <FormControl
          type={FormFieldType.Select}
          options={monthlyLimitAmountOptions}
          name="monthlyLimit"
          label={t('limits.monthly-label', { title })}
          rules={validation.monthlyDeposit}
          disabled={Boolean(getPendingItemByPeriod(LimitPeriodsEnum.monthly))}
          props={{ theme: 'dark' }}
        />
      </Form>
    </PopUpHandler>
  );
};
