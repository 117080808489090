import { useCombobox } from 'downshift';
import memoize from 'lodash/memoize';
import throttle from 'lodash/throttle';
import React, { FunctionComponent, useContext, useState, useTransition, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { RegistrationContext } from '../../Registration.context';
import { AddressData, RegistrationStepConfig } from '../../types';
import { useSnackbar } from '../../../../hooks/snackbar';
import { FormControlSpacing } from '../../../shared/FormControlSpacing';
import { Button } from '../../../shared/Button';
import { Heading } from '../../../shared/Heading';
import { Icon } from '../../../shared/Icon';
import { AutocompleteInput } from '../../../shared/AutocompleteInput';
import { LoqateFindResult, loqateCountryCode, retrieveAddress, searchAddress } from '../../../../utils/loqate';
import { extractAddressFromLabel } from '../../../../utils/loqate/extract-address-from-label';
import './AddressSearch.scss';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

type AddressSearchProps = {
  /**
   * Should be used used to set address in data model on select
   */
  onSelectAddress: (details: AddressData) => void;
  /**
   * Called when user requests to switch to manual address entry form
   */
  onEnterManual: (addressSelected: boolean) => void;
  /**
   * Called after the menu open state has changed
   */
  onMenuOpenChange?: (isMenuOpen: boolean) => void;
  /**
   * Starting state for address data.
   */
  initialAddressData: AddressData;
  addressConfig?: RegistrationStepConfig;
};

const isNorthernIrishPostcode = (postcode: string): boolean => {
  return postcode.trim().toUpperCase().startsWith('BT');
};

/**
 * Address search & selection component/
 */
export const AddressSearch: FunctionComponent<AddressSearchProps> = ({
  initialAddressData,
  addressConfig,
  onMenuOpenChange,
  onSelectAddress,
  onEnterManual,
}) => {
  const { addSnack } = useSnackbar();
  const { t } = useTranslations();

  const [isSearching, startTransition] = useTransition();
  const { setValue, reset, formState } = useFormContext<AddressData>();
  const { onAddressSearchFocus, onAddressSearchBlur } = useContext(RegistrationContext);

  const [addressData, setAddressData] = useState<AddressData | undefined>(
    initialAddressData.addrStreetAddress ? initialAddressData : undefined
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [options, setOptions] = useState<LoqateFindResult[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [showManual, setShowManual] = useState(true);
  const [touched, setTouched] = useState(false);
  const [isNIPostcode, setIsNIPostcode] = useState(false);

  const firstFocusAddressSearch = useRef(true);

  const addErrorSnack = throttle(
    () =>
      addSnack({
        message: t('registration.step-three.address.error'),
        type: 'error',
      }),
    5000
  );

  const handleMenuChange = (open: boolean): void => {
    setIsMenuOpen(open);
    onMenuOpenChange && onMenuOpenChange(open);
  };

  // TODO: make language prop dynamic
  const handleAddressLookup = memoize(async (address?: string, containerId?: string) => {
    if (address && address.length > 2) {
      // Check if the input looks like a Northern Irish postcode
      if (isNorthernIrishPostcode(address)) {
        setIsNIPostcode(true);
        setOptions([]);
        handleMenuChange(false);

        return;
      }

      setIsNIPostcode(false);
      handleMenuChange(true);
      try {
        const searchResponse = await searchAddress({
          text: address,
          language: 'en',
          containerId,
        });

        console.log(searchResponse.Items);

        // Filter out Northern Irish addresses from results
        const filteredResults = searchResponse.Items.filter((item) => {
          const parts = item.Description.split(' ');
          const postcode = parts.length >= 2 ? `${parts[parts.length - 2]} ${parts[parts.length - 1]}`.trim() : '';

          return !isNorthernIrishPostcode(postcode);
        });

        setOptions(filteredResults);
      } catch (e) {
        addErrorSnack();
      }
    } else {
      setOptions([]);
      setIsNIPostcode(false);
      handleMenuChange(false);
    }
  });

  const isValidAddressData = (addressData: AddressData): boolean => {
    const { fieldSets } = addressConfig || { fieldSets: [] };
    const addressFields = fieldSets[0]?.fields;
    const resultFields: string[] = [];

    // Check if the address is from Northern Ireland
    if (isNorthernIrishPostcode(addressData.addrPostalCode)) {
      return false;
    }

    addressFields
      .filter((address) => !address.optional)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .forEach((address) => resultFields.push(addressData[address.name]));

    return resultFields.every((addressItem) => Boolean(addressItem));
  };

  const handleSelect = async (selectedResult: LoqateFindResult): Promise<void> => {
    if (selectedResult.Type === 'Address') {
      startTransition(() => {
        (async (): Promise<void> => {
          try {
            handleMenuChange(false);
            /**
             * Setting a default empty model incase everything has gone wrong, at least then the user
             * can manually enter their address. We'll then trigger an error with the details to new
             * relic, so we can fix it.
             */
            let addressData = {
              addrStreetAddress: '',
              addrLocality: '',
              addrRegion: '',
              addrPostalCode: '',
              addrCountryCode: loqateCountryCode,
            };

            try {
              addressData = extractAddressFromLabel(selectedResult);

              // Check if selected address is in Northern Ireland
              if (isNorthernIrishPostcode(addressData.addrPostalCode)) {
                addSnack({
                  message: t('registration.step-three.address.northern-ireland-error'),
                  type: 'error',
                });

                return;
              }

              if (Math.random() <= 0.1) {
                /**
                 * We don't need to do this any more, however this is the only way loqate charge us.
                 * Let's add an occasional call to loqate here, just so they don't suspend our account.
                 */
                retrieveAddress(selectedResult.Id);
              }
            } catch (e) {
              console.error('Search address error: ', e);
            }

            onSelectAddress(addressData);
            setSearchText(addressData.addrStreetAddress);
            setAddressData(addressData);

            for (const [key, value] of Object.entries(addressData)) {
              setValue(key as keyof AddressData, value, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }

            if (!isValidAddressData(addressData)) {
              onEnterManual(true);
            }
          } catch (e) {
            addErrorSnack();
          }
        })();
      });
    } else {
      setOptions([]);
      handleAddressLookup(searchText, selectedResult.Id);
    }
  };

  return (
    <div className="address-search">
      {!addressData && (
        <FormControlSpacing>
          <AutocompleteInput<LoqateFindResult>
            value={searchText}
            onMenuOpenChange={(change): void => {
              if (change.type === useCombobox.stateChangeTypes.InputBlur) {
                handleMenuChange(!!change.isOpen);
              }
            }}
            isMenuOpen={isMenuOpen}
            loadingOptionsPlaceholder={isSearching ? 'Loading...' : null}
            inputProps={{
              label: t('register.step.address.postal-code.label'),
              showFeedbackIcon: false,
              iconRight: showManual && (
                <Button
                  type="button"
                  variant="text"
                  size="small"
                  onClick={(): void => {
                    onEnterManual(false);
                    handleMenuChange(false);
                  }}
                >
                  {t('input.manual-edit')}
                </Button>
              ),
              validationState:
                ((formState.submitCount > 0 && !formState.isValid) || touched || isNIPostcode) &&
                !isMenuOpen &&
                !isSearching &&
                ((searchText && !addressData) || searchText === '')
                  ? 'error'
                  : undefined,
              validationMessages: [
                {
                  message: isNIPostcode
                    ? t('validation.address-entry.northern-ireland')
                    : t('validation.address-entry.invalid'),
                  id: isNIPostcode ? 'ni-postcode-error' : 'empty-address-text',
                },
              ],
              onBlur: (): void => {
                onAddressSearchBlur?.();
                setTouched(true);
              },
              onFocus: (): void => {
                onAddressSearchFocus?.();
                setShowManual(true);

                if (options.length > 0) {
                  handleMenuChange(true);
                }
              },
            }}
            onInputChange={(s?: string): void => {
              setSearchText(s || '');
              handleAddressLookup(s);
              setShowManual(s !== '');

              if (s?.length === 1 && firstFocusAddressSearch.current) {
                firstFocusAddressSearch.current = false;
              }
            }}
            name="autoComplete"
            options={options}
            optionToString={(result: LoqateFindResult | null): string =>
              result ? `${result.Text}, ${result.Description}` : ''
            }
            onSelect={handleSelect}
          />
        </FormControlSpacing>
      )}

      {addressData && (
        <div className="address-search__selected-address">
          <div className="address-search__address">
            <div>
              <Heading level={6} className="address-search__line">
                {addressData.addrStreetAddress}
              </Heading>
              <span className="address-search__city">{addressData.addrLocality}</span>
              <span className="address-search__county">{addressData.addrRegion}</span>
              <span className="address-search__postCode">{addressData.addrPostalCode}</span>
            </div>
            <Icon variant="PinHome" />
          </div>

          <div className="address-search__selected-address-actions">
            <Button
              type="button"
              variant="text"
              size="small"
              icon={<Icon variant="Edit" />}
              iconPosition="left"
              onClick={(): void => {
                onEnterManual(true);
              }}
            >
              {t('input.search-address.edit')}
            </Button>

            <Button
              type="button"
              variant="text"
              size="small"
              icon={<Icon variant="SearchLocation" />}
              iconPosition="left"
              onClick={(): void => {
                setSearchText('');
                setAddressData(undefined);
                reset(initialAddressData);
                setTouched(false);
                setIsNIPostcode(false);
              }}
            >
              {t('input.search-address.look-up')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
