export interface IUseUserBalanceData {
  cashBalance: number;
  bonusBalance: number;
}

export enum BalanceTypes {
  cashBalance = 'cashBalance',
  bonusBalance = 'bonusBalance',
}

export interface IUseUserBalanceReturnType {
  isBalanceLoading: boolean;
  userBalance: IUseUserBalanceData;
}

export enum USER_BALANCE_TYPE {
  MAIN = 'main',
  BONUS = 'bonus',
  FREEBET = 'free-bet',
  FREESPINS = 'free-spins',
}
