import Cookies from 'js-cookie';
import { AccountFieldSetConfig } from '../components/Account/types';
import { MarketingData, RegistrationFieldConfig, RegistrationFieldSetConfig } from '../components/Registration/types';
import { btag } from '../consts';
import { FlattenedProfileData } from '../hooks/registration';
import { getExternalUrlParam } from './links-helper/links-helper';

type FieldType = RegistrationFieldConfig & { name: string };
type FieldSetType = RegistrationFieldSetConfig | AccountFieldSetConfig<FlattenedProfileData>;

export const getMarketingPreferences = (
  marketingObject: MarketingData,
  fieldSets: FieldSetType[]
): (FieldSetType & {
  fields: FieldType[];
})[] =>
  fieldSets.map((fieldSet) => {
    const { fields, ...otherData } = fieldSet;
    const newFields = (fields as FieldType[]).map((marketingPreferenceName) => {
      const marketingValue =
        marketingObject[marketingPreferenceName.name as 'optInEmail' | 'optInSms' | 'optInTelephone'];

      return {
        ...marketingPreferenceName,
        hidden: marketingValue === undefined ? false : !marketingValue,
      };
    });

    return {
      ...otherData,
      fields: newFields,
    };
  });

export const handleMarketingCookie = (): void => {
  const marketingCookie = getExternalUrlParam(btag);

  marketingCookie && Cookies.set(btag, marketingCookie, { expires: 60 });
};
