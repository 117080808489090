import React, { FunctionComponent, Suspense } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { errorsToErrorSummary } from '../../../../utils/form';
import { PeriodUnit, periodToDays } from '../../../../utils/time';
import { InputValidationAlerts } from '../../../shared/InputValidationAlert';
import { Paragraph } from '../../../shared/Paragraph';
import { Select, SelectOption } from '../../../shared/Select';
import { ToggleButton } from '../../../shared/ToggleButton';
import { getDurationLabelByValue } from '../../SelfExclusion/helper';
import { AccountPauseFormID } from '../consts';
import { takeABreakMocks } from '../mocks';
import './styles';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

export type TakeABreakFormData = {
  duration: string;
  reason: string;
  durationLabel?: string;
};

export type TakeABreakFormProps = {
  /**
   * Callback that's executed when the user submits the form
   */
  onFormSubmit: (data: TakeABreakFormData) => void;
  /**
   * Initial form values
   */
  defaultValues: TakeABreakFormData;
  check?: (bool: boolean) => void;
};

export const TakeABreakForm: FunctionComponent<TakeABreakFormProps> = ({ onFormSubmit, defaultValues, check }) => {
  const { control, watch, handleSubmit, formState } = useForm<TakeABreakFormData>({
    defaultValues,
  });

  const duration = watch('duration');
  const durationOptions: SelectOption[] = takeABreakMocks.takeABreakPeriod.options.map(({ label, value, unit }) => ({
    label,
    value: periodToDays(+value, unit as PeriodUnit),
  }));

  const { t } = useTranslations();

  async function onSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();

    if (!formState.isSubmitting) {
      return handleSubmit((formData) => {
        onFormSubmit({
          ...formData,
          durationLabel: getDurationLabelByValue(formData.duration, durationOptions),
        });
      })(e);
    }
  }

  const errorSummary = errorsToErrorSummary(formState.errors, {}, AccountPauseFormID);

  return (
    <form id={AccountPauseFormID} onSubmit={onSubmit} className="takeabreak-form">
      <Controller
        control={control}
        name="duration"
        rules={{
          required: { value: true, message: 'This field is required' },
        }}
        render={({ field, ...renderProps }): JSX.Element => (
          <div body-scroll-lock-ignore="true" className="takeabreak-form__duration-options">
            {durationOptions.map((option) => (
              <ToggleButton
                {...renderProps}
                selected={duration === option.value}
                value={option.value}
                onClick={(): void => {
                  check && check(true);
                  field?.onChange(option.value);
                }}
                key={option.value}
                className="takeabreak-form__duration"
                variant="secondary-alt"
              >
                {option.label}
              </ToggleButton>
            ))}
          </div>
        )}
      />
      <InputValidationAlerts
        messages={errorSummary.duration?.messages}
        state={errorSummary.duration?.messages?.length ? 'error' : undefined}
      />

      <>
        <Paragraph className="takeabreak-form__reason-label" size="sm">
          {t('take-a-break.reason.title')}
        </Paragraph>

        <Suspense>
          <Controller
            control={control}
            name="reason"
            rules={{
              required: {
                value: takeABreakMocks.takeABreakReason.required,
                message: 'This field is required',
              },
            }}
            render={({ field, ...props }): JSX.Element => (
              <Select
                {...props}
                {...field}
                className="takeabreak-form__select"
                label="Pick an option..."
                theme="dark"
                placement="top"
                options={takeABreakMocks.takeABreakReason.options}
                validationMessages={errorSummary.reason?.messages}
                validationState={errorSummary.reason?.messages?.length ? 'error' : undefined}
              />
            )}
          />
        </Suspense>
      </>
    </form>
  );
};
