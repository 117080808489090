import React, { FunctionComponent } from 'react';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { MediatorType } from '../../types';
import './LimitsHistory.scss';

type LimitsHistoryProps = {
  show: boolean;
  onClose(): void;
  mediatorType?: MediatorType;
};

export const LimitsHistory: FunctionComponent<LimitsHistoryProps> = ({ show, onClose, mediatorType }) => {
  const { t } = useTranslations();

  const noResultsMessage = (
    <div className="limits-history__no-results">
      <p className="limits-history__no-results-text">{t('search.no-results')}</p>
    </div>
  );

  return (
    <PopUpHandler
      header={t('limits.history')}
      drawerProps={{
        backdropCanClose: true,
      }}
      headingProps={{ headingLevel: 5 }}
      isOpen={show}
      onClose={(): void => {
        onClose();
      }}
    >
      {noResultsMessage}
    </PopUpHandler>
  );
};
