import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserFreeBetQueryVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  userProfileId: Types.Scalars['ID']['input'];
  pageLimit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type UserFreeBetQuery = { __typename?: 'Query', userFreeBet?: { __typename?: 'PagedUserProfileFreeBet', data?: Array<{ __typename?: 'UserProfileFreeBet', freeBetTemplateId?: string | null, bonusTemplateId?: string | null, status?: Types.UserProfileFreeBetStatus | null, expiresOn?: string | null, mediatorId?: string | null, currencyCode?: string | null, grantedAmount?: number | null, consumedGrantedAmount?: number | null, convertedAmount?: number | null, balanceAmount?: number | null, conversionMaxAmount?: number | null } | null> | null } | null };


export const UserFreeBetDocument = gql`
    query UserFreeBet($xTenantId: ID!, $userProfileId: ID!, $pageLimit: Int) {
  userFreeBet(
    xTenantID: $xTenantId
    userProfileId: $userProfileId
    pageLimit: $pageLimit
  ) {
    data {
      freeBetTemplateId
      bonusTemplateId
      status
      expiresOn
      mediatorId
      currencyCode
      grantedAmount
      consumedGrantedAmount
      convertedAmount
      balanceAmount
      conversionMaxAmount
    }
  }
}
    `;

/**
 * __useUserFreeBetQuery__
 *
 * To run a query within a React component, call `useUserFreeBetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFreeBetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFreeBetQuery({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      userProfileId: // value for 'userProfileId'
 *      pageLimit: // value for 'pageLimit'
 *   },
 * });
 */
export function useUserFreeBetQuery(baseOptions: Apollo.QueryHookOptions<UserFreeBetQuery, UserFreeBetQueryVariables> & ({ variables: UserFreeBetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFreeBetQuery, UserFreeBetQueryVariables>(UserFreeBetDocument, options);
      }
export function useUserFreeBetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFreeBetQuery, UserFreeBetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFreeBetQuery, UserFreeBetQueryVariables>(UserFreeBetDocument, options);
        }
export function useUserFreeBetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserFreeBetQuery, UserFreeBetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserFreeBetQuery, UserFreeBetQueryVariables>(UserFreeBetDocument, options);
        }
export type UserFreeBetQueryHookResult = ReturnType<typeof useUserFreeBetQuery>;
export type UserFreeBetLazyQueryHookResult = ReturnType<typeof useUserFreeBetLazyQuery>;
export type UserFreeBetSuspenseQueryHookResult = ReturnType<typeof useUserFreeBetSuspenseQuery>;
export type UserFreeBetQueryResult = Apollo.QueryResult<UserFreeBetQuery, UserFreeBetQueryVariables>;