import { TransactionCategoryLabels } from '../../../models/transactions.model';

export const withdrawalPendingDisplayType = 'Withdrawal - Pending';
export const betsDisplayType = 'Bets';

export const transactionCategories: {
  label: string;
  value?: TransactionCategoryLabels;
}[] = [
  {
    value: undefined,
    label: 'all',
  },
  {
    value: 'Banking',
    label: 'banking',
  },
  {
    value: 'Bets',
    label: 'bets',
  },
  {
    value: 'Gaming',
    label: 'gaming',
  },
  {
    value: 'Bonus',
    label: 'bonus',
  },
];

export const operationTypes = {
  debit: 'Debit',
};

export const walletTypeNames = {
  main: 'main',
  bonus: 'bonus',
};
