import { formatDate, isFuture, parseISO } from 'date-fns';
import complement from 'lodash/fp/complement';
import pipe from 'lodash/fp/pipe';
import { BudgetLimitsTransactionResponse, BudgetLimitsTransactionType } from '../../types.__generated__';
import { capitalizeFirstLetter } from '../../utils/form';
import { IGroupedLimits, IsDateReached, MediatorType } from './types';
import { UsedLimit } from './AccountLimits/types';

export const calculateDate = (value: number): string => {
  const date = new Date();
  const timeZoneOffset = date.getTimezoneOffset() * 60000;

  date.setDate(date.getDate() + value);

  return new Date(date.getTime() - timeZoneOffset).toISOString();
};

export const getFirstDayOfNextMonth = (): string => {
  const date = new Date();
  const nextMonth = date.getMonth() + 1;
  const nextDate = new Date(date.getFullYear(), nextMonth, 1);

  return formatDate(nextDate, 'dd.MM.yyyy');
};

export const getLimitNameByPeriod = (period = 'unknown', isCapitalized?: boolean): string => {
  const names: { [key: string]: string } = {
    P1D: 'daily',
    P7D: 'weekly',
    P30D: 'monthly',
    unknown: 'Unknown Limit',
  };

  return isCapitalized ? capitalizeFirstLetter(names[period]) : names[period];
};

// is date in future
export const isDateReached: IsDateReached = pipe(parseISO, complement(isFuture));

export const groupLimitsData = (transactions?: BudgetLimitsTransactionResponse[]): IGroupedLimits => {
  const groupedTransactions = transactions?.reduce((acc, transaction) => {
    const { mediatorType, period } = transaction;

    if (!acc[mediatorType as string]) {
      acc[mediatorType as string] = {};
    }

    if (!acc[mediatorType as string][period as string]) {
      acc[mediatorType as string][period as string] = [];
    }

    acc[mediatorType as string][period as string].push(transaction);

    return acc;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as any);

  return groupedTransactions;
};

export const codedToISO8601: Record<string, string> = {
  '15': 'PT15M',
  '30': 'PT30M',
  '1': 'PT1H',
};

export const decodedMinutesFromISO8601: Record<string, number> = {
  PT15M: 15,
  PT30M: 30,
  PT1H: 60,
};

export const getLimitProperties = (
  mediatorType: MediatorType,
  t?: (arg: string) => string
): {
  title: string;
  type: UsedLimit;
} => {
  const isDeposit = mediatorType === MediatorType.Monetary;
  const isCasinoLoss = mediatorType === MediatorType.Casino;
  const isSportsLoss = mediatorType === MediatorType.Sports;

  const depositName = isDeposit && t?.('limits.deposit');
  const lossCasinoName = isCasinoLoss && t?.('limits.casino-loss');
  const lossSportsName = isSportsLoss && t?.('limits.sports-loss');

  const depositType = isDeposit && BudgetLimitsTransactionType.Deposit;
  const lossType = (isCasinoLoss || isSportsLoss) && BudgetLimitsTransactionType.Loss;

  return {
    title: depositName || lossCasinoName || lossSportsName || '',
    type: depositType || lossType || BudgetLimitsTransactionType.Deposit,
  };
};
