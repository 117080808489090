import React, { FunctionComponent } from 'react';
import DOMPurify from 'dompurify';
import { Paragraph } from '../../../../shared/Paragraph';
import { ConsentVendorCookie } from '../../types';
import './CookieDetailsItem.scss';

type CookiesDetailsItemProps = {
  details: ConsentVendorCookie;
};

export const CookiesDetailsItem: FunctionComponent<CookiesDetailsItemProps> = ({ details }) => {
  const { name, domain, description, duration } = details;

  return (
    <div className="cookie-details-item">
      <Paragraph size="xs" className="cookie-details-item__field">
        <span className="cookie-details-item__key">Name:</span>
        <span className="cookie-details-item__value">{name}</span>
      </Paragraph>
      <Paragraph size="xs" className="cookie-details-item__field">
        <span className="cookie-details-item__key">Host:</span>
        <span className="cookie-details-item__value">{domain}</span>
      </Paragraph>
      <Paragraph size="xs" className="cookie-details-item__field">
        <span className="cookie-details-item__key">Duration:</span>
        <span className="cookie-details-item__value">{duration}</span>
      </Paragraph>
      {description && (
        <Paragraph
          size="xs"
          className="cookie-details-item__field"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(`<span class='cookie-details-item__key'>Description:</span>` + description),
          }}
        />
      )}
    </div>
  );
};
