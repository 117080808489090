import { formatDate, parseISO } from 'date-fns';
import React, { FC } from 'react';
import { BudgetLimitsTransactionResponse } from '../../../../types.__generated__';
import { formatCurrency } from '../../../../utils/wallets';
import { Badge } from '../../../shared/Badge';
import { Button } from '../../../shared/Button';
import { Icon } from '../../../shared/Icon';
import { isDateReached } from '../../helper';
import './styles';

interface AccountLimitsItemPendingLimitProps {
  pendingActivationItem: BudgetLimitsTransactionResponse | undefined;
  pendingUserActionItem: BudgetLimitsTransactionResponse | undefined;
  pendingCancellationItem: BudgetLimitsTransactionResponse | undefined;
  setShowPrompt: (isShow: boolean) => void;
}

export const AccountLimitsItemPendingLimit: FC<AccountLimitsItemPendingLimitProps> = ({
  pendingCancellationItem,
  pendingUserActionItem,
  pendingActivationItem,
  setShowPrompt,
}) => {
  const isPendingCancellation = pendingCancellationItem?.isPendingCancellation;
  const isCoolOffEndDatePassed =
    pendingUserActionItem?.pendingChangeExpectedOn && isDateReached(pendingUserActionItem.pendingChangeExpectedOn);
  const isShow = pendingCancellationItem || pendingUserActionItem || pendingActivationItem;

  return isShow ? (
    <div className="account-limits-item-pending-limit">
      <hr />
      <div className="account-limits-item-pending-limit__content">
        <span className="account-limits-item-pending-limit__icon">
          <Icon variant="Info" />
        </span>
        <span className="account-limits-item-pending-limit__value">
          {isCoolOffEndDatePassed && !isPendingCancellation && (
            <span className="account-limits-item-pending-limit__update">
              New limit request is ready.
              <Button
                className="account-limits-item-pending-limit__update-button"
                type="button"
                variant="text"
                size="medium"
                onClick={(): void => {
                  setShowPrompt(true);
                }}
              >
                View update
              </Button>
            </span>
          )}
          {!isCoolOffEndDatePassed && !isPendingCancellation && (
            <>
              <span className="account-limits-item-pending-limit__pending">
                New limit request{' '}
                <span className="account-limits-item-pending-limit__pending-value">
                  {pendingUserActionItem?.amount
                    ? formatCurrency(pendingUserActionItem?.amount, 'auto', 'auto')
                    : 'No Limit'}
                </span>
              </span>
              <Badge>PENDING</Badge>
              <span>
                Your new limit will take effect after you reconfirm here on or after{' '}
                {formatDate(parseISO(pendingUserActionItem?.pendingChangeExpectedOn as string), 'dd/MM/yyyy | HH:mm')}
              </span>
            </>
          )}
          {isPendingCancellation && (
            <>
              <span className="account-limits-item-pending-limit__cancellation">
                Your{' '}
                <span className="account-limits-item-pending-limit__pending-value">
                  {pendingCancellationItem?.amount
                    ? formatCurrency(pendingCancellationItem?.amount, 'auto', 'auto')
                    : ''}
                </span>{' '}
                limit will be deleted automatically on{' '}
                {formatDate(parseISO(pendingCancellationItem.pendingChangeExpectedOn as string), 'dd/MM/yyyy | HH:mm')}
              </span>
              <Badge className="cancellation-badge">CANCELLATION</Badge>
            </>
          )}
        </span>
      </div>
    </div>
  ) : null;
};
