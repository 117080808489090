import React, { FunctionComponent } from 'react';
import { IconBaseProps, IconType as ReactIconType } from '@react-icons/all-files';

import { BiMenuAltLeft } from '@react-icons/all-files/bi/BiMenuAltLeft';
import { BsFillGridFill } from '@react-icons/all-files/bs/BsFillGridFill';
import { BsList } from '@react-icons/all-files/bs/BsList';
import { BsShuffle } from '@react-icons/all-files/bs/BsShuffle';
import { BsTriangleFill } from '@react-icons/all-files/bs/BsTriangleFill';
import { FaArrowDown } from '@react-icons/all-files/fa/FaArrowDown';
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';
import { HiArrowLeft } from '@react-icons/all-files/hi/HiArrowLeft';
import { FaArrowUp } from '@react-icons/all-files/fa/FaArrowUp';
import { FaBan } from '@react-icons/all-files/fa/FaBan';
import { FaBars } from '@react-icons/all-files/fa/FaBars';
import { FaBell } from '@react-icons/all-files/fa/FaBell';
import { FaBellSlash } from '@react-icons/all-files/fa/FaBellSlash';
import { FaCalendar } from '@react-icons/all-files/fa/FaCalendar';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle';
import { FaCheckSquare } from '@react-icons/all-files/fa/FaCheckSquare';
import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';
import { FaChevronLeft } from '@react-icons/all-files/fa/FaChevronLeft';
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight';
import { FaChevronUp } from '@react-icons/all-files/fa/FaChevronUp';
import { FaCloudUploadAlt } from '@react-icons/all-files/fa/FaCloudUploadAlt';
import { FaCompress } from '@react-icons/all-files/fa/FaCompress';
import { FaCookie } from '@react-icons/all-files/fa/FaCookie';
import { FaCookieBite } from '@react-icons/all-files/fa/FaCookieBite';
import { FaCopy } from '@react-icons/all-files/fa/FaCopy';
import { FaCreditCard } from '@react-icons/all-files/fa/FaCreditCard';
import { FaRegDotCircle } from '@react-icons/all-files/fa/FaRegDotCircle';
import { FaEllipsisH } from '@react-icons/all-files/fa/FaEllipsisH';
import { FaEllipsisV } from '@react-icons/all-files/fa/FaEllipsisV';
import { FaEnvelope } from '@react-icons/all-files/fa/FaEnvelope';
import { FaExclamationCircle } from '@react-icons/all-files/fa/FaExclamationCircle';
import { FaExpand } from '@react-icons/all-files/fa/FaExpand';
import { FaEye } from '@react-icons/all-files/fa/FaEye';
import { FaEyeSlash } from '@react-icons/all-files/fa/FaEyeSlash';
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook';
import { FaGift } from '@react-icons/all-files/fa/FaGift';
import { FaHeadset } from '@react-icons/all-files/fa/FaHeadset';
import { FaHeart } from '@react-icons/all-files/fa/FaHeart';
import { FaHeartBroken } from '@react-icons/all-files/fa/FaHeartBroken';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { FaLanguage } from '@react-icons/all-files/fa/FaLanguage';
import { FaLock } from '@react-icons/all-files/fa/FaLock';
import { FaMinusSquare } from '@react-icons/all-files/fa/FaMinusSquare';
import { FaMoneyCheck } from '@react-icons/all-files/fa/FaMoneyCheck';
import { FaPhoneAlt } from '@react-icons/all-files/fa/FaPhoneAlt';
import { FaPlay } from '@react-icons/all-files/fa/FaPlay';
import { FaPlusCircle } from '@react-icons/all-files/fa/FaPlusCircle';
import { FaQuestionCircle } from '@react-icons/all-files/fa/FaQuestionCircle';
import { FaRegCheckSquare } from '@react-icons/all-files/fa/FaRegCheckSquare';
import { FaRegCircle } from '@react-icons/all-files/fa/FaRegCircle';
import { FaRegEdit } from '@react-icons/all-files/fa/FaRegEdit';
import { FaRegFile } from '@react-icons/all-files/fa/FaRegFile';
import { FaRegFileAlt } from '@react-icons/all-files/fa/FaRegFileAlt';
import { FaRegSquare } from '@react-icons/all-files/fa/FaRegSquare';
import { FaRegTimesCircle } from '@react-icons/all-files/fa/FaRegTimesCircle';
import { FaReply } from '@react-icons/all-files/fa/FaReply';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { FaSearchLocation } from '@react-icons/all-files/fa/FaSearchLocation';
import { FaShare } from '@react-icons/all-files/fa/FaShare';
import { FaShieldAlt } from '@react-icons/all-files/fa/FaShieldAlt';
import { FaSignLanguage } from '@react-icons/all-files/fa/FaSignLanguage';
import { FiLogOut } from '@react-icons/all-files/fi/FiLogOut';
import { FaSms } from '@react-icons/all-files/fa/FaSms';
import { FaSpinner } from '@react-icons/all-files/fa/FaSpinner';
import { FaStopwatch } from '@react-icons/all-files/fa/FaStopwatch';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';
import { FaUnlock } from '@react-icons/all-files/fa/FaUnlock';
import { FaUserCircle } from '@react-icons/all-files/fa/FaUserCircle';
import { FaUserLock } from '@react-icons/all-files/fa/FaUserLock';
import { FaWallet } from '@react-icons/all-files/fa/FaWallet';
import { FiBell } from '@react-icons/all-files/fi/FiBell';
import { FiUpload } from '@react-icons/all-files/fi/FiUpload';
import { FiPlus } from '@react-icons/all-files/fi/FiPlus';
import { GiAges } from '@react-icons/all-files/gi/GiAges';
import { GiCherry } from '@react-icons/all-files/gi/GiCherry';
import { GiMailbox } from '@react-icons/all-files/gi/GiMailbox';
import { ImCreditCard } from '@react-icons/all-files/im/ImCreditCard';
import { IoMdWarning } from '@react-icons/all-files/io/IoMdWarning';
import { MdDeleteForever } from '@react-icons/all-files/md/MdDeleteForever';
import { GrReturn } from '@react-icons/all-files/gr/GrReturn';
import { MdLock } from '@react-icons/all-files/md/MdLock';
import { MdPhoneInTalk } from '@react-icons/all-files/md/MdPhoneInTalk';
import { MdVerifiedUser } from '@react-icons/all-files/md/MdVerifiedUser';
import { RiDeleteBinLine } from '@react-icons/all-files/ri/RiDeleteBinLine';
import { RiFileHistoryLine } from '@react-icons/all-files/ri/RiFileHistoryLine';
import { RiRestartLine } from '@react-icons/all-files/ri/RiRestartLine';
import { GrMapLocation } from '@react-icons/all-files/gr/GrMapLocation';
import { IconVariant } from './Icon.types';
import { RiWhatsappFill } from '@react-icons/all-files/ri/RiWhatsappFill';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';
import { BsXCircleFill } from '@react-icons/all-files/bs/BsXCircleFill';
import { MdEdit } from '@react-icons/all-files/md/MdEdit';
import { PinHome } from '../SVG/Icons';
import { CloseIcon } from '../SVG/Icons';
import { HiArrowRight } from '@react-icons/all-files/hi/HiArrowRight';
import { GoClock } from '@react-icons/all-files/go/GoClock';
import { IoIosFootball } from '@react-icons/all-files/io/IoIosFootball';
import { IoDiceOutline } from '@react-icons/all-files/io5/IoDiceOutline';
import { IoWalletOutline } from '@react-icons/all-files/io5/IoWalletOutline';

const ReactIconMap: Record<IconVariant, ReactIconType> = {
  ArrowDown: FaArrowDown,
  ArrowLeft: HiArrowLeft,
  ArrowRight: HiArrowRight,
  ArrowUp: FaArrowUp,
  Bell: FaBell,
  BellSlash: FaBellSlash,
  BellOutline: FiBell,
  Calendar: FaCalendar,
  ChangePassword: FaUserLock,
  Cherries: GiCherry,
  ChevronDown: FaChevronDown,
  ChevronLeft: FaChevronLeft,
  ChevronRight: FaChevronRight,
  ChevronUp: FaChevronUp,
  CircleDot: FaRegDotCircle,
  CircleOutline: FaRegCircle,
  CircleQuestion: FaQuestionCircle,
  CirclePlus: FaPlusCircle,
  Clock: GoClock,
  Close: FaTimes,
  Compress: FaCompress,
  Copy: FaCopy,
  CustomerSupport: FaHeadset,
  Delete: MdDeleteForever,
  DeleteAlt: RiDeleteBinLine,
  Deposit: FaMoneyCheck,
  Edit: FaRegEdit,
  MdEdit: MdEdit,
  Email: FaEnvelope,
  KeyboardReturn: GrReturn,
  Error: BsXCircleFill,
  Expand: FaExpand,
  Eye: FaEye,
  EyeSlash: FaEyeSlash,
  File: FaRegFileAlt,
  Heart: FaHeart,
  HeartBroken: FaHeartBroken,
  HorizontalDots: FaEllipsisH,
  Info: FaInfoCircle,
  Lock: FaLock,
  LockAlt: MdLock,
  Logout: FiLogOut,
  MapLocation: GrMapLocation,
  Menu: FaBars,
  MenuAltLeft: BiMenuAltLeft,
  NewFile: FaRegFile,
  Phone: FaPhoneAlt,
  PhoneTalk: MdPhoneInTalk,
  Play: FaPlay,
  Plus: FiPlus,
  Post: GiMailbox,
  Profile: FaUserCircle,
  PushChevronUp: FaChevronUp,
  Reply: FaReply,
  Search: FaSearch,
  SearchLocation: FaSearchLocation,
  SelectArrowIcon: FaChevronDown,
  Share: FaShare,
  Sms: FaSms,
  Spinner: FaSpinner,
  SquareCheck: FaCheckSquare,
  SquareMinus: FaMinusSquare,
  SquareOutline: FaRegSquare,
  SquareCheckAlt: FaRegCheckSquare,
  Stop: FaBan,
  Success: FaCheckCircle,
  Timeout: FaStopwatch,
  Triangle: BsTriangleFill,
  Unlock: FaUnlock,
  Warning: FaExclamationCircle,
  WhatsApp: RiWhatsappFill,
  Check: FaCheck,
  CircleClose: FaRegTimesCircle,
  GalleryView: BsFillGridFill,
  ListView: BsList,
  Gift: FaGift,
  Cookie: FaCookie,
  VerticalDots: FaEllipsisV,
  Wallet: FaWallet,
  AgeRestriction: GiAges,
  Card: FaCreditCard,
  CloudUpload: FaCloudUploadAlt,
  CookieAlt: FaCookieBite,
  Cvv: ImCreditCard,
  English: FaLanguage,
  Facebook: FaFacebook,
  German: FaSignLanguage,
  History: RiFileHistoryLine,
  Reload: RiRestartLine,
  Shield: FaShieldAlt,
  Shuffle: BsShuffle,
  Twitter: FaTwitter,
  Verified: MdVerifiedUser,
  Upload: FiUpload,
  NavArrowLeft: HiArrowLeft,
  NavArrowRight: FaArrowRight,
  IoMdWarning: IoMdWarning,
  ExternalLink: FiExternalLink,
  PinHome: PinHome,
  CloseIcon: CloseIcon,
  IoIosFootball: IoIosFootball,
  IoDiceOutline: IoDiceOutline,
  IoWalletOutline: IoWalletOutline,
};

export type IconProps = {
  variant: IconVariant;
} & IconBaseProps;

/**
 * Icon component which either renders a custom component mapped to the `IconVariant` in `AppConfig`,
 * or falls back to using
 * Font Awesome from the `react-icons` package.
 * If you need a variant that's not included here, you must add it to the `IconVariant` type and the `ReactIconMap`.
 * Accepts all props that the icon components from the `react-icons` package accepts.
 *
 * Example:
 *
 * ```tsx
 * <Icon variant='Search' title='Games Search' />
 * ```
 */
export const Icon: FunctionComponent<IconProps> = ({ variant, ...props }) => {
  const Icon = ReactIconMap[variant];

  if (Icon) {
    return <Icon {...props} />;
  } else {
    console.error('Icon variant is not correct');
  }

  return null;
};
