import React, { FC } from 'react';
import { formatCurrency } from '../../../../utils/wallets';
import { Button } from '../../../shared/Button';
import { Icon } from '../../../shared/Icon';
import { Paragraph } from '../../../shared/Paragraph';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { getLimitNameByPeriod, getLimitProperties } from '../../helper';
import { LimitPeriodsEnum } from '../types';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { MediatorType } from '../../types';

type AccountLimitsRemoveModalProps = {
  /**
   * Function to be triggered when user attempts to close pop up
   */
  onClose: () => void;
  /**
   * Whether to display modal or not
   */
  isOpen: boolean;
  /**
   * Which limit type is being removed
   */
  mediatorType?: MediatorType;
  /**
   * Which limit period is being removed
   */
  activePeriod?: LimitPeriodsEnum;
  /**
   * Function to invoke limits editing modal
   */
  onEditLimitClick: () => void;
  /**
   * Function to handle limit removing
   */
  onRemoveLimit: (event: React.FormEvent) => void;
  /**
   * Limit value which is being removed
   */
  limit?: number;
  loading: boolean;
};

export const AccountLimitsRemoveModal: FC<AccountLimitsRemoveModalProps> = ({
  onClose,
  isOpen,
  onEditLimitClick,
  onRemoveLimit,
  mediatorType,
  activePeriod,
  loading,
  limit,
}) => {
  const { t } = useTranslations();
  const title = getLimitProperties(mediatorType || MediatorType.Monetary, t).title;
  const lowerCasedTitle = title.toLowerCase();
  const formId = 'account-limits-remove-form';

  return (
    <PopUpHandler
      isOpen={isOpen}
      header={t('limits.remove-message', { title })}
      onClose={onClose}
      drawerProps={{
        backdropCanClose: true,
      }}
      headingProps={{ headingLevel: 5 }}
      modalProps={{
        'aria-label': 'Account Limits Modal',
      }}
      footer={
        <div className="account-limits-footer">
          <>
            <Button variant="secondary" type="button" size="large" onClick={onClose} disabled={loading}>
              {t('buttons.cancel.uppercase')}
            </Button>
            <Button type="submit" form={formId} size="large" isSubmitting={loading}>
              {t('buttons.confirm.uppercase')}
            </Button>
          </>
        </div>
      }
    >
      <form id={formId} onSubmit={onRemoveLimit}>
        <Paragraph size="md" noMargin>
          {t('limits.remove-time-exp', { lowerCasedTitle })}
        </Paragraph>
        <Paragraph size="md" noMargin>
          {t('limits.remove-caution-exp', { lowerCasedTitle })}
        </Paragraph>
        <div className="account-limits-remove-modal__limit-details">
          <span>{t('limits.limit-by-period', { title, period: getLimitNameByPeriod(activePeriod || '') })}</span>
          <span className="account-limits-remove-modal__limit-value">
            {formatCurrency(limit, 'auto', 'auto')}
            <Icon variant="Edit" onClick={onEditLimitClick} />
          </span>
        </div>
      </form>
    </PopUpHandler>
  );
};
