export const passiveOption = { passive: true };
export const optInString = 'optin';
export const PROFILE_TILE_NAME = 'My Profile';
export const xTenantId = 'betzone_uk';
export const MAX_DEPOSIT_ALLOWED = 50000;
export const countryCode = 'GB';
export const currencyCode = 'GBP';
export const language = 'en';
export const locale = 'en_GB';
export const localeCurrencyCode = 'en-GB';
export const UK__LIMITS_COOLOFF_PERIOD__HOURS = 24;
export const realityCheckMediatorType = 'casino';
export const btag = 'btag';

export enum OVERLAYS_ARIA_LABELS {
  SPIN_ZONE = 'spin-zone',
  PERFORMANCE_PROMPT = 'performance-prompt',
}

export enum GAME_CATEGORIES {
  RECOMMENDED = 'Recommended',
}

export const GAME_COOL_OFF_DURATION = 60000; // 300000ms = 5min
export const GAME_INFO_PRE_BREAK_COUNTDOWN = 60000; // 60000ms = 1min
