import { BudgetLimitsTransactionType } from '../../../types.__generated__';
import { IGroupedLimits, MediatorType } from '../types';

export enum ModalAction {
  Edit = 'edit',
  Remove = 'remove',
}

/**
 * Enum of player periods
 */
export enum LimitPeriodsEnum {
  daily = 'P1D',
  weekly = 'P7D',
  monthly = 'P30D',
}

export enum UserLimits {
  Deposit = 'Deposit',
  CasinoLoss = 'CasinoLoss',
  SportsLoss = 'SportsLoss',
}

export type UsedLimit = BudgetLimitsTransactionType.Deposit | BudgetLimitsTransactionType.Loss;

export type IGroupedLimitsTypes =
  | IGroupedLimits[MediatorType.Monetary]
  | IGroupedLimits[MediatorType.Casino]
  | IGroupedLimits[MediatorType.Sports];
