import { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import { PromotionTabsType } from '../../components/Layout/Promotions/PromotionsList/types';
import {
  FreeBetPromo,
  FreeSpinPromo,
  GigCommonPromo,
  GigPromo,
  ParsedPromo,
  UserGigQuery,
} from '../../components/Layout/Promotions/types';
import { useUserContext } from '../../providers/UserProvider';
import { UserProfileBonusStatus } from '../../types.__generated__';
import {
  BonusesGetAllQuery,
  useBonusesGetAllLazyQuery,
} from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/bonuses.query.generated';
import { APP_URLS, locale, xTenantId } from '../../consts';
import {
  UserBonusesQuery,
  useUserBonusesLazyQuery,
} from '../../libs/graphql/baseAppAPI/queries/__generated__/user-promo-collection.generated';
import { useBonusesGetPublicLazyQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/bonuses-public.query.generated';
import { Endpoint } from '../../models/api.model';
import { getImageUrl } from '../../utils/navigation';
import {
  useTagsObjectLazyQuery,
  TagsObjectQuery,
} from '../../libs/graphql/baseAppAPI/queries/__generated__/tags-get.query.generated';
import {
  UserFreeBetQuery,
  useUserFreeBetLazyQuery,
} from '../../libs/graphql/baseAppAPI/queries/__generated__/user-free-bet-get.generated';
import {
  UserFreeSpinQuery,
  useUserFreeSpinLazyQuery,
} from '../../libs/graphql/baseAppAPI/queries/__generated__/user-free-spin-get.generated';
import { today } from '../../components/Layout/Promotions/consts';

export const useFetchMergedPromotions = (
  promoType: PromotionTabsType
): { mergedFilteredPromotions: ParsedPromo[]; mergedHistoryPromotions: ParsedPromo[]; isPromosLoading: boolean } => {
  const isActivePromotions = promoType === PromotionTabsType.ACTIVE || promoType === PromotionTabsType.BONUS_DETAILS;

  const isUserAuthenticated = useAuth()?.isAuthenticated;

  const { userProfileId } = useUserContext();

  const [directusPromotions, setDirectusPromotions] = useState<ParsedPromo[] | null>(null);
  const [resultPromotions, setResultPromotions] = useState<ParsedPromo[] | null>(null);
  const [promotionsLoading, setPromotionsLoading] = useState<boolean>(true);
  const [userTagBonuses, setUserTagBonuses] = useState<(string | null | undefined)[] | undefined>([]);

  const onQueryError = (e: ApolloError): void => console.error(e.message);

  const handleDirectusPromos = (data: BonusesGetAllQuery): void =>
    setDirectusPromotions(
      data.bonus.map((promo) => ({
        promoCode: 'test-code',
        promoButtonText: 'CLAIM',
        promotionCTAType: 'DepositJourney',
        bonusId: String(promo.template_id),
        startDate: promo.start_date,
        endDate: promo.end_date,
        bonusType: String(promo.__typename),
        products:
          promo.collection?.translations?.map((translation) => translation?.display_name?.toLocaleLowerCase()) || [],
        title: String(promo.translations?.[0]?.title),
        description: String(promo.translations?.[0]?.subtitle),
        termsDescr: promo.translations?.[0]?.description,
        imgPath: getImageUrl(promo.translations?.[0]?.thumbnail?.filename_disk),
        imgAlt: String(promo.translations?.[0]?.thumbnail?.title),
        pageUrl: `${APP_URLS.promotions}/${promo.translations?.[0]?.slug}`,
        successCTAGameInformation: {
          gameId: '',
        },
      }))
    );

  const handleGigPromos = (data: UserGigQuery): void => {
    const gigBonuses = (data as UserBonusesQuery).userBonuses?.data;
    const gigFreeBets = (data as UserFreeBetQuery).userFreeBet?.data;
    const gigFreeSpins = (data as UserFreeSpinQuery).userFreeSpin?.data;

    const resGigBonuses = gigBonuses || gigFreeBets || gigFreeSpins || [];

    // First, handle the Gig bonuses merging as before
    const mergedGigPromotions: (ParsedPromo | undefined)[] =
      (resGigBonuses as GigCommonPromo[])
        .map((gigPromo: GigCommonPromo) => {
          const sdGigPromo = gigPromo as GigPromo;
          const freeSpin = gigPromo as FreeSpinPromo;
          const freeBet = gigPromo as FreeBetPromo;
          const sdGigPromoFreeBet = gigPromo as GigPromo | FreeBetPromo;

          const resPromoId = sdGigPromo?.bonusTemplateId || freeSpin?.freeSpinsTemplateId || freeBet?.freeBetTemplateId;

          // It's using as we can't currently rely on bonusTemplateId that's also placed for free bets and spins
          const cancellable = !(freeSpin?.freeSpinsTemplateId || freeBet?.freeBetTemplateId);

          const directusPromo = directusPromotions?.find((dirPromo: ParsedPromo) => dirPromo.bonusId === resPromoId);

          return directusPromo
            ? {
                ...directusPromo,
                id: sdGigPromo?.id || freeSpin?.freeSpinsTemplateId || freeBet?.freeBetTemplateId,
                status: gigPromo?.status,
                expiresOn: gigPromo?.expiresOn,
                createdOn: sdGigPromo?.createdOn,
                wageringRequirementAmount: sdGigPromo?.wageringRequirementAmount,
                wageredAmount: sdGigPromo?.wageredAmount || '',
                balanceAmount: sdGigPromoFreeBet?.balanceAmount,
                convertedAmount: sdGigPromoFreeBet?.convertedAmount,
                cancellable,
              }
            : undefined;
        })
        .filter((dirPromo: ParsedPromo | undefined) => dirPromo) || [];

    // Then, find any directus promos that match user bonus tags
    const tagMatchedPromotions = directusPromotions
      ?.filter((dirPromo) => userTagBonuses?.includes(dirPromo.bonusId))
      .map((dirPromo) => ({
        ...dirPromo,
        status: UserProfileBonusStatus.Claimed, // Set a default status for tag-matched promos,
      }));

    // Combine both sets of promotions
    // Use Set to ensure no duplicates if a promo appears in both lists
    const allPromotions = [...(resultPromotions || []), ...(tagMatchedPromotions || []), ...mergedGigPromotions];

    // Convert to Set and back to array to remove duplicates based on bonusId
    const uniquePromotions = Array.from(new Set(allPromotions.map((promo) => promo?.bonusId))).map((bonusId) =>
      allPromotions.find((promo) => promo?.bonusId === bonusId)
    );

    setResultPromotions(uniquePromotions as ParsedPromo[]);
  };

  const handleUserTags = (userTagsData: TagsObjectQuery): void => {
    setUserTagBonuses(
      userTagsData.tagsObject?.filter((item) => item.tag?.tagCategory?.name === 'Bonus').map((item) => item.tag?.id)
    );
  };

  const directusFetchData = {
    variables: {
      language: locale,
    },
    fetchPolicy: 'network-only' as WatchQueryFetchPolicy,
    notifyOnNetworkStatusChange: true,
    onCompleted: handleDirectusPromos,
    onError: onQueryError,
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  };

  const gigFetchData = {
    variables: {
      xTenantId,
      userProfileId,
    },
    fetchPolicy: 'network-only' as WatchQueryFetchPolicy,
    onCompleted: handleGigPromos,
    notifyOnNetworkStatusChange: true,
    onError: onQueryError,
    context: { endpoint: Endpoint.appAPI },
  };

  const [fetchBonusesPublic] = useBonusesGetPublicLazyQuery({
    ...directusFetchData,
    variables: {
      language: locale,
      date: today,
    },
  });
  const [fetchBonusesPrivate] = useBonusesGetAllLazyQuery(directusFetchData);

  const [fetchBonusesGig] = useUserBonusesLazyQuery(gigFetchData);
  const [fetchBonusesFreeBetGig] = useUserFreeBetLazyQuery(gigFetchData);
  const [fetchBonusesFreeSpinGig] = useUserFreeSpinLazyQuery(gigFetchData);

  const [fetchUserTags] = useTagsObjectLazyQuery({
    variables: {
      xTenantId,
      externalResource: 'UserProfileId',
      externalResourceId: userProfileId,
    },
    onCompleted: handleUserTags,
    notifyOnNetworkStatusChange: true,
    onError: onQueryError,
    context: { endpoint: Endpoint.appAPI },
  });

  const getMergedFilteredPromotions = (isHistory?: boolean): ParsedPromo[] | null | undefined => {
    const resultStatus: string[] = isActivePromotions
      ? [UserProfileBonusStatus.InProgress, UserProfileBonusStatus.Claimed, UserProfileBonusStatus.Assigned]
      : [UserProfileBonusStatus.Available];

    const nonHistoryStatuses: string[] = [
      UserProfileBonusStatus.InProgress,
      UserProfileBonusStatus.Claimed,
      UserProfileBonusStatus.Available,
    ];

    return isUserAuthenticated
      ? resultPromotions?.filter((promo) => {
          const promoStatus = String(promo.status);

          return isHistory ? !nonHistoryStatuses.includes(promoStatus) : resultStatus.includes(promoStatus);
        })
      : directusPromotions;
  };

  // It's a bit tricky for memoization as it doesn't work in a few random cases
  const mergedFilteredPromotions = getMergedFilteredPromotions();

  const mergedHistoryPromotions = useMemo(
    () => getMergedFilteredPromotions(true),
    [resultPromotions, directusPromotions, isUserAuthenticated, isActivePromotions, userTagBonuses]
  );

  useEffect(() => {
    (async (): Promise<void> => {
      if (isUserAuthenticated) {
        await fetchBonusesPrivate();
        await fetchUserTags();
      } else {
        await fetchBonusesPublic();
        setPromotionsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    directusPromotions?.length &&
      (async (): Promise<void> => {
        if (isUserAuthenticated) {
          await fetchBonusesFreeBetGig();
          await fetchBonusesFreeSpinGig();
          // Sequence is important here, so all bonuses should be fetched after free bonuses to not reload the whole results from them
          await fetchBonusesGig();
          setPromotionsLoading(false);
        }
      })();
  }, [directusPromotions]);

  return {
    mergedFilteredPromotions: mergedFilteredPromotions || [],
    mergedHistoryPromotions: mergedHistoryPromotions || [],
    isPromosLoading: promotionsLoading,
  };
};
