import React, { FunctionComponent, useEffect, useState } from 'react';
import { joinStrings } from '../../../utils/string';
import { Button } from '../../shared/Button';
import { Heading } from '../../shared/Heading';
import { Icon, IconVariant } from '../../shared/Icon';
import { Paragraph } from '../../shared/Paragraph';
import { PopUpHandler } from '../../shared/PopupHandler';
import { StepsType } from '../AccountClosure/consts';
import { TakeABreakForm, TakeABreakFormData } from './TakeABreakForm';
import { AccountPauseFormID } from './consts';
import './TakeABreak.scss';
import { useTranslations } from '../../../hooks/useTranslationsHelper';
import { Trans } from '../../shared/Trans';

export type TakeABreakRuleProps = {
  logo?: string;
  icon?: IconVariant;
  text: string;
};

export type TakeABreakPayload = {
  duration: number;
  reason: string;
  durationLabel?: string;
  accountStatus?: string;
};

export type TakeABreakProps = {
  /**
   * Whether the popup is open or closed
   */
  isOpen: boolean;
  /**
   * Whether buttons are disabled
   */
  disabled?: boolean;
  /**
   * Callback that's executed when the exclusion link is clicked
   */
  onExclusion: () => void;
  /**
   * Callback that's executed when the popup requests to close
   */
  onClose: () => void;
  /**
   * Callback that's executed when the user clicks on Submit
   */
  onSubmit: (payload: TakeABreakPayload) => void;
};

export const TakeABreakRule: FunctionComponent<TakeABreakRuleProps> = ({ logo, icon, text }) => (
  <li className="self-exclusion__rule">
    {icon ? (
      <Icon className="self-exclusion__rule-icon" variant={icon} />
    ) : (
      <img className="self-exclusion__rule-icon" src={logo} alt="rule-icon" />
    )}
    <Paragraph noMargin className="self-exclusion__rule-text" size="sm">
      {text}
    </Paragraph>
  </li>
);

export const TakeABreak: FunctionComponent<TakeABreakProps> = ({
  isOpen,
  disabled = false,
  onExclusion,
  onSubmit,
  onClose,
}) => {
  const [savedFormData, setSavedFormData] = useState<TakeABreakFormData>({
    duration: '',
    reason: '',
    durationLabel: '',
  });
  const { t } = useTranslations();
  const [step, setStep] = useState<StepsType>('form');

  const prevStepHandler = (): void => {
    setStep('form');
  };

  const confirmBtnHandler = (formData: TakeABreakFormData): void => {
    onSubmit && onSubmit({ ...formData, duration: +formData.duration });
  };

  function handleFormSubmit(formData: TakeABreakFormData): void {
    setSavedFormData(formData);
    setStep('confirmation');
  }

  useEffect(() => {
    if (!isOpen) {
      setSavedFormData({ duration: '', reason: '', durationLabel: '' });
    }
  }, [isOpen, step]);

  return isOpen ? (
    <PopUpHandler
      isOpen={isOpen}
      onClose={(): void => {
        onClose();
        setTimeout(() => setStep('form'), 325);
      }}
      header={t('take-a-break.title')}
      modalBodyOverflow={false}
      drawerProps={{ backdropCanClose: true }}
      headingProps={{ headingLevel: 5 }}
      modalProps={{
        className: 'takeabreak-modal',
        'aria-label': 'Take a break',
      }}
      footer={
        step === 'form' ? (
          <div className="takeabreak__footer">
            <Button className="takeabreak__secondary-btn" type="button" variant="secondary-alt" onClick={onClose}>
              {t('buttons.cancel.uppercase')}
            </Button>
            <Button variant="primary" type="submit" form={AccountPauseFormID}>
              {t('buttons.continue.uppercase')}
            </Button>
          </div>
        ) : (
          <div className="takeabreak__footer">
            <Button
              className="takeabreak__secondary-btn"
              variant="secondary-alt"
              type="button"
              iconPosition="left"
              icon={<Icon variant="ArrowLeft" />}
              onClick={prevStepHandler}
            >
              {t('buttons.back.uppercase')}
            </Button>
            <Button
              className="takeabreak__confirm-btn"
              variant="primary"
              type="button"
              form="takeabreak-form"
              onClick={(): void => {
                confirmBtnHandler(savedFormData);
              }}
            >
              {t('buttons.confirm.uppercase')}
            </Button>
          </div>
        )
      }
    >
      {step === 'form' && (
        <div className={joinStrings(['takeabreak', disabled && 'takeabreak--disabled'])}>
          <Paragraph size="sm">{t('take-a-break.rules.intro')}</Paragraph>

          <Heading noMargin level={6}>
            {t('take-a-break.rules.break-time.text')}
          </Heading>

          <Paragraph className="takeabreak__time" size="sm">
            {t('take-a-break.rules.start.text')}
          </Paragraph>

          <TakeABreakForm onFormSubmit={handleFormSubmit} defaultValues={savedFormData} />

          <Paragraph noMargin className="takeabreak__paragraph-2" size="sm">
            <Trans keyProp="take-a-break.reality-check-advertisement">
              <button onClick={onExclusion} className="takeabreak__link"></button>
            </Trans>
          </Paragraph>
        </div>
      )}
      {step === 'confirmation' && (
        <div className="takeabreak">
          <Paragraph size="sm">{t('take-a-break.points.title')}</Paragraph>
          <ul className="takeabreak__condition-list">
            <li>
              <Paragraph size="sm">
                <Trans keyProp="take-a-break.points.01" params={{ durationLabel: String(savedFormData.durationLabel) }}>
                  My account will be closed for <span className="u-text-highlight">{savedFormData.durationLabel}</span>
                  and this is not reversible due to German gambling law.
                </Trans>
              </Paragraph>
            </li>
            <li>
              <Paragraph size="sm">{t('take-a-break.points.02')}</Paragraph>
            </li>
            <li>
              <Paragraph size="sm">
                <Trans keyProp="take-a-break.points.03" params={{ durationLabel: String(savedFormData.durationLabel) }}>
                  If I wish to reactivate my account after{' '}
                  <span className="u-text-highlight">{savedFormData.durationLabel}</span>, I will need to call{' '}
                  <span className="u-text-highlight">Customer Support</span>.
                </Trans>
              </Paragraph>
            </li>
          </ul>
        </div>
      )}
    </PopUpHandler>
  ) : null;
};
