import noop from 'lodash/noop';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_URLS, realityCheckMediatorType, xTenantId } from '../../../consts';
import { useSnackbar } from '../../../hooks/snackbar';
import { useUserContext } from '../../../providers/UserProvider';
import { Button } from '../../shared/Button';
import { HowRealityChecksWorkModal } from './HowItWorksModal';
import { RealityChecksEditModal, RealityChecksFormData } from './RealityChecksEditModal';
import { RealityChecksSection } from './RealityChecksSection';
import './RealityChecks.scss';
import { useTranslations } from '../../../hooks/useTranslationsHelper';
import { useSetRealityCheckIntervalMutation } from '../../../libs/graphql/baseAppAPI/mutations/__generated__/reality-check-set.mutation.generated';
import { useGetRealityCheckConfigsLazyQuery } from '../../../libs/graphql/baseAppAPI/queries/__generated__/reality-check-configs-get.generated';
import { codedToISO8601 } from '../helper';

type RealityChecksProps = {
  isModal: boolean;
  loading?: boolean;
  onClose?: () => void;
  finallyReturnToGamblingControl?: boolean;
};
export const RealityChecks: FC<RealityChecksProps> = ({
  isModal,
  loading = false,
  onClose = noop,
  finallyReturnToGamblingControl = true,
}) => {
  const { t } = useTranslations();
  const { addSnack } = useSnackbar();
  const navigate = useNavigate();
  const { userProfileId } = useUserContext();
  const intervalRef = useRef<string | null>('');

  const listFromISO8601: Record<string, string> = {
    PT15M: t('reality-check.option.minutes', { time: 15 }),
    PT30M: t('reality-check.option.minutes', { time: 30 }),
    PT1H: t('reality-check.option.hour', { time: 1 }),
  };

  const [realityInterval, setRealityInterval] = useState<string | null>('');
  const [displayEditModal, setDisplayEditModal] = useState<boolean>(false);
  const [displayHowItWorks, setHowItWorks] = useState<boolean>(false);
  const [intervalWithLabel, setIntervalWithLabel] = useState<string>('');

  const [fetchRealityCheck, { data: realityCheckData, loading: getLoading, error: getError }] =
    useGetRealityCheckConfigsLazyQuery({
      variables: {
        xTenantId,
        userProfileId,
      },
      fetchPolicy: 'network-only',
    });

  const [setRealityCheckIntervalMutation, { data: saveData, loading: saveLoading, error: saveError }] =
    useSetRealityCheckIntervalMutation({
      variables: {
        xTenantId,
        userProfileId,
        realityCheckSetIntervalBodyRequest: {
          interval: realityInterval,
          mediatorType: realityCheckMediatorType,
        },
      },
      refetchQueries: ['GetRealityCheckConfigs'],
    });

  const dataError = saveData?.setRealityCheckInterval.error;

  const onEditModalClose = (): void => setDisplayEditModal(false);

  useEffect(() => {
    isModal && setDisplayEditModal(true);
    fetchRealityCheck();
  }, []);

  const onUndoReminderInterval = (): void => {
    setRealityInterval(intervalRef.current);
    intervalRef.current = '';
  };

  const onFormSubmit = (formData: RealityChecksFormData): void => {
    const intevalNumberString = String(formData.interval.match(/\d+/)?.[0]);
    const intervalISO8601 = codedToISO8601[intevalNumberString];

    setRealityInterval(intervalISO8601);
    onEditModalClose();

    if (realityInterval || realityInterval === null) intervalRef.current = realityInterval;
  };

  useEffect(() => {
    const intervalList = realityCheckData?.getRealityCheckConfigs.data;
    const slotsInterval = intervalList?.find((interval) => interval.mediatorType === realityCheckMediatorType);

    if (slotsInterval) {
      const interval = slotsInterval.interval;

      const decodedISO8601Interval = listFromISO8601[String(interval)];

      setIntervalWithLabel(decodedISO8601Interval);

      if (intervalRef.current === '') intervalRef.current = interval || null;
    }
  }, [realityCheckData]);

  useEffect(() => {
    (realityInterval || realityInterval === null) && setRealityCheckIntervalMutation();
  }, [realityInterval]);

  useEffect(() => {
    saveData &&
      intervalRef.current !== '' &&
      addSnack({
        type: 'success',
        theme: 'dark',
        message: t('reality-check.updated.text'),
        // eslint-disable-next-line react/display-name
        action: () => (
          <Button
            type="button"
            variant="text"
            size="medium"
            className="reality-checks__undo-btn"
            onClick={(): void => onUndoReminderInterval()}
          >
            {t('buttons.undo')}
          </Button>
        ),
      });
  }, [saveData]);

  useEffect(() => {
    (saveError || getError || dataError) &&
      addSnack({
        type: 'error',
        theme: 'dark',
        message: t('errors.try-later'),
      });
  }, [saveError, getError, dataError]);

  const onCloseHowItWorks = (): void => {
    setHowItWorks(false);
  };

  const onCloseEdit = (): void => {
    onClose();
    onEditModalClose();
    finallyReturnToGamblingControl && navigate(APP_URLS.myAccount.gamblingControls);
  };

  const onCancelEdit = (): void => {
    onEditModalClose();
    onClose();
    finallyReturnToGamblingControl && navigate(APP_URLS.myAccount.gamblingControls);
  };

  const onSubmitEdit = (formData: RealityChecksFormData): void => {
    onFormSubmit(formData);
    onClose();
    finallyReturnToGamblingControl && navigate(APP_URLS.myAccount.gamblingControls);
  };

  const onRealityCheckRemove = (): void => setRealityInterval(null);

  return (
    <div className="reality-checks">
      {!isModal && (
        <div>
          <RealityChecksSection
            intervalWithLabel={intervalWithLabel}
            openHowItWorksModal={(): void => setHowItWorks(true)}
            openEditModal={(): void => {
              setDisplayEditModal(true);
            }}
            loading={loading}
            onRemove={onRealityCheckRemove}
          />
          <HowRealityChecksWorkModal isOpen={displayHowItWorks} onClose={onCloseHowItWorks} />
        </div>
      )}
      <RealityChecksEditModal
        isOpen={displayEditModal}
        intervalWithLabel={intervalWithLabel}
        onClose={onCloseEdit}
        onFormSubmit={onSubmitEdit}
        onCancel={onCancelEdit}
        loading={loading || saveLoading || getLoading}
      />
    </div>
  );
};
