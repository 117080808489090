import React, { FC, useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { RegistrationContext, RegistrationContextProps } from './Registration.context';
import { defaultRegistrationFormState } from './config';
import { RegistrationStepIds } from './types';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import { Icon, IconVariant } from '../shared/Icon';
import { APP_URLS, xTenantId } from '../../consts';
import { useNavigate } from 'react-router-dom';
import { UrlOptions, isHidden } from '../../utils/set-url-storage';
import { joinStrings } from '../../utils/string';
import { Button } from '../shared/Button';
import { Header, Logo } from '../shared/Header';
import { RouterLink } from '../shared/Link';
import { IconButton } from '../shared/IconButton';
import { Card, CardContent } from '../shared/Card';
import { RegistrationContainer } from './RegistrationContainer';
import { Spinner } from '../shared/Spinner';
import { WelcomeBonusBanner } from '../WelcomeBonusBanner';
import { RegistrationSignInLink } from './RegistrationSignInLink/RegistrationSignInLink';
import { RegistrationFooter } from './RegistrationFooter';
import { BaseRegistrationStepConfig } from '../../hooks/registration';
import { useIsGeoLocationAllowedQuery } from '../../libs/graphql/baseAppAPI/queries/__generated__/ip-check.generated';
import { useDispatch } from 'react-redux';
import { setIsGeoLocationAllowed, setIsErrorDuringGeoCheck } from '../../state/app/reducers';
import { useTranslations } from '../../hooks/useTranslationsHelper';
import './styles/';

/**
 * Registration component.
 */
export const Registration: FC = () => {
  const { t } = useTranslations();
  const { isMobileDevice } = useDeviceInfo();
  const navigate = useNavigate();

  const isAuthenticated = false;
  const welcomeBonus = { isDataInitialised: true };

  const [isErrorStep, setErrorStep] = useState<boolean>(false);
  const [iconVariant, setIconVariant] = useState<IconVariant>('ArrowLeft');
  const [isSuccessStep, setSuccessStep] = useState<boolean>(false);
  const [showBackButton, setShowBackButton] = useState(true);
  const [shouldBackOpenPrompt, setShouldBackButtonOpenPrompt] = useState(true);
  const [abandonPromptOpen, setAbandonPromptOpen] = useState(false);
  const previousInput = useRef<string>('none');
  const lastFocused = useRef<string>('none');
  const abandonInFocusInputNameRef = useRef<string>();
  const inFocusFieldName = useRef<string>();
  const dispatch = useDispatch();

  const fieldShouldTriggersFormState: {
    [key: string]: boolean;
  } = useMemo(() => {
    return Object.keys(defaultRegistrationFormState).reduce((obj, key) => {
      return { ...obj, [key]: true };
    }, {});
  }, []);

  const firstFocus = useRef(true);

  const handleQuitRegistration = (): void => {
    navigate(APP_URLS.home);
  };

  const onBackButtonClick = (): void => {
    if (shouldBackOpenPrompt) {
      // Hacky way to retain in focus input name.
      abandonInFocusInputNameRef.current = inFocusFieldName.current;
      setAbandonPromptOpen(true);
    } else {
      // Post reg
      handleQuitRegistration();
    }
  };

  const handleOnClickHomeButton = useCallback(async () => {
    if (isErrorStep || isSuccessStep) {
      navigate(APP_URLS.home);
    } else {
      onBackButtonClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorStep, isSuccessStep]);

  const onFormControlFocus = (fieldName: string): void => {
    if (firstFocus.current) {
      firstFocus.current = false;
    }

    inFocusFieldName.current = fieldName;
    previousInput.current = lastFocused.current = fieldName;

    if (fieldShouldTriggersFormState[fieldName]) {
      fieldShouldTriggersFormState[fieldName] = false;
    }

    if (isMobileDevice && fieldName === 'password') {
      const passwordField = document.getElementsByName('password')[0];

      // Override default focus scroll so requirements can be seen
      setTimeout(() => {
        window.scrollTo({
          top: window.scrollY + passwordField.getBoundingClientRect().top - 80,
          behavior: 'smooth',
        });
      }, 500);
    }
  };

  const onFormControlBlur = (fieldName: string): void => {
    setTimeout(() => {
      if (inFocusFieldName.current === fieldName) {
        inFocusFieldName.current = undefined;
      }
    }, 500);
  };

  const onStepChange = (step: BaseRegistrationStepConfig): void => {
    if (step.id === RegistrationStepIds.Success) {
      setIconVariant('Close');
      setSuccessStep(true);
      setShouldBackButtonOpenPrompt(false);
    }

    if (step.id === RegistrationStepIds.Error || step.id === RegistrationStepIds.VerificationError) {
      setShouldBackButtonOpenPrompt(false);
      setErrorStep(true);
    }

    if (step.id === RegistrationStepIds.IpAddressError) {
      setShouldBackButtonOpenPrompt(false);
      setErrorStep(true);
    }
  };

  const contextValue: RegistrationContextProps = {
    abandonPromptOpen,
    onFormControlFocus,
    onFormControlBlur,
    onCloseAbandonPrompt: () => {
      abandonInFocusInputNameRef.current = undefined;
      setAbandonPromptOpen(false);
    },
    onConfirmAbandon: handleQuitRegistration,
    onPostRegistrationLimitStep: () => {
      setShowBackButton(true);
      setShouldBackButtonOpenPrompt(false);
    },
    onAddressSearchFocus: () => {
      const AUTO_COMPLETE = 'autoComplete';

      inFocusFieldName.current = AUTO_COMPLETE;
    },
    onAddressSearchBlur: () => {
      setTimeout(() => (inFocusFieldName.current = undefined), 500);
    },
  };

  const isHeaderHidden = isHidden(UrlOptions.hideHeader);

  const { loading } = useIsGeoLocationAllowedQuery({
    variables: {
      xTenantId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data?.isGeoLocationAllowed?.isAllowed) {
        dispatch(setIsGeoLocationAllowed(data?.isGeoLocationAllowed?.isAllowed));
        navigate(APP_URLS.home);
      }
    },
    onError: () => {
      dispatch(setIsErrorDuringGeoCheck(true));
      navigate(APP_URLS.home);
    },
  });

  useEffect(() => {
    // reset to init values
    dispatch(setIsGeoLocationAllowed(true));
    dispatch(setIsErrorDuringGeoCheck(false));
  }, []);

  return (
    <div
      className={joinStrings([
        'registration',
        !isMobileDevice && 'gradient-cold',
        isHeaderHidden && 'registration--header-hidden',
      ])}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          {!isHeaderHidden ? (
            <div className="registration__header">
              <header className="registration__desktop-header">
                {showBackButton && (
                  <Button
                    iconPosition="left"
                    icon={<Icon variant={iconVariant} />}
                    type="button"
                    size="large"
                    variant="text-alt"
                    onClick={handleOnClickHomeButton}
                  >
                    {isErrorStep ? t('buttons.home') : isSuccessStep ? t('buttons.close') : t('buttons.back')}
                  </Button>
                )}
              </header>

              <Header
                sticky
                left={
                  <div
                    className={joinStrings(['registration__logo', !showBackButton && 'registration__logo--no-click'])}
                  >
                    <RouterLink to={APP_URLS.home} aria-label="header-logo">
                      <Logo />
                    </RouterLink>
                  </div>
                }
                right={
                  showBackButton && (
                    <IconButton
                      aria-label="registration-back-link"
                      onClick={onBackButtonClick}
                      icon={<Icon variant="CloseIcon" />}
                      size="large"
                      className="registration__close"
                    />
                  )
                }
              />
            </div>
          ) : null}
          <RegistrationContext.Provider value={contextValue}>
            <div className="registration__body">
              <Card>
                <CardContent>
                  <RegistrationContainer onStepChange={onStepChange} />
                </CardContent>
              </Card>
              <div className="registration__content">
                {!isMobileDevice && (
                  <div className="registration__logo">
                    <Logo />
                  </div>
                )}
                {welcomeBonus.isDataInitialised ? (
                  // Get WelcomeBonusBanner content from CMS
                  <WelcomeBonusBanner termsContent="" title="" subTitle="" />
                ) : (
                  <Spinner />
                )}
                {isMobileDevice && !isAuthenticated && (
                  <RegistrationSignInLink className="registration__sign-container" />
                )}

                <RegistrationFooter />
              </div>
            </div>
          </RegistrationContext.Provider>
        </>
      )}
    </div>
  );
};
