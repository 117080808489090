import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserBonusesQueryVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  userProfileId: Types.Scalars['ID']['input'];
  pageLimit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type UserBonusesQuery = { __typename?: 'Query', userBonuses: { __typename?: 'PagedUserProfileBonus', data?: Array<{ __typename?: 'UserProfileBonus', bonusTemplateId?: string | null, status?: Types.UserProfileBonusStatus | null, expiresOn?: string | null, createdOn?: string | null, balanceAmount?: number | null, convertedAmount?: number | null, wageredAmount?: number | null, wageringRequirementAmount?: number | null, id?: string | null } | null> | null } };


export const UserBonusesDocument = gql`
    query UserBonuses($xTenantId: ID!, $userProfileId: ID!, $pageLimit: Int) {
  userBonuses(
    xTenantID: $xTenantId
    userProfileId: $userProfileId
    pageLimit: $pageLimit
  ) {
    data {
      bonusTemplateId
      status
      expiresOn
      createdOn
      balanceAmount
      convertedAmount
      wageredAmount
      wageringRequirementAmount
      id
    }
  }
}
    `;

/**
 * __useUserBonusesQuery__
 *
 * To run a query within a React component, call `useUserBonusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBonusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBonusesQuery({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      userProfileId: // value for 'userProfileId'
 *      pageLimit: // value for 'pageLimit'
 *   },
 * });
 */
export function useUserBonusesQuery(baseOptions: Apollo.QueryHookOptions<UserBonusesQuery, UserBonusesQueryVariables> & ({ variables: UserBonusesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserBonusesQuery, UserBonusesQueryVariables>(UserBonusesDocument, options);
      }
export function useUserBonusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserBonusesQuery, UserBonusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserBonusesQuery, UserBonusesQueryVariables>(UserBonusesDocument, options);
        }
export function useUserBonusesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserBonusesQuery, UserBonusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserBonusesQuery, UserBonusesQueryVariables>(UserBonusesDocument, options);
        }
export type UserBonusesQueryHookResult = ReturnType<typeof useUserBonusesQuery>;
export type UserBonusesLazyQueryHookResult = ReturnType<typeof useUserBonusesLazyQuery>;
export type UserBonusesSuspenseQueryHookResult = ReturnType<typeof useUserBonusesSuspenseQuery>;
export type UserBonusesQueryResult = Apollo.QueryResult<UserBonusesQuery, UserBonusesQueryVariables>;