import { ProductName, ProductType, PromotionTabsType } from '../Layout/Promotions/PromotionsList/types';
import { LinkTargetType, NavigationLinkIcons } from './NavigationLink';
import { CategoryOfUsers, Promotions } from './types';
import bowenKnotBadge from '../../assets/Promotions/bowen-knot.png';
import giftIcon from '../../assets/Promotions/gift-icon.png';
import userIcon from '../../assets/Promotions/user-icon.png';
import bowenKnotBadgeActive from '../../assets/Promotions/bowen-knot-active.png';
import giftIconActive from '../../assets/Promotions/gift-icon-active.png';
import userIconActive from '../../assets/Promotions/user-icon-active.png';
import { APP_URLS } from '../../consts';

const { AllUser } = CategoryOfUsers;

// Default tabs and categories
export const promotionsNav: Promotions = {
  tabs: [
    {
      id: ProductType.ALL,
      name: ProductName.ALL,
    },
    {
      id: ProductType.CASINO,
      name: ProductName.CASINO,
    },
    {
      id: ProductType.SPORT,
      name: ProductName.SPORT,
    },
  ],
  categories: [
    {
      authenticated: true,
      unauthenticated: false,
      type: PromotionTabsType.ACTIVE,
      label: 'Active',
      iconLink: userIcon,
      activeIconLink: userIconActive,
    },
    {
      authenticated: true,
      unauthenticated: false,
      type: PromotionTabsType.PROMOTIONS,
      label: 'Promotions',
      iconLink: giftIcon,
      activeIconLink: giftIconActive,
    },
    {
      authenticated: true,
      unauthenticated: false,
      type: PromotionTabsType.BONUS_DETAILS,
      label: 'Bonus Detail',
      iconLink: bowenKnotBadge,
      activeIconLink: bowenKnotBadgeActive,
    },
  ],
};

export const defaultPrimaryMenuItems = [
  {
    name: 'Sports',
    linkText: 'Sports',
    to: APP_URLS.home,
    icon: NavigationLinkIcons.Ball,
    active: false,
    dividerAfter: false,
    linkTarget: LinkTargetType.SameWindow,
    showForUsers: AllUser,
  },
  {
    name: 'Slots',
    linkText: 'Slots',
    to: `${APP_URLS.gameLobby.main}/slots`,
    icon: NavigationLinkIcons.Cherry,
    active: false,
    dividerAfter: false,
    linkTarget: LinkTargetType.SameWindow,
    showForUsers: AllUser,
  },
  {
    name: 'Live Casino',
    linkText: 'Live Casino',
    to: `${APP_URLS.gameLobby.main}/live-casino`,
    icon: NavigationLinkIcons.Casino,
    active: false,
    dividerAfter: false,
    linkTarget: LinkTargetType.SameWindow,
    showForUsers: AllUser,
  },
  {
    name: 'Virtual Games',
    linkText: 'Virtual Games',
    to: `${APP_URLS.gameLobby.main}/virtual-games`,
    icon: NavigationLinkIcons.Jackpot,
    active: false,
    dividerAfter: false,
    linkTarget: LinkTargetType.SameWindow,
    showForUsers: AllUser,
  },
  {
    name: 'Promotions',
    linkText: 'Promotions',
    to: APP_URLS.promotions,
    icon: NavigationLinkIcons.Gift,
    active: false,
    dividerAfter: true,
    linkTarget: LinkTargetType.SameWindow,
    showForUsers: AllUser,
  },
];
