import React, { FC } from 'react';
import { Button, ButtonSize, ButtonVariant } from '../Button';
import { joinStrings } from '../../../utils/string';
import { useTranslations } from '../../../hooks/useTranslationsHelper';
import { isWhiteListPage } from '../../../utils/cookies';
import './NotificationBar.scss';

export type ButtonConfig = {
  text: string;
  size: ButtonSize;
  onClick?: () => void;
  buttonVariant?: ButtonVariant;
  type: 'button' | 'submit' | 'reset';
};

export interface NotificationBarProps {
  className?: string;
  imgNode?: React.ReactNode;
  buttonClass?: string;
  message?: string | React.ReactNode;
  textClass?: string;
  buttonText?: string;
  onClick?: () => void;
  isShow?: boolean;
  disableSiteContent?: boolean;
  SecondaryButton?: ButtonConfig;
}

export const NotificationBar: FC<NotificationBarProps> = ({
  className,
  imgNode,
  message,
  buttonClass,
  buttonText,
  onClick,
  isShow,
  SecondaryButton,
  disableSiteContent,
}) => {
  const { t } = useTranslations();

  return isShow ? (
    <div className={joinStrings(['notification-wrapper', className])}>
      {disableSiteContent && (
        <div
          className={joinStrings([
            'notification-wrapper--disabled-content',
            isWhiteListPage() && 'notification-wrapper--disabled-content-soft',
          ])}
          aria-hidden="true"
        />
      )}
      <div className="notification-wrapper__bar">
        <div className="notification-wrapper__content">
          {imgNode && <div className="notification-wrapper__img">{imgNode}</div>}
          {message}
        </div>

        <div className="notification-wrapper__buttons">
          {SecondaryButton && (
            <Button
              size={SecondaryButton.size}
              type={SecondaryButton.type}
              onClick={SecondaryButton.onClick}
              variant={SecondaryButton.buttonVariant}
              className="notification-wrapper__secondary-btn"
            >
              {t('cookie-banner-secondary-btn-text')}
            </Button>
          )}

          {buttonText && (
            <Button
              className={joinStrings(['notification-wrapper__button', buttonClass])}
              type="button"
              variant="primary"
              onClick={onClick}
            >
              {t('cookie-banner-button-text')}
            </Button>
          )}
        </div>
      </div>
    </div>
  ) : null;
};
