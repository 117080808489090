import { FC, useEffect } from 'react';
import { useSignIn } from '../../hooks/auth';

export const Login: FC = () => {
  const signIn = useSignIn();

  useEffect(() => {
    signIn();
  }, []);

  return null;
};
