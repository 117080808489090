import noop from 'lodash/fp/noop';
import React, { FC, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GAME_CATEGORIES, locale } from '../../../consts';
import { Orientation, useDeviceInfo, useOrientation } from '../../../hooks/useDeviceInfo';
import { useFetchGames } from '../../../hooks/useFetchGames';
import { selectDisabledGamesData } from '../../../state/game';
import { transformCategoriesGameData, transformGameData } from '../../../utils/games/games.utils';
import { joinStrings } from '../../../utils/string';
import { Paragraph } from '../../shared/Paragraph';
import { Spinner } from '../../shared/Spinner';
import { Carousel } from '../Carousel';
import { GameCardContainerType } from '../GameCard/GameCard.types';
import { SimpleGameCard } from '../GameCard/SimpleGameCard';
import { getGameCarouselConfig } from '../GameInfo/GamesCarouselConfig';
import { Game, PromotionGames } from '../games.model';
import { mapGamesToSimpleSlides } from './mapGamesToSimpleSlides';
import { useTranslations } from '../../../hooks/useTranslationsHelper';
import './styles';

interface RecommendedGameCarouselProps {
  title?: string;
  /**
   * Display a carousel or .
   */
  isCarousel?: boolean;
  /**
   * Card container type.
   */
  containerType?: GameCardContainerType;
  /**
   * Show the navigation arrows that appear on the sides of the carousal.  *Will override to "false" for mobile devices.*
   */
  showArrows?: boolean;
  /**
   * Show the navigation dots that are in the top right corner.
   */
  showDots?: boolean;
  /**
   * Number of slides drawn in the carousel per size configuration.  Breakpoints are set in AppConfig.
   */
  visibleConfig?: {
    wide: number;
    desktop: number;
    tablet: number;
    mobile: number;
  };
  onClose?: () => void;
  /**
   * External eligible games which are comming from bonus slug endpoint
   */
  eligibleGames?: PromotionGames;
}

export const RecommendedGameCarousel: FC<RecommendedGameCarouselProps> = ({
  isCarousel = true,
  title,
  containerType = GameCardContainerType.Wide,
  showArrows = false,
  showDots = false,
  visibleConfig = {
    wide: 4,
    desktop: 3,
    tablet: 3,
    mobile: 2,
  },
  onClose = noop,
  eligibleGames,
}): JSX.Element => {
  const { t } = useTranslations();
  const { slidesCount } = getGameCarouselConfig(t);
  const isMobile = useDeviceInfo().isMobileDevice;
  const isLandscape = useOrientation() === Orientation.LANDSCAPE;
  const { areGamesDisabled } = useSelector(selectDisabledGamesData);

  const isEligibleGames = eligibleGames?.length;

  const [games, setGames] = useState<Array<Game>>([]);
  const [slidesRecommendedGames, setSlidesRecommendedGames] = useState<(JSX.Element | null)[]>();
  const [resEligibleGames, setResEligibleGames] = useState<(JSX.Element | null)[]>();

  const slides = isEligibleGames ? resEligibleGames : slidesRecommendedGames;

  const { isFetchedGamesByCategoryDataLoading, fetchGamesByCategory } = useFetchGames({
    language: locale,
    count: slidesCount,
    category: GAME_CATEGORIES.RECOMMENDED,
    onGamesGetByCategoryComplete: (data) => {
      const transformedData = transformCategoriesGameData(data.categories);

      if (transformedData.length) {
        setGames(transformedData);
        setSlidesRecommendedGames(mapGamesToSimpleSlides(transformedData, onClose, containerType));
      }
    },
  });

  useLayoutEffect(() => {
    if (isEligibleGames) {
      const initEligibleGames = eligibleGames?.map((item) => transformGameData(item?.game_id)).filter(Boolean) || [];

      setResEligibleGames(mapGamesToSimpleSlides(initEligibleGames, onClose, containerType));
    } else {
      (async (): Promise<void> => {
        await fetchGamesByCategory();
      })();
    }
  }, [isEligibleGames]);

  return (
    <div className="recommended-game-carousel">
      {isFetchedGamesByCategoryDataLoading ? (
        <Spinner />
      ) : isCarousel ? (
        <Carousel
          adjustSlidesOnResize
          slides={slides}
          title={title || t('game.recommended.label')}
          subtitle={''}
          showArrows={showArrows}
          showDots={showDots}
          visibleConfig={visibleConfig}
          disableSlideContent={areGamesDisabled}
          emptyTitle={t('promotions.slides.empty-title')}
        />
      ) : (
        <div className="recently-played">
          <Paragraph className="recently-played__text" noMargin>
            {title}
          </Paragraph>
          <div className="recently-played__game-cards">
            {games.length ? (
              games.map((game) => (
                <div
                  key={game.gameId}
                  className={joinStrings([
                    'recently-played__game-card-wrap',
                    isMobile && 'recently-played__game-card-wrap--mobile',
                    isLandscape && 'recently-played__game-card-wrap--landscape',
                  ])}
                >
                  <SimpleGameCard onClose={onClose} game={game} />
                </div>
              ))
            ) : (
              <Paragraph className="recently-played__text" noMargin>
                {t('promotions.slides.empty-title')}
              </Paragraph>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
