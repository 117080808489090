import React from 'react';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { useUserValidationConstraints } from '../../../Registration/const';
import { FormFieldType } from '../../../shared/Form';
import { Icon } from '../../../shared/Icon';
import { countryCodeOptions } from '../../../shared/TelephoneInput/countryCodes';
import { AccountFieldMap } from '../../types';

/**
 * Get a key value map of player form field configurations.
 * @param config
 */
export const GetAccountFieldConfig = (): AccountFieldMap => {
  const { t } = useTranslations();
  const userValidationConstraints = useUserValidationConstraints();

  return {
    email: {
      type: FormFieldType.TextInput,
      name: 'email',
      label: t('my-account.home.my-profile.email-address'),
      rules: userValidationConstraints.email.rules as never,
      props: {
        autoComplete: 'email',
        type: 'email',
        autoCapitalize: 'off',
        inputMode: 'email',
      },
    },
    givenName: {
      type: FormFieldType.TextInput,
      name: 'givenName',
      label: t('register.steps.personal.first-name-input.label'),
      rules: {
        maxLength: {
          value: 40,
          message: t('validation.first-name.less-symbols'),
        },
        minLength: {
          value: 2,
          message: t('validation.first-name.more-symbols'),
        },
        required: t('validation.field-required'),
        pattern: {
          value: userValidationConstraints.lastnamePattern,
          message: t('validation.first-name.allowed'),
        },
      },
      props: { autoComplete: 'given-name', autoCorrect: 'off' },
    },
    familyName: {
      type: FormFieldType.TextInput,
      name: 'familyName',
      label: t('register.steps.personal.last-name-input.label'),
      rules: {
        maxLength: {
          value: 40,
          message: t('validation.last-name.less-symbols'),
        },
        minLength: {
          value: 2,
          message: t('validation.last-name.more-symbols'),
        },
        required: t('validation.field-required'),
        pattern: {
          value: userValidationConstraints.lastnamePattern,
          message: t('validation.last-name.allowed'),
        },
      },
      props: { autoComplete: 'family-name', autoCorrect: 'off' },
    },
    birthDate: {
      type: FormFieldType.DateOfBirthInput,
      name: 'birthDate',
      label: t('my-account.home.my-profile.date-of-birth'),
      placeholder: 'YYYY-MM-DD',
      dateMask: '9999-99-99',
      dateFormat: 'yyyy-MM-dd',
      rules: userValidationConstraints.dateOfBirth.rules,
      props: { autoComplete: 'bday', type: 'text', inputMode: 'numeric' },
    },
    addrStreetAddress: {
      type: FormFieldType.TextInput,
      name: 'addrStreetAddress',
      label: t('my-account.home.my-profile.address'),
      rules: {
        required: t('validation.required'),
        pattern: {
          value: userValidationConstraints.addressLinePattern,
          message: t('validation.address.invalid'),
        },
      },
    },
    mobileNumber: {
      type: FormFieldType.TelephoneInput,
      label: t('my-account.home.my-profile.mobile-num'),
      name: 'mobileNumber',
      rules: userValidationConstraints.telephone,
      props: {
        maskedInputProps: {
          name: 'countryCode',
          label: t('input.country-code'),
          mask: '',
          autoComplete: 'tel-national',
          type: 'tel',
          theme: 'dark',
        },
        selectProps: {
          label: t('my-account.home.my-profile.country-code'),
          name: 'mobilePrefix',
          options: countryCodeOptions,
          theme: 'dark',
        },
      },
    },
    toggleAllMarketing: {
      type: FormFieldType.Switch,
      name: 'toggleAllMarketing',
      label: t('my-account.home.settings.communication.all'),
    },
    optInEmail: {
      type: FormFieldType.Switch,
      name: 'optInEmail',
      label: t('my-account.home.settings.communication.email'),
      props: {
        iconLabel: <Icon variant="Email" />,
      },
    },
    optInSms: {
      type: FormFieldType.Switch,
      name: 'optInSms',
      label: t('my-account.home.settings.communication.sms'),
      props: {
        iconLabel: <Icon variant="Sms" />,
      },
    },
    optInTelephone: {
      type: FormFieldType.Switch,
      name: 'optInTelephone',
      label: t('my-account.home.settings.communication.phone'),
      props: {
        iconLabel: <Icon variant="Phone" />,
      },
    },
  };
};
