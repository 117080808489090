import { ITransformedContentPageData } from './types';
import { ContentPageGetBySlugQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/content-page-get-by-slug.query.generated';
import { filterSpecialHTMLCharacters } from '../string';

export const transformContentPageData = (data: ContentPageGetBySlugQuery | undefined): ITransformedContentPageData => {
  const documentContent = data?.documents?.[0]?.revisions?.[0]?.document_revisions_id?.document_content?.[0];
  const cleanedContent = filterSpecialHTMLCharacters(String(documentContent?.content || ''));

  return {
    title: documentContent?.title || '',
    content: cleanedContent,
  };
};
