import React, { FC } from 'react';
import { AccountTile } from '../../../Account/MyAccount/AccountTile';
import { AccountTileListItem } from '../../../Account/MyAccount/AccountTileListItem';
import { Button } from '../../../shared/Button';
import { Heading } from '../../../shared/Heading';
import { Paragraph } from '../../../shared/Paragraph';
import { AccountTileRealityCheckItem } from './AccountTileRealityCheckItem';
import './RealityChecksSection.scss';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

interface RealityChecksSectionProps {
  /**
   * Optional to add a spinner before loading a component.
   */
  loading: boolean;
  /**
   * Value of the Select element in the format 'time text' (ex.: 15 minutes, 1 hours)
   */
  intervalWithLabel?: string;
  /**
   * Callback that's executed when the user opens the HowItWorks modal
   */
  openHowItWorksModal: () => void;
  /**
   * Callback that's executed when the user opens the EditModal
   */
  openEditModal: () => void;
  /**
   * Calback that's executed on remove icon click
   */
  onRemove: () => void;
}

export const RealityChecksSection: FC<RealityChecksSectionProps> = ({
  loading,
  intervalWithLabel,
  openHowItWorksModal,
  openEditModal,
  onRemove,
}) => {
  const { t } = useTranslations();
  const text = intervalWithLabel || t('reality-check.limit.add.text');

  return (
    <div className="reality-checks-section">
      <Heading level={6}>{t('reality-check.title')}</Heading>

      <Paragraph className="reality-checks-section__subtext" size="xs" noMargin>
        {t('reality-check.limit.info')}
        <Button type="button" variant="text" className="reality-checks-section__link" onClick={openHowItWorksModal}>
          {t('reality-check.how-it-works.title')}
        </Button>
      </Paragraph>

      <AccountTile noPadding>
        {intervalWithLabel ? (
          <AccountTileRealityCheckItem text={text} onOpenEditModal={openEditModal} onRemove={onRemove} />
        ) : (
          <AccountTileListItem loading={loading} text={text} icon="Plus" iconAlign="right" onClick={openEditModal} />
        )}
      </AccountTile>
    </div>
  );
};
