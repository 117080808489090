import qs from 'qs';
import {
  LoqateFindQuery,
  LoqateFindResult,
  LoqateResults,
  LoqateRetrieveItem,
  LoqateReverseGeocodeResult,
  LoqateGeocodeItem,
} from './loqate.types';
// TODO: update loqate params
export const LOQATE_BASE_URL = 'https://api.addressy.com/Capture/Interactive';
export const LOQATE_RETRIEVE_URL = `${LOQATE_BASE_URL}/Retrieve/v1/json3.ws`;
export const LOQATE_FIND_URL = `${LOQATE_BASE_URL}/Find/v1.1/json3.ws`;
export const LOQATE_GEOCODING_BASE_URL = 'https://api.addressy.com/Geocoding/International/ReverseGeocode/v2/';
export const LOQATE_GEOCODING_REVERSE_URL = `${LOQATE_GEOCODING_BASE_URL}/json3.ws`;
export const loqateCountryCode = 'GB';
const LOQATE_GEOLOCATION_URL = 'https://api.addressy.com/Geocoding/International/Geocode/v1.1/json3.ws';
const loqateApiKey = process.env.REACT_APP_LOQATE_API_KEY;

if (!loqateApiKey) {
  console.error('Loqate API Key env variable not configured');
}

function makeRequest<T>(url: string, params: Record<string, unknown>, method = 'GET'): Promise<T> {
  const queryString = qs.stringify(
    { ...params, Key: loqateApiKey },
    {
      arrayFormat: 'comma',
    }
  );
  const request = new Request(`${url}?${queryString}`, { method });

  return fetch(request).then<T>((response) => response.json());
}

/**
 * Find full address details by id which can be found in search results
 * @param id
 */
export const retrieveAddress = (id: string): Promise<LoqateResults<LoqateRetrieveItem>> =>
  makeRequest(LOQATE_RETRIEVE_URL, {
    Id: id,
  });

/**
 * Search loqate API for an address by free text value
 * @param query Loqate query values
 */
export const searchAddress = (query: LoqateFindQuery): Promise<LoqateResults<LoqateFindResult>> => {
  const { text, countries = loqateCountryCode, containerId, language, limit } = query;

  return makeRequest<LoqateResults<LoqateFindResult>>(LOQATE_FIND_URL, {
    Text: text,
    Countries: countries,
    Container: containerId,
    limit,
    language,
  });
};

export const retrieveAddressByGeolocation = (
  latitude: number,
  longitude: number
): Promise<{ Items: Array<LoqateReverseGeocodeResult> }> =>
  makeRequest(LOQATE_GEOCODING_REVERSE_URL, {
    Latitude: latitude,
    Longitude: longitude,
  });

/**
 * Find geolocation data by location which can be found in search results
 * @param location
 * @param country
 */
export const retrieveGeolocation = (location: string, country = 'uk'): Promise<LoqateResults<LoqateGeocodeItem>> =>
  makeRequest(LOQATE_GEOLOCATION_URL, {
    Location: location,
    Country: country,
  });
