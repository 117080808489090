import noop from 'lodash/noop';
import React, { FunctionComponent, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import { joinStrings } from '../../../../utils/string';
import { AccountPageWrapper } from '../../../Account/MyAccount/AccountPageWrapper';
import { Heading } from '../../../shared/Heading';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { CookiesPreferencesMode } from '../types';
import './styles';

type CookiesPreferencesPortalProps = {
  /**
   * Callback that's executed when
   * the user presses the close button
   */
  onClose?: () => void;
  /**
   * Callback that's executed when
   * the user presses the back button
   */
  onBackBtnClick?: () => void;
  /**
   * Indicates whether
   * detail page is displayed
   */
  isDetailPageDisplayed: boolean;
  /**
   * Account page wrapper title
   */
  cookieDetailedPageTitle?: string;
  children?: React.ReactNode;

  footer?: React.ReactNode;
} & CookiesPreferencesMode;

export const CookiesPreferencesPortal: FunctionComponent<CookiesPreferencesPortalProps> = ({
  mode = 'prompt',
  children,
  onClose,
  cookieDetailedPageTitle,
  onBackBtnClick,
  isDetailPageDisplayed,
  footer,
}) => {
  const auth = useAuth();
  const elementToFocusOnOpen = useRef<HTMLButtonElement>(null);
  const userAuthenticated = auth?.isAuthenticated;
  const cookieHeading = cookieDetailedPageTitle;

  return (
    <>
      {mode === 'account' && (
        <AccountPageWrapper
          onClose={onClose || noop}
          onBackBtnClick={onBackBtnClick}
          elementToFocusOnOpen={elementToFocusOnOpen}
          className={joinStrings([
            'cookies-preferences-portal__account',
            !isDetailPageDisplayed && 'cookies-preferences-portal__account--not-full-height',
          ])}
        >
          <div className="cookies-preferences-portal__cookie-content">
            <Heading level={3}>{cookieHeading}</Heading>
            {children}
          </div>
        </AccountPageWrapper>
      )}
      {mode === 'prompt' && (
        <PopUpHandler
          isOpen
          onClose={onClose || noop}
          header={cookieHeading}
          headingProps={{ headingLevel: 5 }}
          modalProps={{
            'aria-label': cookieHeading,
            canDismiss: userAuthenticated,
            className: 'modal-cookie-preference',
          }}
          modalBodyOverflow={false}
          drawerProps={{
            'aria-label': cookieHeading,
            showCloseBtn: false,
            showTouchPad: userAuthenticated,
            backdropCanClose: userAuthenticated,
            className: 'drawer-cookie-preference',
          }}
          elementToFocusOnOpen={elementToFocusOnOpen}
          footer={footer}
        >
          <div className="cookies-preferences-portal__prompt">{children}</div>
        </PopUpHandler>
      )}
    </>
  );
};
