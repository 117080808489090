import axios from 'axios';
import { language, xTenantId } from '../../consts';
import { getToken } from '../../utils/auth';

const requestUrl = process.env.REACT_APP_BASE_AUTH_URL;

interface RetrieveGameLaunchUrlPayload {
  userProfileId: string;
  gameExternalId: string;
  currencyCode: string;
  gameProvider: string;
}

export const retrieveGameLaunchUrl = async ({
  userProfileId,
  gameExternalId,
  currencyCode = 'GBP',
  gameProvider,
}: RetrieveGameLaunchUrlPayload): Promise<{ url: string }> => {
  const token = getToken();
  const config = {
    headers: {
      authorization: token ? `Bearer ${token}` : null,
      'X-Tenant-Id': xTenantId,
    },
  };
  const payload = { currencyCode, languageCode: language, gameExternalId };

  const result = await axios.post(
    `${requestUrl}/${gameProvider}/v1/user-profiles/${userProfileId}/launch`,
    payload,
    config
  );

  return result?.data;
};
