import { APP_URLS } from '../consts';
import { storageConsent } from './storage';

export const isCookieFalsy = (): boolean => {
  const storageConsentData = (storageConsent.get() as Record<string, string>)?.consent || {};

  return Object.values(storageConsentData).every((cookieType) => !cookieType);
};

export const isWhiteListPage = (): boolean => {
  const { pathname } = window.location;

  return pathname.includes(APP_URLS.cookiePolicy) || pathname.includes(APP_URLS.privacyPolicy);
};
