import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import { Carousel } from '../Games/Carousel';
import { Chip } from '../shared/Chip';
import { HeroBanner } from '../HeroBanner';
import { Heading } from '../shared/Heading';
import { InnerHTML } from '../shared/InnerHTML';
import { RouterLink } from '../shared/Link';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Link } from '../shared/Link';
import { MediaBreakpoint, useBreakpointInfo, useDeviceInfo } from '../../hooks/useDeviceInfo';
import { ButtonSize } from '../shared/Button';
import { checkIsUrlExternal, handleLinkSlashSymbol } from '../../utils/links-helper/links-helper';
import { hashIsValidAsScrollTarget, tryScrollingToElementWithId } from '../../utils/scroll-to-element';
import { joinStrings } from '../../utils/string';
import { Game } from '../Games/games.model';
import { useNavigate, useLocation } from 'react-router-dom';
import { storageRoute } from '../../utils/storage';
import { APP_URLS } from '../../consts';
import './HeroBannerCarousel.scss';

export enum ButtonLinkType {
  PAGE = 'PAGE',
  GAME = 'GAME',
  PROMO = 'PROMO',
}

type PromotionSlide = {
  heading: string;
  subHeading: string;
  buttonEnabled: boolean;
  buttonShown?: boolean;
  buttonLabel?: string;
  buttonLink?: string;
  buttonLinkType?: ButtonLinkType;
  imgSrc?: string;
  mobileImgSrc?: string;
  bonusId?: number;
  imgAlt?: string;
  footer: string;
};

export type CarouselConfigProps = {
  game?: Game;
  friendlyUrlName?: string;
  forceUpperCaseHeading?: boolean;
  forceUpperCaseSubHeading?: boolean;
  isDisabledSlideContent?: boolean;
  defaultNumberOfBanners?: number;
  children?: React.ReactNode;
};

export type HeroBannerSlide = {
  showWithoutPromoPage?: boolean;
  promotionSlide?: PromotionSlide;
  heading?: string;
  subHeading?: string;
  buttonEnabled?: boolean;
  buttonShown?: boolean;
  buttonLabel?: string;
  buttonLink?: string;
  buttonLinkType?: ButtonLinkType;
  routerLink?: boolean;
  footer?: string;
  imgSrc?: string;
  mobileImgSrc?: string;
  bonusId?: number;
  imgAlt?: string;
  tag?: { text: string; backgroundColor: string; textColor: string };
  priority?: number;
  promotionPage?: string;
  isGameInfoPage?: boolean;
  openInNewTab?: boolean | null;
};

export type HeroBannerCarouselProps = {
  staticBanner?: HeroBannerSlide;
  slides: HeroBannerSlide[];
  autoplayInterval: number;
  className?: string;
  callEligiblePromos?: boolean;
  isGameInfoPage?: boolean;
};

type FooterProps = {
  footerText: string;
  className: string;
  promotionPage: string;
  pageUrl?: string;
};

const Footer: FunctionComponent<FooterProps> = ({ footerText, className, pageUrl = '', promotionPage = '' }) => (
  <>
    {footerText &&
      (promotionPage ? (
        <RouterLink
          className={`hero-carousel-slide__${className}`}
          to={{
            pathname: pageUrl,
            hash: APP_URLS.terms,
          }}
        >
          <InnerHTML text={footerText} />
        </RouterLink>
      ) : (
        <div className={`hero-carousel-slide__${className}`}>
          <InnerHTML text={footerText} />
        </div>
      ))}
  </>
);

const HeroBannerCarouselSlide: FunctionComponent<HeroBannerSlide & CarouselConfigProps> = ({
  imgAlt,
  imgSrc,
  mobileImgSrc,
  heading = '',
  subHeading = '',
  footer = '',
  buttonEnabled = true,
  buttonShown = true,
  buttonLink,
  buttonLinkType,
  routerLink = true,
  buttonLabel,
  forceUpperCaseHeading,
  forceUpperCaseSubHeading,
  isDisabledSlideContent,
  promotionPage = '',
  isGameInfoPage,
  openInNewTab,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isBelowBigDesktopWidth = useBreakpointInfo([MediaBreakpoint.BELOW_BIG_DESKTOP]);
  const isBelowDesktopWidth = useBreakpointInfo([MediaBreakpoint.BELOW_DESKTOP]);
  const mobile = useBreakpointInfo([MediaBreakpoint.BELOW_MOBILE]);
  const buttonSize: ButtonSize = isBelowBigDesktopWidth ? (isBelowDesktopWidth ? 'medium' : 'large') : 'x-large';
  const HTMLTagRegExp = new RegExp('<[^>]*>');
  const headingLevel = isGameInfoPage ? 1 : 2;
  const isExternalUrl = buttonLink && checkIsUrlExternal(buttonLink);
  const launchURL = handleLinkSlashSymbol(buttonLink);
  const isMobile = useBreakpointInfo([MediaBreakpoint.MOBILE]);
  const [isPlayLoading] = useState<boolean>(false);

  const checkAndConvertCase = (text: string, isUppercase: boolean | undefined): string => {
    if (text) {
      return isUppercase && !text.match(HTMLTagRegExp) ? text.toUpperCase() : text;
    }

    return '';
  };

  const handleDisabled = (
    callHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
    event: React.MouseEvent | React.KeyboardEvent
  ): void => {
    isDisabledSlideContent ? event.preventDefault() : callHandler(event);
  };

  const handleRouterLinkClick = (): void => {
    if (isMobile && buttonLink?.startsWith('#') && hashIsValidAsScrollTarget(buttonLink)) {
      tryScrollingToElementWithId(buttonLink.substr(1));
    }

    storageRoute.set({
      value: location.pathname,
    });

    if (openInNewTab) {
      window.open(launchURL, '_blank');
    } else {
      navigate(launchURL);
    }
  };

  const onClick = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent): void => {
      event.preventDefault();
      handleDisabled(handleRouterLinkClick, event);

      if (event.defaultPrevented) {
        // This means `handleDisabled` prevent the click, so we should not do anything more...
        return;
      }
    },
    [routerLink, buttonLink, buttonLinkType, isPlayLoading, isDisabledSlideContent]
  );

  const memoizedFooter = useMemo(
    () => <Footer footerText={footer} className="footer" promotionPage={promotionPage} pageUrl={''} />,
    [promotionPage]
  );

  const memoizedMobileFooter = useMemo(
    () => <Footer footerText={footer} className="mobile-footer" promotionPage={promotionPage} pageUrl={''} />,
    [promotionPage]
  );

  return (
    <div>
      <div className="hero-carousel-slide">
        {imgSrc && (
          <HeroBanner
            className="hero-carousel-slide__hero-banner"
            imgPath={mobile ? mobileImgSrc || imgSrc : imgSrc}
            imgAlt={imgAlt || ''}
          >
            <div className="hero-carousel-slide__content">
              <div className="hero-carousel-slide__heading">
                <Heading level={headingLevel}>{checkAndConvertCase(heading, forceUpperCaseHeading)}</Heading>
              </div>
              <div className="hero-carousel-slide__sub-heading">
                <InnerHTML text={checkAndConvertCase(subHeading, forceUpperCaseSubHeading)} />
              </div>
              {buttonShown && buttonLabel && buttonLink && (
                <div
                  className={joinStrings([
                    'hero-carousel-slide__link',
                    isPlayLoading && 'button--submitting',
                    !buttonEnabled && 'hero-carousel-slide__link--disabled',
                  ])}
                >
                  {routerLink && !isExternalUrl ? (
                    <RouterLink buttonStyle={{ variant: 'primary', size: buttonSize }} to={launchURL} onClick={onClick}>
                      <span>{buttonLabel}</span>
                      {isPlayLoading && <LoadingIndicator />}
                    </RouterLink>
                  ) : (
                    <Link
                      buttonStyle={{ variant: 'primary', size: buttonSize }}
                      href={launchURL}
                      onClick={onClick}
                      target={openInNewTab ? '_blank' : '_self'}
                    >
                      <span>{buttonLabel}</span>
                      {isPlayLoading && <LoadingIndicator />}
                    </Link>
                  )}
                </div>
              )}
              {memoizedFooter}
            </div>
            {memoizedMobileFooter}
          </HeroBanner>
        )}
      </div>
    </div>
  );
};

export const HeroBannerCarousel: FunctionComponent<HeroBannerCarouselProps & CarouselConfigProps> = ({
  slides = [],
  autoplayInterval = 5000,
  staticBanner,
  className,
  friendlyUrlName,
  forceUpperCaseHeading,
  forceUpperCaseSubHeading,
  game,
  children,
  defaultNumberOfBanners,
  isGameInfoPage,
  isDisabledSlideContent,
}) => {
  const navigate = useNavigate();
  const carouselRef = useRef<HTMLDivElement>(null);
  const { isMobileDevice } = useDeviceInfo();
  const mobile = isMobileDevice;
  const belowCustomDesktopWidth = window.innerWidth < 1281;
  const isBelowTabletLandWidth = useBreakpointInfo([MediaBreakpoint.BELOW_TABLET]);

  const carouselSlideNodes = slides
    .map((slide) => (
      <HeroBannerCarouselSlide
        {...slide}
        {...(slide.promotionSlide || {})}
        game={game}
        friendlyUrlName={friendlyUrlName}
        bonusId={slide.promotionSlide?.bonusId || staticBanner?.bonusId}
        forceUpperCaseHeading={forceUpperCaseHeading}
        forceUpperCaseSubHeading={forceUpperCaseSubHeading}
        isDisabledSlideContent={isDisabledSlideContent}
        defaultNumberOfBanners={defaultNumberOfBanners}
        isGameInfoPage={isGameInfoPage}
        buttonEnabled={!isDisabledSlideContent}
      />
    ))
    .slice(0, defaultNumberOfBanners);
  // Fix the carousel width on resize

  const handleClick = (staticBanner: HeroBannerSlide): void => {
    if (isDisabledSlideContent) return;
    const staticBannerLaunchURL = handleLinkSlashSymbol(staticBanner?.buttonLink);

    navigate(staticBannerLaunchURL);
  };

  return !carouselSlideNodes || defaultNumberOfBanners === 0 ? null : (
    <div
      className={joinStrings([
        'hero-banner-carousel',
        className,
        `hero-banner-carousel--${mobile ? 'mobile' : 'desktop'}`,
        !belowCustomDesktopWidth && (staticBanner || children) && `hero-banner-carousel--with-static-banner`,
      ])}
    >
      <>
        <div ref={carouselRef} className="hero-banner-carousel__carousel">
          {Boolean(slides.length) && (
            <Carousel
              visibleConfig={{
                wide: 1,
                desktop: 1,
                tablet: 1,
                mobile: 1,
              }}
              touchEnabled
              infinite
              showArrows={!mobile}
              showDots={!mobile && slides.length > 1}
              autoplayInterval={autoplayInterval}
              autoplay={autoplayInterval > 0}
              alignment="center"
              slides={carouselSlideNodes}
              {...(isBelowTabletLandWidth && {
                offsetPercentage: 4,
                offsetStyle: 'leftRight',
              })}
            />
          )}
        </div>

        {children}

        {!belowCustomDesktopWidth && staticBanner && (
          <div
            className={joinStrings([
              'hero-banner-carousel__static-banner',
              isDisabledSlideContent && 'hero-banner-carousel__static-banner--disabled',
            ])}
            style={{
              backgroundImage: `url("${staticBanner.imgSrc}")`,
            }}
            {...(staticBanner.buttonLink
              ? {
                  'aria-label': staticBanner.buttonLabel,
                  onClick: () => handleClick(staticBanner),
                  role: 'button',
                }
              : {})}
          >
            {staticBanner.tag?.text && (
              <Chip
                variant="tag"
                className="hero-banner-carousel__static-banner-tag"
                style={{
                  backgroundColor: staticBanner.tag.backgroundColor,
                  borderColor: staticBanner.tag.backgroundColor,
                  color: staticBanner.tag.textColor,
                }}
              >
                {staticBanner.tag.text}
              </Chip>
            )}
          </div>
        )}
      </>
    </div>
  );
};
