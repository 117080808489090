import { PopUpHandler } from '../../../shared/PopupHandler';
import { FC, useState, useEffect, useRef } from 'react';
import { fetchUploaderIframeURL } from './service';
import { useUserContext } from '../../../../providers/UserProvider';
import { Spinner } from '../../../shared/Spinner';
import { Prompt } from '../../../shared/Prompt';
import './DocumentUploader.scss';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

export const DocumentUploader: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false); // Initialize as false
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const { userProfileId, compliance } = useUserContext();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { t } = useTranslations();
  const isDocUploaderEnabled = JSON.parse(process.env.REACT_APP_ENABLE_DOCUPLOAD as string);

  useEffect(() => {
    const shouldDisplay =
      isDocUploaderEnabled && compliance && (compliance.idVerificationStatus === 'failed' || compliance.kycLevel === 0);

    // Reset states if we shouldn't display
    if (!shouldDisplay) {
      setShowPrompt(false);
      setIsOpen(false);

      return;
    }

    // If we should display and prompt isn't shown yet, show it
    setShowPrompt(true);

    const fetchUrl = async (): Promise<void> => {
      const url = await fetchUploaderIframeURL(userProfileId);

      setIframeUrl(url);
    };

    fetchUrl();
  }, [userProfileId, compliance]);

  const handlePromptClose = (): void => {
    setShowPrompt(false);
    setIsOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  // If compliance check fails, don't render anything
  if (
    !isDocUploaderEnabled ||
    !compliance ||
    (compliance.idVerificationStatus !== 'failed' && compliance.kycLevel !== 0)
  ) {
    return null;
  }

  return (
    <>
      <div className="doc-uploader">
        <Prompt
          PrimaryButton={{ text: 'Okay', onClick: handlePromptClose }}
          show={showPrompt}
          title="Document Upload"
          onClose={handlePromptClose}
        >
          {t('documentUpload.prompt')}
        </Prompt>

        <PopUpHandler
          elementToFocusOnOpen={iframeRef}
          isOpen={isOpen}
          header="Document Uploader"
          onClose={handleClose}
          modalProps={{ className: 'doc-uploader__modal' }} // TODO: don't allow dismiss
          drawerProps={{ className: 'doc-uploader__modal' }}
        >
          {iframeUrl ? (
            <iframe
              ref={iframeRef}
              src={iframeUrl}
              title="Document Uploader"
              allow="camera; microphone"
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-downloads allow-top-navigation"
            />
          ) : (
            <Spinner />
          )}
        </PopUpHandler>
      </div>
    </>
  );
};
